<template>
    <div class="mask" :class="{ 'overflow-visible': debug, 'overflow-hidden': !debug }">
        <div class="content" :class="{ 'reverse-animation': reverseAnimation }">
            <slot></slot>
        </div>
        <div
            v-if="debug"
            class="absolute inset-0 border-green-900 border-2 border-dashed flex items-center justify-center text-2xl text-green-900"
        >
            <span>{{ percentage }}, {{ reverseAnimation }}</span>
        </div>
    </div>
</template>

<script>
import { scrollableComponent } from '~/utils/mixins/scrollableComponent';

export default {
    name: 'ParallaxContainer',
    components: {},
    mixins: [scrollableComponent],
    props: {
        contentScale: {
            type: Number,
            default: 1.25,
        },
        debug: {
            type: Boolean,
            default: false,
        },
        reverseAnimation: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            scaleOffset: '12.5%',
            animationsDelay: '-50s',
            percentage: 0.5,
        };
    },
    watch: {
        // whenever question changes, this function will run
        percentage() {
            this.animationsDelay = `${this.percentage * -100}s`;
        },
    },
    mounted() {
        const scaleOffset = (this.contentScale - 1) * 0.5;
        this.scaleOffset = `${scaleOffset * 100}%`;

        this.onScrollUpdate();
    },

    methods: {
        onScrollUpdate() {
            const progress = this.getViewPortIntersectionRatio(this.$el);
            this.percentage = Math.min(Math.max(progress, 0), 1);
        },
        setPercentage(percentage) {
            this.percentage = percentage;
        },
    },
};
</script>

<style scoped>
.content {
    @apply h-full w-full;
    animation-name: scrollParallaxY;
    animation-duration: 100s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-play-state: paused;
    animation-delay: v-bind(animationsDelay);
    &.reverse-animation {
        animation-direction: reverse;
    }
    /*
    transform-origin: center center;
    transform: translateY(calc(v-bind(scaleOffset) * -1)) scale(v-bind(contentScale));

     */
}

@keyframes scrollParallaxY {
    0% {
        transform: translateY(v-bind(scaleOffset)) scale(v-bind(contentScale));
    }
    50% {
        transform: translateY(0) scale(v-bind(contentScale));
    }

    100% {
        transform: translateY(calc(v-bind(scaleOffset) * -1)) scale(v-bind(contentScale));
    }
}
</style>
