import { getNodeByPath } from '~/utils/content/navigation';
import type { MarketSetup, MarketSetupAdmin } from '~/composables/useMarketSetup';
import useMagnoliaLanguage from '~/composables/useMagnoliaLanguage';
import useMagnoliaSite from '~/composables/useMagnoliaSite';

interface MarketContentEndpointResponse {
    marketSetup?: {
        marketSetup?: MarketSetup;
        marketSetupAdmin?: MarketSetupAdmin;
    };
    commonLabels: {
        [key: string]: string;
    };
    productPageLinks: [
        {
            [index: number]: {
                defaultProductPage: boolean;
                productId: string;
                productPageLinkUrl: string;
                template: string;
            };
        },
    ];
}
const useMarketContent = function () {
    const { marketSetup, marketSetupAdmin, marketSetupLinks } = useMarketSetup();
    const { commonLabels } = useCommonLabels();
    const { productPages } = useMagnoliaShopPages();
    const { currentLanguage } = useMagnoliaLanguage();

    const marketContent = useState('marketContent', () => ({}));
    const loadMarketContent = async (contentPath = '') => {
        const {
            public: { mgnlApi },
        } = useRuntimeConfig();
        const navigationNode = getNodeByPath(contentPath);
        if (!navigationNode || !currentLanguage.value) {
            return;
        }

        const key = `marketContent-${navigationNode}-${currentLanguage.value}`;

        const { mgnlSiteHeaders } = useMagnoliaSite();
        const url = `${mgnlApi}/.rest/delivery/marketContent/v1/${navigationNode}?lang=${currentLanguage.value}`;
        const { data, error } = await useFetch<MarketContentEndpointResponse>(url, {
            key,
            headers: mgnlSiteHeaders,
            timeout: 5000,
        });

        if (!error.value) {
            marketContent.value = data.value ?? {};
            marketSetup.value = data.value?.marketSetup?.marketSetup ?? {};
            marketSetupAdmin.value = data.value?.marketSetup?.marketSetupAdmin ?? {};
            commonLabels.value = data.value?.commonLabels ?? {};
            productPages.value = data.value?.productPageLinks ?? {};

            // public links
            marketSetupLinks.value = {
                startPage: marketSetup.value.startPage,
                searchResultPage: marketSetup.value.searchResultPage,
                dataPrivacyPage: marketSetup.value.dataPrivacyPage,
                generalTermsAndConditionsPage: marketSetup.value.generalTermsAndConditionsPage,
                returnPolicyPage: marketSetup.value.returnPolicyPage,
                newsletterUnsubscribePage: marketSetup.value.newsletterUnsubscribePage,
                newsletterDoiPage: marketSetup.value.newsletterDoiPage,
                // TODO consider moving these Links to a dedicated type / App / Tab in mgnl
                cartPage: marketSetupAdmin.value.cartPage,
                internalServerErrorPage: marketSetup.value.internalServerErrorPage,
                pageNotFoundPage: marketSetup.value.pageNotFoundPage,
                unauthorizedPage: marketSetup.value.unauthorizedPage,
                orderLookupPage: marketSetupAdmin.value.orderLookupPage,
                orderPaymentPage: marketSetupAdmin.value.orderPaymentPage,
                myBritaOverviewPage: marketSetupAdmin.value.myBritaOverviewPage,
                myBritaRegisterPage: marketSetupAdmin.value.myBritaRegisterPage,
                myBritaSubscriptionPage: marketSetupAdmin.value.myBritaSubscriptionPage,
                yourceMigrationPage: marketSetupAdmin.value.yourceMigrationPage,
                yourceMigrationSuccessPage: marketSetupAdmin.value.yourceMigrationSuccessPage,
                yourceMigrationActivePage: marketSetupAdmin.value.yourceMigrationActivePage,
                yourceMigrationActiveSuccessPage: marketSetupAdmin.value.yourceMigrationActiveSuccessPage,
            };
        } else {
            console.log(error.value);
        }
    };

    return { marketSetup, marketSetupAdmin, marketSetupLinks, marketContent, loadMarketContent };
};

export default useMarketContent;
