function getValueSign(number) {
    let sign = Math.sign(number) ?? 0;
    switch (sign) {
        case 1:
            return '+';
        case -1:
            return '-';
        default:
            return '+';
    }
}

export { getValueSign };
