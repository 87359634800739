<template>
    <section class="story-section relative">
        <div class="absolute inset-0">
            <div class="sticky top-0 h-full max-h-screen w-full">
                <div
                    ref="intersectingElement"
                    class="absolute inset-0 animation-container overflow-hidden safari-overflow-hidden-fix"
                >
                    <ParallaxContainer
                        ref="bgParallax"
                        class="absolute inset-0 h-full w-full"
                        :content-scale="1.5"
                        :enable-observer-on-mount="false"
                        :reverse-animation="false"
                        :debug="false"
                    >
                        <PictureElement
                            :img="image"
                            :class="{
                                hidden: containsImage(imageMobile),
                                'md:inline': containsImage(imageMobile),
                            }"
                            :breakpoints="BREAKPOINTS"
                            img-class="absolute inset-0 block h-full w-full object-cover"
                        ></PictureElement>
                        <PictureElement
                            v-if="containsImage(imageMobile)"
                            :img="imageMobile"
                            :breakpoints="BREAKPOINTS"
                            class="md:hidden"
                            img-class=" absolute inset-0 block object-cover w-full h-full"
                        ></PictureElement>
                    </ParallaxContainer>
                    <div
                        class="bg-black absolute inset-0"
                        :class="contentToTailwindClasses({ opacity: opacity ?? 0 })"
                    ></div>
                    <DotPattern :variant="dotPattern" class="absolute inset-0 h-full"></DotPattern>
                    <div v-if="indicator" class="container mx-auto relative h-full">
                        <div
                            class="absolute left-0 bottom-0 right-0 lg:top-0 lg:right-auto flex lg:flex-col gap-4 items-center justify-center pb-8 lg:pl-4 lg:pb-0"
                            :class="{
                                'gap-2 lg:gap-4': indicator === 'dots',
                                'gap-4': indicator === 'numbers',
                            }"
                        >
                            <div
                                v-for="index in slideCount"
                                :key="`dot-${index}`"
                                class="rounded-full flex items-center justify-center transition-colors duration-300"
                                :class="{
                                    'bg-white': index !== slideIndex,
                                    'bg-dark-blue': index === slideIndex,
                                    'text-white': index === slideIndex,
                                    'w-4 h-4 lg:w-6 lg:h-6': indicator === 'dots',
                                    'h-8 lg:h-8 w-8 lg:w-8': indicator === 'numbers',
                                }"
                            >
                                <span v-if="indicator === 'numbers'">
                                    {{ index }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            ref="content"
            class="container no-nested-container-padding relative overflow-x-hidden flex flex-col items-center"
        >
            <EditableArea class="story-area w-full" :content="main" :custom-view="EditorialStoryComponentArea" />
        </div>
    </section>
</template>

<script setup>
import { containsImage } from '~/utils/helper/magnolia';
import { DIMENSIONS } from '~/utils/helper/imaging';
import EditorialStoryComponentArea from './EditorialStoryComponentArea';
import { EditableArea } from '@magnolia/vue-editor';
import ParallaxContainer from '../../elements/ParallaxContainer';
import PictureElement from '../../elements/PictureElement';
import DotPattern from '~/templates/elements/DotPattern.vue';

const BREAKPOINTS = {
    start: DIMENSIONS['sm'].ratio['1/2'].scale['1.5'],
    md: DIMENSIONS['md'].ratio['2/3'].scale['1.5'],
    xl: DIMENSIONS['xl'].ratio['3/2'].scale['1.5'],
    container: DIMENSIONS['container'].ratio['3/2'].scale['1.5'],
    page: DIMENSIONS['page'].ratio['video'].scale['1.5'],
};
</script>
<script>
import { editableComponent } from '~/utils/mixins/editableComponent';
import { scrollableComponent } from '~/utils/mixins/scrollableComponent';
import { imageName, imagePath, mapNodes } from '~/utils/helper/magnolia';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';

export default {
    name: 'EditorialStoryComponent',
    mixins: [editableComponent, scrollableComponent],
    provide() {
        // use function syntax so that we can access `this`
        return {
            slideStore: this.slideStore,
        };
    },
    props: ['container', 'main', 'dotPattern', 'image', 'imageMobile', 'opacity', 'indicator'],
    data() {
        return {
            animationsDelay: '-50s',
            contentPercentage: 0.5,
            slideCount: 0,
            slideIndex: 0,
            slideStore: {
                activeSlide: 0,
            },
        };
    },
    computed: {
        intersectionOptions() {
            return {
                rootMargin: '10px',
                threshold: [0, 0.1, 0.9, 1.0],
            };
        },
    },
    mounted() {
        this.onScrollUpdate();
        this.slideCount = mapNodes(this.main).length;
    },
    methods: {
        onScrollUpdate() {
            const progress = this.getViewPortIntersectionRatio(this.$refs.intersectingElement);
            this.percentage = Math.min(Math.max(progress, 0), 1);
            this.animationsDelay = `${this.percentage * -100}s`;
            // update content based parallax
            const contentPercentage = this.getViewPortIntersectionRatio(this.$refs.content);
            this.$refs.bgParallax.setPercentage(contentPercentage);
            this.slideIndex = this.slideStore.activeSlide + 1;
        },
        imageName,
        imagePath,
        mapNodes,
        contentToTailwindClasses,
    },
};
</script>

<style>
.story-section {
    @apply min-h-screen;
}

.story-section .headline-component {
    @apply text-center text-8xl;
}

.story-section .animation-container {
    animation-name: snapScreen;
    animation-duration: 100s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
    animation-play-state: paused;
    animation-delay: v-bind(animationsDelay);
}

@keyframes snapScreen {
    0% {
        border-radius: 32px;
        margin: 0px var(--container-page-offset, 32px);
    }
    45% {
        border-radius: 0;
        margin: 0;
    }
    55% {
        border-radius: 0;
        margin: 0;
    }
    100% {
        border-radius: 32px;
        margin: 0px var(--container-page-offset, 32px);
    }
}
</style>
