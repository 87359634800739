/**
 *
 * replaces {{ keys }} with values passed via key value map
 *
 * e.g.:
 * string:  i am {{ foo }} label {{ bar }} baz !
 * values: { foo: 'replacedFoo', bar: 'replacedBar' }
 *
 * returns: i am replacedFoo label replacedBar baz !
 *
 *
 * @param string
 * @param values
 * @param linkMap
 * @returns {*}
 */

export const replacePattern = (string, values, linkMap) => {
    let processedString = string;

    try {
        if (processedString?.includes('{{')) {
            const matches = Array.from(processedString.matchAll(/{{+.*?}}+/g));
            matches.forEach(match => {
                const matchedPlaceholder = match[0];
                const keyMatch = matchedPlaceholder.match(/{{(.+?)}}/);
                const placeHolderKey = keyMatch[1].trim();
                const placeHolderValue = values?.[placeHolderKey] ?? '';

                if (placeHolderKey.includes('|')) {
                    const [linkKey, label] = placeHolderKey.split('|');
                    if (linkMap?.[linkKey?.trim()]) {
                        // found a valid link
                        const link = `<a data-html href="${linkMap[linkKey?.trim()]}" target="_blank">${label}</a>`;
                        processedString = processedString.replaceAll(matchedPlaceholder, link);
                    } else {
                        processedString = processedString.replaceAll(matchedPlaceholder, label);
                    }
                } else if (typeof placeHolderValue !== 'undefined') {
                    processedString = processedString.replaceAll(matchedPlaceholder, placeHolderValue);
                }
            });
        }
    } catch (e) {
        console.log('Pattern Replacement Error', e.message);
    }
    return processedString;
};
