import { computed, ref } from 'vue';
import type { Order } from '@shopware-pwa/types';

import { ORDER_DETAILS_ASSOCIATIONS } from '~/utils/helper/shop/order';

type UseGuestOrderDetailsReturn = {
    guestOrder: ComputedRef<Order | undefined | null>;
    loadGuestOrder(order_number: string | number, customer_email: string | number): Promise<void>;
    changeGuestOrderPaymentMethod(paymentMethodId: string, customer_email: string): Promise<void>;
    resetOrder(): void;
};

/**
 * Composable for managing an existing order.
 */
export function useGuestOrderDetails(): UseGuestOrderDetailsReturn {
    const { apiInstance } = useShopwareContext();
    const _sharedOrder = ref();

    const loadGuestOrder = async (orderId: string, hashed_email: string) => {
        // guest endpoints expects token param to be uri safe.
        const { data } = await apiInstance.invoke.post('/store-api/order/guest', {
            orderId,
            token: encodeURIComponent(hashed_email),
            ...ORDER_DETAILS_ASSOCIATIONS,
        });
        _sharedOrder.value = data;
    };

    async function changeGuestOrderPaymentMethod(paymentMethodId: string, customer_email: string) {
        await apiInstance.invoke.post(`/store-api/order/payment`, {
            orderId: _sharedOrder.value.id,
            paymentMethodId,
            customer_email,
        });

        await loadGuestOrder(_sharedOrder.value.id, btoa(customer_email));
    }

    const resetOrder = () => {
        _sharedOrder.value = null;
    };

    return {
        guestOrder: computed(() => _sharedOrder.value),
        loadGuestOrder,
        changeGuestOrderPaymentMethod,
        resetOrder,
    };
}
