<template>
    <AccountGrid>
        <div class="account-profile-page flex flex-col">
            <h2 class="text-2xl font-medium text-dark-blue xl:text-3xl">
                <CommonLabel path="BWF/shop/account/profile/myProfile"></CommonLabel>
            </h2>
            <div class="flex flex-col gap-4 xl:gap-6 mt-6 xl:mt-12">
                <section class="flex flex-col border border-grey rounded-lg gap-4 px-4 py-6 xl:px-6">
                    <h3 class="font-medium text-lg">
                        <CommonLabel path="BWF/shop/account/profile/customerNo"></CommonLabel>
                    </h3>

                    <p>{{ user?.customerNumber }}</p>
                </section>

                <AccountPersonalData />

                <AccountAccessData />

                <EditableArea
                    :content="profileNewsletter"
                    class="xl:col-span-full"
                    :custom-view="AccountNewsletterRegistration"
                />

                <EditableArea :content="accountDeletion" class="xl:col-span-full" :custom-view="EmptyArea" />
                <AccountDeletion :content="accountDeletion" @delete-account="deleteAccountController.open()" />

                <EditableArea :content="deleteAccountModal" :custom-view="EmptyArea" />
                <ModalComponent
                    :content="deleteAccountModal"
                    :controller="deleteAccountController"
                    @secondary-action="trackAccountModification('delete account')"
                >
                    <template #headline>Willst Du dein Konto wirklich löschen?*</template>
                    <template #richText>
                        Damit verlierst Du alle Vorteile wie z.B. einen vereinfachten Kaufprozess, Bestell- und
                        Retouren-Status einsehen, Verwaltung der persönlichen Daten, Übersicht deiner registrierten
                        Produkte, Abos und Guthaben.
                        <br /><br />
                        Bitte beachte, dass wir bei Löschung des Kontos nicht automatisch alle deine personenbezogene
                        Daten löschen. Solltest Du das wünschen, bitten wir Dich, einen entsprechenden Löschwunsch an
                        unseren Customer-Service unter datapolicy@brita.net zu senden*
                    </template>
                    <template #primaryAction>
                        <button class="btn btn-ghost" @click="deleteAccountController.close()">Abbrechen</button>
                    </template>
                </ModalComponent>
            </div>
        </div>
    </AccountGrid>
</template>

<script setup>
import AccountGrid from '~/templates/components/account/AccountGrid.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import AccountNewsletterRegistration from '~/templates/components/shop/account/AccountNewsletterRegistration.vue';
import AccountDeletion from '~/templates/components/shop/account/AccountDeletion.vue';
import { EditableArea } from '@magnolia/vue-editor';
import ModalComponent from '~/templates/components/ModalComponent.vue';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
import { useModal } from '~/composables/useModal';
import useAccountTracking from '~/composables/tracking/useAccountTracking';
defineOptions({
    inheritAttrs: false,
});
defineProps(['profileNewsletter', 'accountDeletion', 'deleteAccountModal']);

const { trackAccountModification } = useAccountTracking();
const { user } = useUser();
const deleteAccountController = useModal();
</script>
