const SESSION_KEY = 'MSAL_INTERACTION_STATUS';
const LOGIN = 'MSAL_LOGIN';
const REGISTER = 'MSAL_REGISTER';

export default function () {
    const gtm = useGtm(); // auto-imported by the module
    const msalTrackingInteraction = useSessionStorage(SESSION_KEY);
    const trackLoginBegin = () => {
        try {
            // store interaction
            msalTrackingInteraction.value = LOGIN;
            // track
            gtm?.trackEvent({
                event: 'gx.login_begin',
                event_name: 'login_begin',
                event_source: 'source_code',
                login_begin: {
                    method: 'manual',
                },
            });
        } catch (e) {
            console.error('gx.login_begin', e);
        }
    };
    const trackLogin = user_id => {
        try {
            // track
            gtm?.trackEvent({
                event: 'gx.login',
                event_name: 'login',
                event_source: 'source_code',
                login: {
                    login_status: 'logged in',
                    user_id,
                    method: 'manual',
                },
            });
        } catch (e) {
            console.error('gx.login', e);
        }
    };

    const trackSignUpBegin = () => {
        try {
            // store interaction
            msalTrackingInteraction.value = REGISTER;
            // track
            gtm?.trackEvent({
                event: 'gx.sign_up_begin',
                event_name: 'sign_up_begin',
                event_source: 'source_code',
                sign_up_begin: {
                    method: 'manual',
                },
            });
        } catch (e) {
            console.error('gx.sign_up_begin', e);
        }
    };
    const trackSignUp = user_id => {
        try {
            // track
            gtm?.trackEvent({
                event: 'gx.sign_up',
                event_name: 'sign_up',
                event_source: 'source_code',
                sign_up: {
                    login_status: 'logged in',
                    user_id,
                    method: 'manual',
                },
            });
        } catch (e) {
            console.error('gx.sign_up', e);
        }
    };

    const trackLogout = () => {
        try {
            // track
            gtm?.trackEvent({
                event: 'gx.logout',
                event_name: 'logout',
                event_source: 'source_code',
                logout: {
                    login_status: 'logged out',
                },
            });
        } catch (e) {
            console.error('gx.logout', e);
        }
    };
    /**
     * handle redirects back to BWF
     * @param response
     */
    const trackRedirect = response => {
        try {
            switch (msalTrackingInteraction.value) {
                case LOGIN:
                    trackLogin(response?.account?.homeAccountId);
                    break;
                case REGISTER:
                    trackSignUp(response?.account?.homeAccountId);
                    break;
            }
            // empty
            msalTrackingInteraction.value = null;
        } catch (e) {
            console.error('gx.mybrita-redirect', e);
        }
    };
    return { trackLoginBegin, trackLogout, trackRedirect, trackSignUp, trackSignUpBegin };
}
