<template>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 responsive-gap">
        <template v-for="column in getColumns(row)" :key="'headline' + column?.['@id'] ?? '-empty'">
            <AccordionComponent
                v-if="
                    isList(column) && isTabletOrMobile && Object.keys(column).length > 0 && !containsLink(column?.link)
                "
                :is-active="true"
                class="block-cell"
            >
                <template #header>
                    <FooterHeadline
                        :class="{
                            'font-book': !isList(column),
                            'font-medium': isList(column),
                        }"
                        :text="column?.title"
                    >
                    </FooterHeadline>
                </template>
                <template #content>
                    <FooterColumnContent :column="column"></FooterColumnContent>
                </template>
            </AccordionComponent>

            <template v-else>
                <FooterHeadline
                    class="footer-grid-cell"
                    :class="{
                        'font-book': !isList(column),
                        'font-medium': isList(column),
                    }"
                    :text="column?.title"
                    :link="column?.link"
                >
                </FooterHeadline>
                <FooterColumnContent class="footer-grid-cell" :column="column"></FooterColumnContent>
            </template>
        </template>
    </div>
</template>

<script>
import { editableComponent } from '~/utils/mixins/editableComponent';
import { containsLink, filterNodesByType, getId, imageName, imagePath, mapNodes } from '~/utils/helper/magnolia';
import FooterColumnContent from './FooterColumnContent';
import FooterHeadline from './FooterHeadline';

import AccordionComponent from '../../elements/AccordionComponent';

export default {
    name: 'FooterGrid',
    components: {
        AccordionComponent,
        FooterColumnContent,
        FooterHeadline,
    },
    mixins: [editableComponent],
    props: ['row'],
    setup() {
        const { isTabletOrMobile } = useDevice();
        return { isTabletOrMobile };
    },
    computed: {
        rows() {
            return filterNodesByType(mapNodes(this.footer), 'footer:row');
        },
    },

    methods: {
        mapNodes,
        filterNodesByType,
        getId,
        imageName,
        imagePath,
        getColumns(row) {
            let columns = mapNodes(row);
            columns = columns.concat(Array(4).fill({}));
            return columns.slice(0, 4);
        },
        isList(column) {
            return column?.displayAs !== 'button';
        },
        containsLink,
    },
};
</script>

<style>
@screen md {
    .footer-grid-cell:nth-child(2n + 1) {
        order: 1;
    }

    .footer-grid-cell:nth-child(2n + 2) {
        order: 2;
    }

    .footer-grid-cell:nth-child(2n + 5) {
        order: 3;
    }

    .footer-grid-cell:nth-child(2n + 6) {
        order: 4;
    }

    .footer-grid-cell:nth-child(2n + 9) {
        order: 5;
    }

    .footer-grid-cell:nth-child(2n + 10) {
        order: 6;
    }

    .footer-grid-cell:nth-child(2n + 13) {
        order: 7;
    }

    .footer-grid-cell:nth-child(2n + 14) {
        order: 8;
    }
}

@screen lg {
    .footer-grid-cell:nth-child(2n + 1) {
        order: 1;
    }

    .footer-grid-cell:nth-child(2n + 2) {
        order: 2;
    }

    .footer-grid-cell:nth-child(2n + 9) {
        order: 3;
    }

    .footer-grid-cell:nth-child(2n + 10) {
        order: 4;
    }
}

.block-cell {
    @apply col-span-full;
    .footer-grid-cell {
        order: unset;
    }
}
</style>
