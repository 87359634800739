<template>
    <div
        class="flex flex-col"
        :class="[
            contentToTailwindClasses({
                flexItems: alignment,
                textAlign: alignment,
            }),
            {
                'gap-2 xl:gap-4': !sameFontSize,
            },
        ]"
    >
        <HeadlineComponent
            :class="
                contentToTailwindClasses({
                    textColor: headline?.textColor ?? 'blue',
                    fontSize: fontSize,
                    fontWeight: headline?.fontWeight ?? 'regular',
                })
            "
            :headline="headline"
        ></HeadlineComponent>
        <div
            v-if="subline"
            class="hyphens-auto"
            :class="
                sameFontSize
                    ? contentToTailwindClasses({ fontSize: fontSize })
                    : contentToTailwindClasses({ fontSize: Math.min(3, fontSize + 2) })
            "
        >
            {{ subline }}
        </div>
    </div>
</template>
<script>
import HeadlineComponent from './HeadlineComponent';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';

export default {
    name: 'SingleHeadlineComponent',
    components: { HeadlineComponent },
    props: ['alignment', 'sameFontSize', 'fontSize', 'headline', 'subline', 'textColor'],
    methods: {
        contentToTailwindClasses,
    },
};
</script>
