<template>
    <span>{{ formatted }}</span>
</template>

<script setup>
import { useNow, useDateFormat } from '@vueuse/core';
const props = defineProps(['date', 'pattern']);

const formatted = useDateFormat(props.date ?? props.date ?? useNow(), props.pattern ?? 'D.M.YYYY');
</script>
