<template>
    <svg
        id="eqDmW3d9Nwx1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1600 900"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
    >
        <g id="eqDmW3d9Nwx2_ts" transform="translate(100,580) scale(0.2,0.2)">
            <ellipse
                id="eqDmW3d9Nwx2"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="eqDmW3d9Nwx3_ts" transform="translate(1660,320) scale(0.2,0.2)">
            <ellipse
                id="eqDmW3d9Nwx3"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="eqDmW3d9Nwx4_ts" transform="translate(360,840) scale(0.2,0.2)">
            <ellipse
                id="eqDmW3d9Nwx4"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#84d0f5"
                fill-opacity="0.65"
                stroke-width="0"
            />
        </g>
        <g id="eqDmW3d9Nwx5_ts" transform="translate(620,840) scale(0.2,0.2)">
            <ellipse
                id="eqDmW3d9Nwx5"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
    </svg>
</template>
<style scoped>
#eqDmW3d9Nwx2_ts {
    animation: eqDmW3d9Nwx2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx2_ts__ts {
    0% {
        transform: translate(100px, 580px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        transform: translate(100px, 580px) scale(1, 1);
    }
    100% {
        transform: translate(100px, 580px) scale(1, 1);
    }
}
#eqDmW3d9Nwx2 {
    animation: eqDmW3d9Nwx2_c_o 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx2_c_o {
    0% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eqDmW3d9Nwx3_ts {
    animation: eqDmW3d9Nwx3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx3_ts__ts {
    0% {
        transform: translate(1660px, 320px) scale(0.2, 0.2);
    }
    10% {
        transform: translate(1660px, 320px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        transform: translate(1660px, 320px) scale(1, 1);
    }
    100% {
        transform: translate(1660px, 320px) scale(1, 1);
    }
}
#eqDmW3d9Nwx3 {
    animation: eqDmW3d9Nwx3_c_o 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx3_c_o {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eqDmW3d9Nwx4_ts {
    animation: eqDmW3d9Nwx4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx4_ts__ts {
    0% {
        transform: translate(360px, 840px) scale(0.2, 0.2);
    }
    20% {
        transform: translate(360px, 840px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        transform: translate(360px, 840px) scale(1, 1);
    }
    100% {
        transform: translate(360px, 840px) scale(1, 1);
    }
}
#eqDmW3d9Nwx4 {
    animation: eqDmW3d9Nwx4_c_o 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx4_c_o {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eqDmW3d9Nwx5_ts {
    animation: eqDmW3d9Nwx5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx5_ts__ts {
    0% {
        transform: translate(620px, 840px) scale(0.2, 0.2);
    }
    30% {
        transform: translate(620px, 840px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        transform: translate(620px, 840px) scale(1, 1);
    }
    100% {
        transform: translate(620px, 840px) scale(1, 1);
    }
}
#eqDmW3d9Nwx5 {
    animation: eqDmW3d9Nwx5_c_o 2000ms linear 1 normal forwards;
}
@keyframes eqDmW3d9Nwx5_c_o {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
