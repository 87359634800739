import validate from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45navigation_45global from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/middleware/01.navigation.global.ts";
import _02_45auth_45azure_45global from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/middleware/02.auth.azure.global.ts";
import manifest_45route_45rule from "/opt/bamboo/xml-data/build-dir/BRITAD2C-BMPP-JOB1/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45navigation_45global,
  _02_45auth_45azure_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}