import { computed } from 'vue';

interface UseShopConfigurationReturn {
    cleanedShopwareEndpoint: ComputedRef<string>;
}
export function useShopConfiguration(): UseShopConfigurationReturn {
    const {
        public: { shopware },
    } = useRuntimeConfig();

    /**
     * will remove trailing slashes from shopware base url for tmpl concatenation
     */
    const cleanedShopwareEndpoint = computed(() => {
        return shopware.shopwareEndpoint.replace(/\/+$/, '');
    });

    return {
        cleanedShopwareEndpoint,
    };
}
