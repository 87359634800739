<template>
    <div class="form-field">
        <label class="form-field-wrapper" :class="{ 'has-error': hasError }">
            <select
                v-if="options?.length > 0"
                :id="`select-${attrs.name}`"
                class="absolute opacity-0 inset-0 w-full"
                :name="attrs.name"
                :disabled="disabled"
                :autocomplete="attrs.autocomplete"
                :value="modelValue"
                @input="onInput($event.target.value)"
            >
                <option
                    ref="firstOptionRef"
                    :disabled="firstOptionDisabled"
                    selected
                    :label="firstOptionLabel ?? label ?? ' '"
                    :value="firstOptionValue"
                >
                    {{ firstOptionLabel ?? label ?? '' }}
                </option>

                <option
                    v-for="{ value, label: optionLabel } in options"
                    :key="value"
                    :value="value"
                    :selected="`${value}` === `${modelValue}`"
                >
                    {{ optionLabel ?? value ?? '' }}
                </option>
            </select>

            <span
                class="relative w-full border border-light-grey flex items-center justify-between px-2 rounded-t-sm pointer-events-none"
                :class="[{ 'has-label': !!label, 'has-value': !!modelValue }, !!label ? 'h-14' : 'h-10']"
            >
                <span class="py-2">{{ activeLabel }}</span>
                <SvgIcon name="Arrow-Down" class="w-4 h-4 transition-all text-dark-blue pointer-events-none"></SvgIcon>
            </span>

            <span
                v-if="label"
                class="floating-label pointer-events-none absolute left-0 top-4 px-2 flex items-center h-10 transition-all text-medium-grey"
            >
                {{ label }}
                <span v-if="label && attrs.required">{{ requiredChar }}</span>
            </span>
        </label>
        <div v-if="errorMessage" class="px-2 pointer-events-none text-signal-red text-xs">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';

const props = defineProps({
    modelValue: [Object, String, Number, null],
    options: Array,
    label: [String, null],
    errorMessage: [String, null],
    disabled: [Boolean, null],
    firstOptionValue: [Number, String, null],
    firstOptionDisabled: {
        type: [Boolean, null],
        default: true,
    },
    firstOptionLabel: {
        type: [String, null],
        default: '',
    },
});
const attrs = useAttrs();
const emit = defineEmits(['update:modelValue']);
const hasError = computed(() => !!props.errorMessage);

const requiredChar = inject('input-required-char', '*');

const activeLabel = computed(() => {
    const activeOption = props.options.find(option => `${option.value}` === `${props.modelValue}`);

    return activeOption ? activeOption.label ?? activeOption.value : '';
});

const onInput = value => {
    emit('update:modelValue', value);
};

const firstOptionRef = ref(null);
const optionRefs = ref([]);
const reset = () => {
    optionRefs.value.forEach(option => {
        option.selected = false;
    });
    firstOptionRef.value.selected = true;
    emit('update:modelValue', '');
};
defineExpose({ reset });
</script>

<style scoped>
.form-field {
    select {
        @apply appearance-none opacity-0;
    }
    label {
        @apply block;
    }
}

.has-label {
    @apply pt-4;
}

.has-value + .floating-label {
    @apply top-1 h-4 font-medium text-xs text-deep-blue;
}

.has-error {
    .floating-label {
        @apply text-signal-red;
    }
}
</style>
