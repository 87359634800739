<template>
    <div>
        <form class="relative flex flex-col gap-4 xl:gap-6" @submit.prevent="invokeWarranty">
            <slot name="back-button"></slot>
            <div class="text-lg xl:text-4xl font-medium">
                <h2 class="text-dark-blue">
                    <CommonLabel path="BWF/shop/account/orderHistory/warrantyHeadline"></CommonLabel>
                </h2>
                <h3 class="flex flex-wrap gap-1 gap-x-4 xl:gap-4 items-center">
                    <span>
                        <CommonLabel path="BWF/shop/account/orderHistory/orderNumber"></CommonLabel>
                        <span>&nbsp;{{ order?.orderNumber }}</span>
                    </span>
                    <AccountOrderStateLabel :state="order?.stateMachineState"></AccountOrderStateLabel>
                </h3>
            </div>
            <template v-if="!isEditMode()">
                <slot name="loading"></slot>
                <template v-if="order">
                    <hr class="hidden xl:block" />
                    <OrderSelectableItems :order="order"></OrderSelectableItems>

                    <hr class="hidden xl:block" />
                    <div class="flex flex-col mt-4">
                        <div class="xl:text-xl font-medium">
                            <CommonLabel path="BWF/shop/account/orderHistory/warrantyReasons"> </CommonLabel>
                        </div>
                        <SelectField
                            v-model="warrantyFormState.reason"
                            :label="reasonLabel"
                            required="required"
                            class="form-field-grey xl:w-1/2"
                            name="warrantyReason"
                            :options="selectableReasons"
                            :error-message="warrantyReturnForm$?.reason?.$errors?.[0]?.$message"
                            @change="warrantyReturnForm$?.reason.$touch()"
                        ></SelectField>
                        <!-- check on blur and change might be unnecessary -->
                    </div>
                    <div class="flex flex-col mt-4">
                        <TextAreaField
                            v-model="warrantyFormState.message"
                            name="returnMessage"
                            required="required"
                            :placeholder="messageLabel"
                            :error-message="warrantyReturnForm$?.message?.$errors?.[0]?.$message"
                            maxlength="1000"
                            @blur="warrantyReturnForm$?.message.$touch()"
                        >
                        </TextAreaField>
                        <InputField
                            v-model="warrantyFormState.middlename"
                            name="middlename"
                            type="text"
                            label="middlename"
                            placeholder="middlename"
                            class="form-field-umbrella"
                        />
                    </div>
                    <div class="flex flex-col mt-4">
                        <div class="xl:text-xl font-medium">
                            <CommonLabel path="BWF/shop/account/orderHistory/warrantyUploadHeadline"> </CommonLabel>
                        </div>
                        <div class="flex flex-col xl:text-xl">
                            <CommonLabel path="BWF/shop/account/orderHistory/warrantyUploadFormat"></CommonLabel>
                            <CommonLabel path="BWF/shop/account/orderHistory/warrantyUploadSize"></CommonLabel>
                        </div>
                    </div>
                    <div class="mt-4 w-full">
                        <div v-for="(file, index) in files" :key="file.index" class="flex flex-row items-center">
                            <SvgIcon name="Download" class="h-4 w-4 flex-shrink-0 mr-3 text-dark-blue"> </SvgIcon>
                            <span class="truncate grow self-start w-3/5">{{ file.name }}</span>
                            <span class="text-medium-grey flex-shrink-0 mr-5 md:mr-10"
                                >{{ Math.floor(file.size / 1000) }} KB</span
                            >
                            <button type="button" @click="handleFileDelete(index)">
                                <SvgIcon name="Remove-Item" class="text-link text-medium-grey h-4 w-4 flex-shrink-0">
                                </SvgIcon>
                            </button>
                        </div>
                    </div>
                    <div class="mt-4 w-full">
                        <label
                            class="flex align-center disabled"
                            for="fileInputHidden"
                            :class="
                                files.length >= maxFiles
                                    ? 'cursor-not-allowed text-grey'
                                    : 'cursor-pointer text-dark-blue'
                            "
                        >
                            <SvgIcon name="Plus" type="button" class="h-6 w-6 mr-6"> </SvgIcon>
                            <CommonLabel
                                v-if="files.length > 0"
                                path="BWF/shop/account/orderHistory/warrantyUploadMoreFiles"
                                class="text-xl text-medium"
                            ></CommonLabel>
                            <CommonLabel
                                v-else
                                path="BWF/shop/account/orderHistory/warrantyUploadFiles"
                                class="text-xl text-medium"
                            ></CommonLabel>
                        </label>
                        <input
                            id="fileInputHidden"
                            ref="fileInput"
                            class="hidden"
                            multiple
                            type="file"
                            :disabled="files.length >= maxFiles"
                            accept="image/png, image/jpeg, image/heic, application/pdf, video/avi, video/mp4, video/mpeg, video/quicktime"
                            @change="handleFileChange"
                        />
                    </div>
                </template>
                <hr />
            </template>

            <div class="flex flex-col xl:flex-row gap-4 items-start">
                <button class="btn btn-blue w-full xl:ml-auto xl:w-auto" :disabled="isLoading">
                    <CommonLabel path="BWF/shop/account/orderHistory/warrantyButtonLabel"></CommonLabel>
                </button>
            </div>
        </form>
    </div>
</template>
<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
import SelectField from '~/templates/elements/form/SelectField.vue';
import TextAreaField from '~/templates/elements/form/TextAreaField.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import OrderSelectableItems from '~/templates/components/shop/account/OrderSelectableItems.vue';

import { useVuelidate } from '@vuelidate/core';
import useBritaMiddleware from '~/composables/useBritaMiddleware';
import { isEditMode } from '~/utils/content/magnolia';
import { useOrderSelection } from '~/composables/shop/useOrderSelection';
import useMagnoliaLanguage from '~/composables/useMagnoliaLanguage';
import InputField from '~/templates/elements/form/InputField.vue';
const { warrantyReturn, requestUpload, uploadFile } = useBritaMiddleware();
const { order } = useOrderDetails();
const { selectedItems } = useOrderSelection(order);

const { currentLanguage } = useMagnoliaLanguage();
const { pushError } = useNotifications();

const props = defineProps(['confirmModalArea', 'warrantyClaimArea']);

const emit = defineEmits(['warranty-confirm-modal']);

const { getLabels } = useCommonLabels();
const { reasonLabel, messageLabel } = getLabels('BWF/shop/account/orderHistory/', ['reasonLabel', 'messageLabel']);
const { warrantyOf, failed } = getLabels('BWF/shop/account/orderDetails/', ['warrantyOf', 'failed']);

const warrantyClaimNotifications = getLabels('BWF/shop/account/orderHistory/warrantyClaim', [
    'videoFileSizeError',
    'otherFileSizeError',
    'maxFileQty',
    'notAddedFiles',
]);

const middlewareErrors = getLabels('BWF/shared/middleware/errors', [
    'IncorrectJson',
    'NoFileNameValue',
    'NoFileName',
    'NoFileType',
    'NoAllowedFileType',
]);

const selectedOrderItemsForMiddlewareOnly = computed(
    () => selectedItems.value?.map(item => ({ lineItemID: item.orderLineItemId, quantity: item.quantity })) ?? []
);

const warrantyFormState = reactive({
    orderNumber: computed(() => order?.value?.orderNumber),
    lineItems: computed(() => selectedOrderItemsForMiddlewareOnly.value),
    email: computed(() => order?.value?.orderCustomer?.email),
    first_name: computed(() => order?.value?.orderCustomer?.firstName),
    message: '',
    middlename: '',
    language: currentLanguage?.value ?? '',
    attachments: [],
});

const { requiredValidator, messageValidators, oneItemRequired } = await useValidation();

const warrantyFormRules = computed(() => ({
    reason: {
        ...requiredValidator,
    },
    message: {
        ...requiredValidator,
        ...messageValidators,
    },
    lineItems: {
        ...oneItemRequired,
    },
}));
const selectableReasons =
    props.warrantyClaimArea?.commonLabel?.map(({ name, value }) => ({
        value: name,
        label: value,
    })) ?? [];

const fileInput = ref();
const files = ref([]);
const maxFiles = 5;
const isLoading = ref(true);

const handleFileChange = () => {
    const result = [...files.value];
    const maxFileSizeVideo = 128 * 1024 * 1024; // Max Video File Size 128MB
    const maxFileSizeOther = 30 * 1024 * 1024; // Max Other File Size 30MB

    Array.from(fileInput.value?.files).forEach(file => {
        if (file.type.split('/')[0] === 'video') {
            if (file.size > maxFileSizeVideo) {
                pushError(`${warrantyClaimNotifications?.videoFileSizeError}`);
            } else {
                result.push(file);
                fileInput.value.value = '';
            }
        } else {
            if (file.size > maxFileSizeOther) {
                pushError(`${warrantyClaimNotifications?.otherFileSizeError}`);
            } else {
                result.push(file);
                fileInput.value.value = '';
            }
        }
    });

    if (result.length > maxFiles) {
        pushError(
            `${result.length - maxFiles} ${warrantyClaimNotifications?.notAddedFiles} ${maxFiles} ${
                warrantyClaimNotifications?.maxFileQty
            }`
        );
    }

    files.value = result.slice(0, maxFiles);
};

function handleFileDelete(index) {
    files.value.splice(index, 1);
}

const warrantyReturnForm$ = useVuelidate(warrantyFormRules, warrantyFormState);

const uploadFileToServer = async file => {
    try {
        const requestUploadResponse = await requestUpload({ fileName: file.name });
        await uploadFile(requestUploadResponse?.SAS, file, {
            'x-ms-date': new Date(file.lastModified),
            'x-ms-blob-type': 'BlockBlob',
        });
        return requestUploadResponse;
    } catch (e) {
        const labelError = middlewareErrors?.[e?.data?.errorKey];
        pushError(labelError || e?.data?.error);
        throw 'upload error';
    }
};

const invokeWarranty = async () => {
    warrantyReturnForm$.value.$touch();
    const valid = await warrantyReturnForm$.value.$validate();
    if (valid) {
        isLoading.value = true;

        const fileUploads = [];

        try {
            files.value?.forEach(file => {
                fileUploads.push(uploadFileToServer(file));
            });

            const fileUploadResults = await Promise.all(fileUploads);

            fileUploadResults.forEach(file => {
                warrantyFormState?.attachments.push(file?.filePathName);
            });

            await warrantyReturn(warrantyFormState);
            isLoading.value = false;
            // confirmModal.open();
            emit('warranty-confirm-modal');
        } catch (e) {
            pushError(`${warrantyOf} (${order?.value?.orderNumber}) ${failed}`);
        }
        isLoading.value = false;
    } else if (warrantyReturnForm$?.value?.lineItems?.$errors?.[0]?.$message) {
        pushError(warrantyReturnForm$?.value?.lineItems?.$errors?.[0]?.$message);
    } else {
        pushError(`${warrantyOf} (${order?.value?.orderNumber}) ${failed}`);
    }
};

onMounted(async () => {
    if (!isEditMode()) {
        isLoading.value = false;
    }
});
</script>
