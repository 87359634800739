<template>
    <div class="relative text-base">
        <label class="flex gap-2 items-start" :class="{ 'has-label': !!label, 'border-signal-red': hasError }">
            <span class="relative h-5 w-5 shrink-0 p-1 border rounded-full border-dark-blue">
                <input
                    :id="attrs.value"
                    type="radio"
                    :placeholder="attrs.placeholder"
                    :name="attrs.name"
                    :required="attrs.required"
                    :disabled="attrs.disabled"
                    :value="attrs.value"
                    :checked="modelValue === attrs.value"
                    class="h-full w-full appearance-none absolute inset-0"
                    :class="{ 'has-label': !!label, 'has-error': hasError }"
                    @input="$emit('update:modelValue', attrs.value)"
                    @blur="$emit('blur', $event)"
                    @change="$emit('change', $event)"
                />

                <span class="circle hidden bg-dark-blue rounded-full absolute inset-0.5"></span>
            </span>
            <span class="leading-5 w-full">
                {{ label }}
                <slot />
            </span>
        </label>
        <div
            v-if="errorMessage"
            class="absolute left-0 bottom-0 translate-y-full px-2 pointer-events-none text-signal-red text-sm"
        >
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
const props = defineProps(['modelValue', 'label', 'errorMessage']);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

defineEmits(['update:modelValue', 'blur', 'change']);
</script>

<style scoped>
input {
}

input:checked + .circle {
    @apply block;
}
input.has-error {
    @apply border-signal-red;
}
</style>
