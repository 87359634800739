<template>
    <div
        v-if="components.length > 0"
        ref="rootElement"
        class="relative overflow-hidden"
        :class="{ 'is-mounted': isMounted }"
    >
        <EditableComponent
            v-for="(childContent, i) in mapNodes(content)"
            :key="childContent['@id']"
            :index="i"
            :is-active="isActive(i)"
            :content="childContent"
        />

        <div
            v-if="components.length > 1"
            class="absolute top-0 z-10 overflow-hidden w-full aspect-[6/7] md:aspect-video max-h-screen pointer-events-none"
        >
            <div class="absolute w-full bottom-0">
                <div class="container flex gap-2 pb-6 md:pb-4 xl:pb-10 w-full overflow-auto pointer-events-auto">
                    <button
                        v-for="(childContent, i) in components"
                        :key="childContent['@id']"
                        :class="{ 'is-active': i === index, 'animation-paused': isMenuOpen }"
                        class="btn text-white text-xs md:text-sm bg-black bg-opacity-50 px-4 py-0 md:py-3 h-8 md:h-10 rounded-md shrink-0 last:mr-8 md:last:mr-0"
                        @click="navigateTo(i)"
                        @animationend="nextCarouselSlide"
                    >
                        <span class="z-10">{{ childContent.navText }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { mapNodes } from '~/utils/helper/magnolia';
import { EditableComponent } from '@magnolia/vue-editor';
const props = defineProps(['content']);
const index = ref(0);
const DEFAULT_INTERVAL = '0ms';
const components = computed(() => {
    return mapNodes(props?.content);
});

const interval = ref(props.content?.interval ? `${props.content.interval}ms` : DEFAULT_INTERVAL);

const isActive = carouselIndex => {
    return parseInt(index.value) === parseInt(carouselIndex);
};

const isMounted = ref(false);

const { isMenuOpen } = useMainNavigationState();

onMounted(() => {
    // this is flag to prevent animations from playing (going nuts) before hydration has finished
    isMounted.value = true;
});

const nextCarouselSlide = () => {
    if (interval.value !== DEFAULT_INTERVAL && index?.value < components.value?.length - 1) {
        index.value++;
    } else if (interval.value !== DEFAULT_INTERVAL) {
        index.value = 0;
    }
};

const navigateTo = i => {
    index.value = i;
};
</script>

<style scoped>
.btn {
    @apply relative overflow-hidden;
}

.btn.is-active {
    @apply bg-white bg-opacity-50 text-black;
}

.btn:before {
    @apply bg-white;
    @apply absolute inset-0 right-full;
    content: '';
}

.btn:hover:before {
    @screen md {
        animation-play-state: paused;
    }
}

.animation-paused:before {
    animation-play-state: paused;
}

.is-mounted .btn.is-active:before {
    animation-name: button-progress;
    animation-duration: v-bind(interval);
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
}

@keyframes button-progress {
    0% {
        right: 100%;
    }

    100% {
        right: 0;
    }
}
</style>
