<template>
    <div class="scrollable-text-image-video-component">
        <component
            :is="isEditMode ? 'div' : FadeContainer"
            :animation-variant="1"
            :animation-offset="isDesktop ? 0 : 150"
            @percentage="onPercentage"
        >
            <EditableArea :content="main" class="flex flex-col gap-4" />
        </component>
    </div>
</template>
<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import FadeContainer from '~/templates/elements/FadeContainer.vue';
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
const props = defineProps(['main', 'componentIndex']);
const isEditMode = evaluateEditMode();

const { isDesktop } = useDevice();
const componentPercentageIndex = inject('componentPercentageIndex');

const onPercentage = changedPercentage => {
    componentPercentageIndex.value[props?.componentIndex] = changedPercentage;
};
</script>

<style>
/*
sad but buggy in ff
.scrollable-text-image-video-component {
    scroll-snap-align: center;
} */
</style>
