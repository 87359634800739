<template>
    <div>
        <div v-if="unitPrice && !hasEShopSet" class="flex gap-2 items-end">
            <SharedPrice
                :value="unitPrice"
                class="mt-2 font-medium text-left text-base"
                :class="{ 'lg:text-3xl text-signal-red': isListPrice }"
            />
            <SharedPrice
                v-if="isListPrice"
                :value="price?.listPrice?.price"
                class="font-normal text-sm text-medium-grey mt-1 lg:mt-2 line-through"
            />
        </div>
        <CommonLabel
            v-if="price && containsExchangeBox"
            class="font-normal text-xxxs leading-tight text-medium-grey lg:text-sm"
            path="BWF/shop/exchangeBox/priceCardExchangeBoxHint"
            :placeholder="{ amountCylinders }"
        ></CommonLabel>
        <template v-if="hasEShopSet">
            <div v-if="hasBestPrice" class="flex gap-2 items-end">
                <SharedPrice
                    :value="eShopSet?.eshopSetPriceTotal"
                    class="font-medium text-base lg:text-3xl mt-1 lg:mt-2 text-signal-red"
                />
                <SharedPrice
                    :value="eShopSet?.bestEshopPriceFromHistory"
                    class="font-normal text-sm text-medium-grey mt-1 lg:mt-2 line-through"
                />
            </div>
            <SharedPrice v-else :value="eShopSet?.eshopSetPriceTotal" class="font-medium text-base mt-1 lg:mt-2" />
        </template>
    </div>
</template>

<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';

const props = defineProps<{
    product: Product;
}>();
const { product } = toRefs(props);

const { eShopSet, hasBestPrice, hasEShopSet } = await useProductEShopSetConfigurator();
const { isListPrice, unitPrice, price } = useProductPrice(product);
const { containsExchangeBox, amountCylinders } = await useExchangeBox();
</script>
