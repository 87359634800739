<template>
    <div class="image-component w-full flex flex-col responsive-gap">
        <VideoPictureElement
            class="image-rounded relative w-full aspect-2/3 sm:aspect-video max-h-screen mx-auto overflow-hidden safari-overflow-hidden-fix"
            :img="image"
            img-class="absolute inset-0 w-full h-full object-cover"
            :breakpoints="BREAKPOINTS"
            :show-video-inline="isDesktop"
            :video="video"
        >
            <div class="relative inset-0 h-full w-full">
                <div
                    v-if="opacity > 0"
                    class="absolute inset-0 bg-black"
                    :class="contentToTailwindClasses({ opacity: opacity ?? 0 })"
                ></div>
                <div
                    class="relative container flex flex-col h-full w-full gap-4 xl:gap-8 p-8 inset-0"
                    :class="{
                        'items-center justify-center text-center': position === 'center',
                        'items-start justify-end': position !== 'center',
                    }"
                >
                    <div v-if="text" class="text-white text-xl font-medium md:text-4xl w-10/12 lg:w-1/2">
                        {{ text }}
                    </div>

                    <button v-if="hasVideo(video)" class="btn btn-circle bg-white h-16 w-16 md:h-24 md:w-24">
                        <SvgIcon class="text-black w-6 h-6 xl:w-8 xl:h-8" name="Play"></SvgIcon>
                    </button>
                </div>
            </div>
        </VideoPictureElement>

        <div v-if="subline || headline?.text" class="container no-nested-container-padding">
            <HeadlineComponent
                v-if="headline?.text"
                class="font-medium text-2xl"
                :headline="headline"
            ></HeadlineComponent>
            <div v-if="subline" class="mt-2 xl:mt-4">
                {{ subline }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import HeadlineComponent from './HeadlineComponent';
import SvgIcon from '../../elements/SvgIcon.vue';
import VideoPictureElement from '~/templates/elements/VideoPictureElement.vue';
import { hasVideo } from '~/utils/helper/video';

const { isDesktop } = useDevice();

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['2/3'],
    sm: DIMENSIONS['sm'].ratio.video,
    md: DIMENSIONS['md'].ratio.video,
    xl: DIMENSIONS['xl'].ratio.video,
    container: DIMENSIONS['container'].ratio.video,
};
</script>

<script>
export default {
    name: 'ImageComponent',
    props: ['image', 'video', 'text', 'opacity', 'position', 'headline', 'subline'],
};
</script>

<style>
.image-component .image-rounded {
    @apply rounded-xl;
}

.no-container .image-rounded {
    @apply rounded-none;
}
</style>
