<template>
    <div class="image-text">
        <div
            class="image-text-content flex responsive-gap-x gap-y-8"
            :class="{
                'is-mobile-reversed': isMobileReversed,
                'is-reversed': isReversed,
                'disable-padding': paddingDisabled,
            }"
        >
            <div class="w-full">
                <VideoPictureElement
                    class="relative w-full mx-auto overflow-hidden safari-overflow-hidden-fix rounded-xl bg-light-grey"
                    :class="contentToTailwindClasses({ aspectRatio: ratio })"
                    :img="image"
                    :img-class="`absolute inset-0 w-full h-full object-cover ${
                        ratio === 'original' ? 'relative' : 'absolute'
                    }`"
                    :breakpoints="getImagingBreakpoints(ratio)"
                    :video="video"
                >
                </VideoPictureElement>
            </div>
            <div class="flex items-center w-full">
                <EditableArea
                    class="flex flex-col w-full text-col"
                    :class="[
                        contentToResponsiveGapClasses({ gap: hasValue(main?.spacing) ? main?.spacing : 'xxs' }),
                        contentToTailwindClasses({
                            textAlign: main?.horizontal,
                            flexItems: main?.horizontal,
                        }),
                    ]"
                    :content="main"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import { DIMENSIONS } from '~/utils/helper/imaging';
import { contentToTailwindClasses, contentToResponsiveGapClasses } from '~/utils/helper/tailwind';
import VideoPictureElement from '~/templates/elements/VideoPictureElement.vue';
import { hasValue } from '~/utils/helper/magnolia';

let BREAKPOINTS_SQUARE = {
    start: DIMENSIONS['start'].ratio.square,
    sm: DIMENSIONS['sm'].ratio.square,
    md: DIMENSIONS['md'].width['1/2'].ratio.square,
    xl: DIMENSIONS['xl'].width['1/2'].ratio.square,
    container: DIMENSIONS['container'].width['1/2'].ratio.square,
};
let BREAKPOINTS_TV = {
    start: DIMENSIONS['start'].ratio?.['4/3'],
    sm: DIMENSIONS['sm'].ratio?.['4/3'],
    md: DIMENSIONS['md'].width['1/2'].ratio?.['4/3'],
    xl: DIMENSIONS['xl'].width['1/2'].ratio?.['4/3'],
    container: DIMENSIONS['container'].width['1/2'].ratio?.['4/3'],
};
let BREAKPOINTS_ORIGINAL = {
    start: DIMENSIONS['start'].ratio.original,
    sm: DIMENSIONS['sm'].ratio.original,
    md: DIMENSIONS['md'].width['1/2'].ratio.original,
    xl: DIMENSIONS['xl'].width['1/2'].ratio.original,
    container: DIMENSIONS['container'].width['1/2'].ratio.original,
};
let BREAKPOINTS_VIDEO = {
    start: DIMENSIONS['start'].ratio.video,
    sm: DIMENSIONS['sm'].ratio.video,
    md: DIMENSIONS['md'].width['1/2'].ratio.video,
    xl: DIMENSIONS['xl'].width['1/2'].ratio.video,
    container: DIMENSIONS['container'].width['1/2'].ratio.video,
};

const getImagingBreakpoints = ratio => {
    if (ratio === 'tv') {
        return BREAKPOINTS_TV;
    } else if (ratio === 'video') {
        return BREAKPOINTS_VIDEO;
    } else if (ratio === 'original') {
        return BREAKPOINTS_ORIGINAL;
    }
    return BREAKPOINTS_SQUARE;
};
</script>

<script>
export default {
    name: 'ImageTextComponent',
    props: ['image', 'video', 'main', 'ratio', 'order', 'mobileOrder', 'disablePadding'],
    data: () => ({
        showVideo: false,
    }),
    computed: {
        contentCenter() {
            return this?.position === 'center';
        },
        isReversed() {
            return parseInt(this.order) === 1;
        },
        isMobileReversed() {
            return parseInt(this.mobileOrder) === 1;
        },
        paddingDisabled() {
            return this.main?.disablePadding ?? false;
        },
    },
};
</script>

<style scoped>
.image-text-content {
    --parent-col-width: calc(200% * (1 / 12));
    @apply flex-col md:flex-row;
    &.is-mobile-reversed {
        @apply flex-col-reverse;
    }
    &.is-reversed {
        @apply md:flex-row-reverse;
    }

    @screen xl {
        .text-col {
            padding-left: var(--parent-col-width);
            padding-right: var(--parent-col-width);
        }
    }

    &.disable-padding:not(.is-reversed) .text-col {
        @apply xl:pr-0;
    }
    &.disable-padding.is-reversed .text-col {
        @apply xl:pl-0;
    }
}
</style>
