<template>
    <div>
        <div v-if="(isTablet && mapNodes(content).length > 2) || (isMobile && mapNodes(content).length > 1)">
            <SliderElement
                :class="'benefits-component-slider'"
                :pagination="true"
                :disable-buttons="true"
                :components="mapNodes(content)"
                :swiper-options="swiperOptions"
            ></SliderElement>
        </div>
        <div v-else class="flex responsive-gap">
            <EditableComponent
                v-for="childContent in mapNodes(content)"
                :key="childContent['@id']"
                :content="childContent"
            />
        </div>
    </div>
</template>

<script setup>
import { EditableComponent } from '@magnolia/vue-editor';
import SliderElement from '~/templates/elements/SliderElement.vue';
import { mapNodes } from '~/utils/helper/magnolia';
import { screens } from '~/tailwind.config.screens';
import useDevice from '~/composables/useDevice';
defineProps(['content']);
const { isMobile, isTablet } = useDevice();
const swiperOptions = {
    slidesPerView: 1.3,
    spaceBetween: 8,
    centeredSlides: false,
    loop: false,
    breakpoints: {
        [parseInt(screens.sm)]: {
            slidesPerView: 2.5,
        },
    },
};
</script>

<style>
.benefits-component-slider {
    @apply w-full;
}
.benefits-component-slider .swiper-slide {
    @apply h-auto;
    .benefit-tile {
        @apply h-full;
    }
}
</style>
