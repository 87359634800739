<template>
    <span
        class="badge is-outline"
        :class="{
            'is-grey': isCancelled,
            'is-green ': isActive,
            'is-red ': isPaymentUpdateRequested,
        }"
    >
        <CommonLabel v-if="isCancelled" path="BWF/shop/account/subscriptions/contract/statusCancelled" />
        <CommonLabel
            v-else-if="isPaymentUpdateRequested"
            path="BWF/shop/account/subscriptions/contract/paymentUpdateRequested"
        />
        <CommonLabel v-else-if="isActive" path="BWF/shop/account/subscriptions/contract/statusActive" />
    </span>
</template>

<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
const props = defineProps(['status']);

const isCancelled = computed(() => props.status?.includes('cancel'));
const isPaymentUpdateRequested = computed(() => props.status === 'payment_update_requested');
const isActive = computed(() => props.status === 'active');
</script>
