<template>
    <div>
        <CheckboxField v-if="!isDummyAddress" v-model="changeShippingToBilling">
            <CommonLabel path="BWF/shop/shared/addressForm/identicalToBillingAddress" />
        </CheckboxField>
        <AccountAddressForm
            :address="address"
            @address-submit="invokeSave"
            @close-modal="emit('close-modal')"
        ></AccountAddressForm>
    </div>
</template>

<script setup lang="ts">
import type { CustomerAddress } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import useAccountTracking from '~/composables/tracking/useAccountTracking';
import useShopwareNotifications from '~/composables/useShopwareNotifications';
const { refreshSessionContext } = useSessionContext();
const { defaultBillingAddressId, defaultShippingAddressId } = useUser();
const props = defineProps<{
    address: CustomerAddress;
}>();

const changeShippingToBilling = ref(false);
const { getCountries } = useCountries();
const { createCustomerAddress, updateCustomerAddress, setDefaultCustomerShippingAddress } = useAddress();
const { pushSuccess, pushError } = useNotifications();
const { pushShopwareError } = useShopwareNotifications();
const hasValidShippingCountry = (address: CustomerAddress) => {
    return !!getCountries.value.find(country => country.id === address.countryId && country.shippingAvailable);
};

const emit = defineEmits<{
    (e: 'close-modal'): void;
}>();

const identicalAddresses = defaultShippingAddressId.value === defaultBillingAddressId.value;

const isDummyAddress = computed(() => !!props.address?.customFields?.dummyAddress);
const currentAddressId = computed(() => props.address?.id);
const { getLabels } = useCommonLabels();
const addressFormPushLabels = getLabels('BWF/shared/notifications/', [
    'setDefaultBillingAddressError',
    'setDefaultBillingAddressSuccess',
]);

const setAddressSuccess = addressFormPushLabels?.setDefaultBillingAddressSuccess;
const { trackAccountModification } = useAccountTracking();
const invokeSave = async (addressFormState: never): Promise<void> => {
    try {
        // update payload with id
        const updateAddress: CustomerAddress = {
            ...addressFormState,
            id: currentAddressId.value,
        };
        // clone address
        const clonedAddress: CustomerAddress = {
            ...(isDummyAddress.value ? addressFormState : props.address),
        };
        // rm id from clone just in case
        delete clonedAddress.id;

        if (identicalAddresses) {
            /* silent update for shopware because dummy address must be converted to a real address,
            or the following operations run into errors */
            await updateCustomerAddress(updateAddress, true);
            // create shipping with old values if identical
            if (hasValidShippingCountry(clonedAddress)) {
                // CLONE OLD ADDRESS AND USE AS SHIPPING
                const newShippingAddress = await createCustomerAddress(clonedAddress, true);
                await setDefaultCustomerShippingAddress(newShippingAddress.id, true);
            } else {
                console.error('cannot autocreate shipping as nilling has no valid country selected');
            }
        } else if (changeShippingToBilling.value) {
            // CHECKBOX
            if (hasValidShippingCountry(addressFormState)) {
                // update shipping address with values
                const updatedAddress = await updateCustomerAddress(
                    {
                        ...addressFormState,
                        id: defaultShippingAddressId.value,
                    },
                    true
                );
                await setDefaultCustomerShippingAddress(updatedAddress.id, true);
            } else {
                pushError(addressFormPushLabels?.setDefaultShippingAddressError);
            }
        }
        /**
         * After completing the additional actions above
         * update billing with form & trigger customer MW update
         */
        await updateCustomerAddress(updateAddress);

        await refreshSessionContext();
        trackAccountModification('modify billing');
        emit('close-modal');
        pushSuccess(`${setAddressSuccess}`);
    } catch (error) {
        await pushShopwareError(error);
    }
};
</script>
