<template>
    <div class="container">
        <div v-if="!order && isLoading === false" class="text-4xl text-center my-8 text-dark-blue font-medium">
            <!--            TODO show 404 !?-->
            {{ successLabels?.orderMissing }}
        </div>
        <div v-else class="mx-auto xl:w-6/12" :class="{ 'animate-pulse': isLoading }">
            <div class="flex flex-col xl:gap-4">
                <div class="text-brand-dark font-medium text-lg xl:text-3xl">
                    {{ successLabels?.orderReceived }}
                </div>
                <div>
                    {{ successLabels?.confirmationEnRoute }}
                </div>

                <LoadingElement v-if="isLoading" class="aspect-[3/1]"></LoadingElement>
                <div v-else class="grid grid-cols-[1fr_2fr] mt-3 xl:mt-2 gap-1 xl:gap-2 text-sm xl:text-base">
                    <div class="font-medium">{{ successLabels?.orderNumberLabel }}</div>
                    <div>{{ order?.orderNumber }}</div>
                    <div class="font-medium">{{ sharedLabels?.tableQuantity }}</div>
                    <div>{{ orderArticleCount(order) }}</div>
                    <div class="font-medium">{{ successLabels?.shippingDate }}</div>
                    <div>{{ shippingMethod?.deliveryTime?.name }}</div>
                    <div v-if="shippingAddress" class="font-medium">
                        {{ sharedLabels?.shippingAddress }}
                    </div>
                    <div v-if="shippingAddress">
                        <div>
                            {{ shippingAddress?.firstName }}
                            {{ shippingAddress?.lastName }}
                        </div>
                        <div>
                            {{ shippingAddress?.street }}
                        </div>
                        <div v-if="shippingAddress.additionalAddressLine1">
                            {{ shippingAddress?.additionalAddressLine1 }}
                        </div>
                        <div>{{ shippingAddress?.city }}, {{ shippingAddress?.zipcode }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { orderArticleCount } from '~/utils/helper/shop/order';
import LoadingElement from '~/templates/elements/LoadingElement.vue';

const props = defineProps(['order', 'isLoading']);

const shippingMethod = computed(() => props.order?.deliveries?.[0]?.shippingMethod);
const shippingAddress = computed(() => props.order?.deliveries?.[0]?.shippingOrderAddress);

const { getLabels } = useCommonLabels();
const successLabels = getLabels('BWF/shop/checkout/success');
const sharedLabels = getLabels('BWF/shop/shared');
</script>
