<template>
    <div ref="root" class="cc-root">
        <MollieCreditCardComponent
            v-if="creditCardEnabled"
            ref="mollie"
            :settings="mollieSettings"
            @error="onCreditCardError"
        />
    </div>
</template>
<script setup lang="ts">
import { useShopwareContext, useUser } from '@shopware-pwa/composables-next';
import MollieCreditCardComponent from '~/templates/components/shop/checkout/MollieCreditCardComponent.vue';

const { apiInstance } = useShopwareContext();
const { user } = useUser();
const { currentLanguage } = useMagnoliaLanguage();
// https://docs.mollie.com/reference/mollie-js/mollie-object#mollie-profileid-options
const SUPPORTED_MOLLIE_LOCALES = [
    'en_US',
    'nl_NL',
    'nl_BE',
    'fr_FR',
    'fr_BE',
    'de_DE',
    'de_AT',
    'de_CH',
    'es_ES',
    'ca_ES',
    'pt_PT',
    'it_IT',
    'nb_NO',
    'sv_SE',
    'fi_FI',
    'da_DK',
    'is_IS',
    'hu_HU',
    'pl_PL',
    'lv_LV',
];

defineProps<{
    submitButtonLabel?: string;
    submitDisabled?: boolean;
}>();

const emits = defineEmits<{
    (e: 'submit', token: string | undefined): void;
    (e: 'error', error: string | undefined): void;
}>();

const doCreditCardSubmit = async (token: string | undefined) => {
    const response = await apiInstance.invoke.post(
        `/store-api/mollie/creditcard/store-token/${user.value?.id}/${token}`,
        {}
    );
    emits('submit', token);
    return response;
};

const root = ref<HTMLElement | null>(null);
const mollie = ref(null);

const mollieSettings = ref({});
const creditCardEnabled = ref(false);

const onCreditCardError = async (error: string | undefined) => {
    emits('error', error);
    root.value?.scrollIntoView?.();
};

const checkFieldsAndSubmitToken = async () => {
    try {
        if (!globalThis.Mollie || !creditCardEnabled.value) {
            return true;
        }

        const token = await mollie.value?.getToken?.();
        const {
            data: { success },
        } = await doCreditCardSubmit(token);
        return success;
    } catch (e) {
        root.value?.scrollIntoView?.();
        return false;
    }
};

defineExpose({ checkFieldsAndSubmitToken });

const { data, error } = await apiInstance.invoke.get(`/store-api/mollie/settings`);
if (error?.value) {
    console.error('could not load mollie settings');
} else {
    const mollieLanguage = SUPPORTED_MOLLIE_LOCALES.find(
        mLang => mLang.toLowerCase() === `${currentLanguage.value}`.toLowerCase()
    );

    mollieSettings.value = {
        profileId: data.profileId,
        testMode: data.isTestMode,
        ...(mollieLanguage ? { locale: currentLanguage.value } : {}),
    };
    creditCardEnabled.value = data?.areCreditCardComponentsEnabled;
}
</script>

<style scoped>
.cc-root {
    scroll-margin-top: theme('spacing.28');
}
</style>
