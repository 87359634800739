<template>
    <p
        class="font-medium whitespace-pre-wrap hyphens-auto"
        :class="
            contentToTailwindClasses({
                fontSize,
            })
        "
    >
        {{ text }}
    </p>
</template>

<script>
import { contentToTailwindClasses } from '~/utils/helper/tailwind';

export default {
    name: 'EditorialStoryTextComponent',
    props: ['text', 'fontSize'],
    methods: { contentToTailwindClasses },
};
</script>
