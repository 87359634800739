<template>
    <div class="contents">
        <div v-if="hasBestPrice" class="flex gap-2 items-end">
            <SharedPrice
                :value="eShopSet?.eshopSetPriceTotal"
                class="font-medium text-2xl lg:text-3xl mt-1 lg:mt-2 text-signal-red"
            />
            <SharedPrice
                :value="eShopSet?.bestEshopPriceFromHistory"
                class="font-normal text-base text-medium-grey mt-1 lg:mt-2 line-through"
            />
        </div>
        <SharedPrice
            v-else
            :value="eShopSet?.eshopSetPriceTotal"
            class="font-medium text-2xl lg:text-3xl mt-1 lg:mt-2"
        />
        <div class="mt-1 font-normal text-xxxs leading-tight text-medium-grey lg:text-sm">
            <CommonLabel path="BWF/shop/productDetailPage/plus"></CommonLabel>
            <span>&nbsp;</span>
            <CommonLabel path="BWF/shop/checkout/cart/taxes" />
        </div>
    </div>
</template>

<script setup lang="ts">
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';

const { eShopSet, hasBestPrice } = await useProductEShopSetConfigurator();
</script>
