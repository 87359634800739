<template>
    <p v-if="content.filterHeadline" class="text-base leading-snug font-normal">{{ content.filterHeadline }}</p>
    <FilterButtons
        v-if="content.showFilter"
        class="mt-4 mb-8 md:mb-12 xl:mt-5"
        :filters="categoryFilterButtons"
        :all-label="content.filterAllLabel ?? 'Alle'"
        :show-all-filter="content.showAllFilter"
        @filter-change="onFilter"
    ></FilterButtons>
    <div>
        <div class="grid gap-4 md:responsive-gap md:grid-cols-2">
            <EditableComponent
                v-for="childContent in visibleComponents"
                :key="childContent['@id']"
                :content="childContent"
            />
        </div>
    </div>
</template>

<script setup>
import FilterButtons from '~/templates/components/FilterButtons.vue';
import { EditableComponent } from '@magnolia/vue-editor';
import { mapNodes } from '~/utils/helper/magnolia';
import useFilterTracking from '~/composables/tracking/useFilterTracking';

const FILTER_TYPE = 'category_filter';

const props = defineProps(['content']);
let activeFilters = ref([]);

let uniqueFilters = mergeArraysOfObjectsToOneArray(mapNodes(props.content));
const categoryFilterButtons = [...uniqueFilters?.map(filter => ({ label: filter['value'], value: filter['@id'] }))];

useFilterTracking({ type: FILTER_TYPE, filters: categoryFilterButtons, activeFilters: activeFilters });

const visibleComponents = computed(() => {
    return activeFilters.value.length === 0
        ? mapNodes(props.content)
        : mapNodes(props.content).filter(node =>
              node.filterTags?.some(filter => activeFilters.value?.includes(filter['@id']))
          );
});

const onFilter = currentFilter => {
    activeFilters.value = currentFilter;
};

function mergeArraysOfObjectsToOneArray(nodes) {
    /**
     * .flatMap flatten the filters arrays
     * .map convert each object to a string fpr comparison
     * .map convert each unique string back to an object
     * */
    return Array.from(new Set(nodes.flatMap(node => node?.filterTags.map(JSON.stringify)))).map(JSON.parse);
}
</script>
