<template>
    <dl>
        <div v-if="isAvailable" class="flex items-center gap-x-2 w-full text-green">
            <dt class="flex-none">
                <span class="sr-only">delivery time</span>
                <svg-icon name="Checkmark-Circle" class="h-4 w-4 stroke-2 lg:w-5 lg:h-5" aria-hidden="true" />
            </dt>
            <dd class="text-sm font-normal leading-5 lg:text-base lg:leading-6">
                <CommonLabel
                    path="BWF/shop/productDetailPage/deliveryTime"
                    :placeholder="{ deliveryTime: product?.deliveryTime?.name }"
                >
                </CommonLabel>
            </dd>
        </div>
        <div v-else class="text-medium-grey">
            <dd class="text-sm font-normal leading-5 lg:text-base lg:leading-6">
                <span class="sr-only">product not available</span>
                <CommonLabel path="BWF/shop/productDetailPage/productNotAvailable"></CommonLabel>
            </dd>
        </div>
    </dl>
</template>
<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';

const props = defineProps<{ product: Product; isAvailable: boolean }>();
const { product } = toRefs(props);
</script>
