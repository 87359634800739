<template>
    <div class="relative text-base">
        <textarea
            :id="`input-${attrs?.name}`"
            :placeholder="
                attrs?.placeholder && attrs?.required ? `${attrs.placeholder}${requiredChar}` : attrs.placeholder
            "
            :name="attrs?.name"
            :autocomplete="attrs?.autocomplete"
            :readonly="attrs?.readonly"
            :disabled="attrs?.disabled"
            :value="modelValue"
            :maxlength="attrs?.maxlength"
            class="appearance-none bg-white h-48 w-full p-2 border border-light-grey rounded-t-sm"
            :class="{ 'has-error': hasError }"
            :aria-label="attrs?.label"
            @input="$emit('update:modelValue', $event.target.value)"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
        />
        <div v-if="attrs?.maxlength >= 0" class="prose prose-sm">
            <CommonLabel
                path="BWF/form/shared/remainingCharsLabel"
                :placeholder="{ remainingChars: Math.max(0, attrs?.maxlength - modelValue.length) }"
            ></CommonLabel>
        </div>
        <div v-if="errorMessage" class="px-2 pointer-events-none text-signal-red text-xs">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';

const props = defineProps(['modelValue', 'label', 'errorMessage']);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

const requiredChar = inject('input-required-char', '*');

defineEmits(['update:modelValue', 'blur', 'change']);
</script>

<style scoped>
[readonly],
[disabled] {
    @apply cursor-not-allowed grayscale opacity-60;
}

textarea::placeholder {
    @apply text-medium-grey;
}

textarea:focus {
    @apply outline-none border-b-deep-blue;
}

textarea.has-error {
    @apply border-signal-red;
}
</style>
