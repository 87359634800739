<template>
    <div class="flex flex-col gap-8">
        <HeadlineComponent
            class="no-container-page-offset text-2xl xl:text-3xl text-dark-blue"
            :headline="headline"
        ></HeadlineComponent>
        <EditableArea class="category-slider" :content="main" :custom-view="SliderArea" />
        <div class="no-container-page-offset flex flex-col gap-2 items-start">
            <div v-if="text" class="xl:text-lg">
                {{ text }}
            </div>
            <CallToActionComponent v-if="link?.label" :link="link" class="btn btn-ghost"></CallToActionComponent>
        </div>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import HeadlineComponent from './HeadlineComponent';
import SliderArea from '../../areas/SliderArea';
import { screens } from '~/tailwind.config.screens';
import CallToActionComponent from './CallToActionComponent';
import useNavigationTracking, { NAVIGATION_SLIDER_CATEGORY } from '~/composables/tracking/useNavigationTracking';
useNavigationTracking(NAVIGATION_SLIDER_CATEGORY);

defineProps(['main', 'headline', 'text', 'link']);

provide('swiperOptions', {
    slidesPerView: 1.2,
    spaceBetween: 8,
    centeredSlides: false,
    loop: false,
    breakpoints: {
        [parseInt(screens.md)]: {
            slidesPerView: 2.4,
            spaceBetween: 16,
        },
        [parseInt(screens.xl)]: {
            slidesPerView: 4,
            spaceBetween: 24,
        },
    },
});
</script>

<style>
.category-slider .swiper .swiper-slide {
    @apply h-auto;
    max-width: calc((10 / 12) * 100% + 8px);
    @screen md {
        max-width: calc((5 / 12) * 100% + 16px);
    }
    @screen xl {
        max-width: calc((3 / 12) * 100% + 24px);
    }
}
</style>
