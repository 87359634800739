import { usePrice as coreUsePrice } from '@shopware-pwa/composables-next';
import useMagnoliaLanguage from '~/composables/useMagnoliaLanguage';

export function usePrice() {
    const swUsePrice = coreUsePrice();
    const { currency } = useSessionContext();
    const { currentLanguage } = useMagnoliaLanguage();

    // const priceFormatterOptions = reactive({ style: 'currency', currency: 'EUR' });
    const formatterLocale = currentLanguage?.value?.replace('_', '-');

    // precision is in type but missing in currency response
    const decimalPrecision = currency?.value?.decimalPrecision ?? 2;
    const priceFormatter = new Intl.NumberFormat(formatterLocale, {
        style: 'currency',
        currency: currency?.value?.isoCode ?? 'EUR',
        maximumFractionDigits: decimalPrecision,
        minimumFractionDigits: decimalPrecision,
    });

    function getIntlFormattedPrice(value: number | undefined): string {
        return priceFormatter.format(value ?? 0);
    }

    return {
        ...swUsePrice,
        getIntlFormattedPrice,
    };
}
