<template>
    <div>
        <div class="font-medium text-lg xl:text-lg mb-2 xl:my-4">
            <CommonLabel path="BWF/shop/account/orderDetails/returnInformations"></CommonLabel>
        </div>
        <div class="font-medium text-sm xl:text-sm mb-2 xl:my-4">
            <CommonLabel path="BWF/shop/account/orderDetails/returnDate"></CommonLabel>
        </div>
        <div class="text-base xl:text-base mb-2 xl:my-4">
            {{ returnDate }}
        </div>
        <div v-if="parcelCount > 1" class="notification is-blue col-span-full gap-4 mt-4 mb-0">
            <SvgIcon name="Tooltip" class="h-6 w-6"></SvgIcon>
            <CommonLabel
                class="prose-black"
                path="BWF/shop/account/orderDetails/exchangeBox/returnLabelTamNotification"
            ></CommonLabel>
        </div>
        <div v-else-if="retoureLabel" class="font-medium text-base xl:text-xl mb-2 xl:my-4">
            <a
                class="flex gap-2 items-end text-base text-link no-underline"
                :href="`${cleanedShopwareEndpoint}/${retoureLabel}`"
                target="_blank"
                download
            >
                <CommonLabel
                    class="truncate leading-none no-underline"
                    path="BWF/shop/account/orderDetails/returnLabel"
                ></CommonLabel>
                <SvgIcon class="h-5 w-5" name="Download"></SvgIcon
            ></a>
        </div>
        <div v-else class="notification is-blue col-span-full gap-4 mt-4 mb-0">
            <SvgIcon name="Tooltip" class="h-6 w-6"></SvgIcon>
            <CommonLabel
                class="prose-black"
                path="BWF/shop/account/orderDetails/exchangeBox/returnLabelAvailabilityNotification"
            ></CommonLabel>
        </div>
    </div>
</template>
<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import { useShopConfiguration } from '~/composables/shop/useShopConfiguration';
const { apiInstance } = useShopwareContext();
const { cleanedShopwareEndpoint } = useShopConfiguration();

const props = defineProps(['order']);

const parcelCount = props?.order?.extensions?.returns?.[0]?.extensions?.parcelCount.value;

const retoureLabel = ref('');

onMounted(async () => {
    if (props.order?.extensions?.returns?.length) {
        try {
            const { data } = await apiInstance.invoke.get(`/store-api/order/${props.order?.id}/label`);
            retoureLabel.value = data?.data?.orderLabelUrl;
        } catch (e) {
            console.error(e);
        }
    }
});

const returnDate = computed(() => {
    return new Date(props.order?.extensions?.returns?.[0]?.requestedAt ?? '').toLocaleDateString(
        (typeof navigator !== 'undefined' && navigator.language) || 'de-DE'
    );
});
</script>
