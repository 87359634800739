<template>
    <div class="flex flex-col gap-8">
        <HeadlineComponent
            class="no-container-page-offset text-2xl xl:text-3xl text-dark-blue"
            :headline="headline"
            tag="h2"
        ></HeadlineComponent>
        <EditableArea class="story-slider" :content="main" :custom-view="SliderArea" />
    </div>
</template>

<script setup>
import useNavigationTracking, { NAVIGATION_SLIDER_STORY } from '~/composables/tracking/useNavigationTracking';
import { EditableArea } from '@magnolia/vue-editor';
import SliderArea from '../../areas/SliderArea';
import HeadlineComponent from './HeadlineComponent';
import { screens } from '~/tailwind.config.screens';

useNavigationTracking(NAVIGATION_SLIDER_STORY);

defineProps(['main', 'headline']);

provide('swiperOptions', {
    slidesPerView: 1.2,
    spaceBetween: 8,
    centeredSlides: false,
    loop: false,
    breakpoints: {
        // when window width is >= 320px
        [parseInt(screens.md)]: {
            slidesPerView: 2.4,
            spaceBetween: 16,
        },
        // when window width is >= 480px
        [parseInt(screens.xl)]: {
            slidesPerView: 3,
            spaceBetween: 24,
        },
    },
});
</script>

<style>
.story-slider .swiper .swiper-slide {
    @apply h-auto;
    max-width: calc((10 / 12) * 100% + 8px);
    @screen md {
        max-width: calc((5 / 12) * 100% + 16px);
    }
    @screen xl {
        max-width: calc((4 / 12) * 100% + 24px);
    }
}
</style>
