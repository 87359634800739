<template>
    <div>
        <EditableArea v-if="main" :content="main" />

        <div class="container mb-20">
            <LoadingElement
                v-if="isLoading && !isEditMode()"
                class="w-full aspect-3/4 lg:aspect-video"
            ></LoadingElement>

            <template v-else>
                <EditableArea v-if="isEmpty || isEditMode()" :content="emptyCartArea" />
                <div v-if="!isEmpty">
                    <BackButton class="hidden xl:flex my-6"></BackButton>
                    <div class="my-6 grid gap-4 xl:grid-cols-3 xl:gap-6">
                        <div class="xl:col-span-2 flex flex-col gap-4">
                            <div class="flex items-center justify-between">
                                <HeadlineComponent tag="h1" class="text-lg xl:text-4xl font-medium text-dark-blue">
                                    <CommonLabel path="BWF/shop/checkout/cart/cartHeadline"></CommonLabel>
                                </HeadlineComponent>
                                <div v-if="count > 0" class="text-sm">
                                    <CommonLabel class="font-medium" path="BWF/shop/shared/articleCount"></CommonLabel
                                    >&nbsp;{{ count }}
                                </div>
                            </div>
                            <hr />

                            <ul role="list" class="">
                                <li
                                    v-for="(cartItem, index) in getLineItemsWithoutPromotion(cartItems)"
                                    :key="cartItem.id"
                                    role="listitem"
                                >
                                    <CheckoutCartItem :cart-item="cartItem" :column-names="index === 0" />
                                    <hr
                                        v-if="index < getLineItemsWithoutPromotion(cartItems).length - 1"
                                        class="my-6"
                                    />
                                </li>
                            </ul>
                        </div>

                        <aside class="xl:col-span-1 flex flex-col gap-4">
                            <CheckoutSummary />
                        </aside>
                    </div>
                    <hr class="col-span-full mb-10 hidden xl:block" />

                    <div class="grid gap-8 xl:grid-cols-3">
                        <div class="flex flex-col gap-10">
                            <NuxtLink
                                class="btn btn-blue btn-size-normal"
                                :external="true"
                                :to="marketSetupAdmin?.checkoutPage ?? ''"
                            >
                                <CommonLabel path="BWF/shop/checkout/cart/continueToCheckout"></CommonLabel>
                            </NuxtLink>
                            <ShopBenefits class="hidden xl:block"></ShopBenefits>
                        </div>
                        <div class="xl:col-span-2 xl:order-first">
                            <button class="text-icon-link mx-auto xl:mx-0" @click="$router.go(-1)">
                                <SvgIcon name="Arrow-Up" class="h-4 w-4 -rotate-90"></SvgIcon>
                                <CommonLabel path="BWF/shop/checkout/cart/backToShop"></CommonLabel>
                            </button>
                        </div>
                        <ShopBenefits class="xl:hidden"></ShopBenefits>

                        <CommonLabel class="text-sm mt-4 xl:mt-14" path="BWF/shared/vatFootnote" />
                    </div>
                </div>
            </template>
        </div>

        <EditableArea v-if="bottom" :content="bottom" />
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import CheckoutCartItem from '../components/shop/checkout/CheckoutCartItem.vue';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import { onMounted } from 'vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import CheckoutSummary from '~/templates/components/shop/checkout/CheckoutSummary.vue';
import { getLineItemsWithoutPromotion } from '~/utils/helper/shop/cart';
import ShopBenefits from '~/templates/components/shop/product/ShopBenefits.vue';
import BackButton from '~/templates/components/BackButton.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useCartInformation } from '~/composables/shop/useCartInformation';
import { isEditMode } from '~/utils/content/magnolia';
import useCheckoutTracking from '~/composables/tracking/useCheckoutTracking';
import useShopwareNotifications from '~/composables/useShopwareNotifications';
import { useNotifications } from '@shopware-pwa/composables-next';

defineOptions({
    inheritAttrs: false,
});
const { marketSetupAdmin } = useMarketSetup();

const { cartItems, refreshCart, cart, consumeCartErrors } = useCart();
const { getLabelByPath } = useCommonLabels();
const { isEmpty, count } = useCartInformation();
const { pushShopwareError, pushShopwareCartErrors } = useShopwareNotifications();
const { pushSuccess } = useNotifications();

const isLoading = ref(true);
defineProps(['title', 'metadata', 'main', 'emptyCartArea', 'bottom']);
const { trackCartView } = useCheckoutTracking();

watch(cart, () => {
    const errors = consumeCartErrors();
    if (errors) {
        pushShopwareCartErrors(errors);
        if (Object.values(errors).find(error => error.messageKey.includes('promotion-discount-added'))) {
            const discountAdded = getLabelByPath('BWF/shop/messages/promotion-discount-added');
            pushSuccess(discountAdded);
        }
    }
});

onMounted(async () => {
    isLoading.value = true;
    try {
        await refreshCart();
        trackCartView();
    } catch (e) {
        showError({ statusCode: 500, statusMessage: 'Cart Error' });
        await pushShopwareError(e);
    }
    isLoading.value = false;
});
</script>
