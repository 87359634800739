<template>
    <AccountGrid>
        <div class="account-overview-cards grid xl:grid-cols-2 gap-4 xl:gap-6">
            <div class="mb-8 xl:mb-16 col-span-full">
                <h2 class="text-2xl font-medium text-dark-blue xl:text-3xl">
                    <CommonLabel path="BWF/shop/account/overview/greeting"></CommonLabel
                    ><span>{{ user?.firstName ? ',&nbsp;' + user?.firstName : '' }}</span>
                </h2>
                <p class="mt-4">
                    <CommonLabel path="BWF/shop/account/overview/mybritaOpeningText"></CommonLabel>
                </p>
            </div>

            <section class="flex flex-col border border-grey rounded-lg px-4 py-6 xl:px-6">
                <h3 class="font-medium text-lg mb-4">
                    <CommonLabel path="BWF/shop/account/overview/myProfile"></CommonLabel>
                </h3>

                <div>
                    <span v-if="!hasNoSalutation">
                        {{
                            //@ts-ignore
                            user?.salutation?.translated?.displayName
                        }}&nbsp;</span
                    >
                    <span>{{ user?.firstName }}&nbsp;{{ user?.lastName }}</span>
                    <br />
                    <span class="mb-6 xl:mb-8">{{ user?.email }}</span>
                </div>

                <NuxtLink
                    class="btn btn-blue mt-6 xl:self-start xl:mt-auto"
                    :to="marketSetupAdmin?.myBritaProfilePage ?? ''"
                >
                    <CommonLabel path="BWF/shop/account/overview/editProfile"></CommonLabel>
                </NuxtLink>
            </section>

            <section class="flex flex-col border border-grey rounded-lg px-4 py-6 xl:px-6">
                <h3 class="pb-4 font-medium text-lg">
                    <CommonLabel path="BWF/shop/account/overview/myAddress"></CommonLabel>
                </h3>

                <AccountAddressCard
                    v-if="userDefaultBillingAddress?.id && !isDummyUser(user)"
                    :address="userDefaultBillingAddress"
                    :show-title="false"
                    :can-edit="false"
                    class="mb-6 xl:mb-8"
                />
                <p v-else class="mb-6 xl:mb-8">
                    <CommonLabel path="BWF/shop/account/overview/noAddressAvailable"> </CommonLabel>
                </p>

                <NuxtLink class="btn btn-blue mt-auto xl:self-start" :to="marketSetupAdmin?.myBritaAddressPage ?? ''">
                    <CommonLabel v-if="userDefaultBillingAddress?.id" path="BWF/shop/account/overview/changeAddress">
                    </CommonLabel>
                    <CommonLabel v-else path="BWF/shop/account/overview/createAddress"></CommonLabel>
                </NuxtLink>
            </section>

            <section class="flex flex-col border border-grey rounded-lg px-4 py-6 xl:px-6">
                <h3 class="pb-4 font-medium text-lg">
                    <CommonLabel path="BWF/shop/account/overview/orders"></CommonLabel>
                </h3>

                <p class="mb-6 xl:mb-8">
                    <CommonLabel path="BWF/shop/account/overview/ordersExplanationTextLabel"></CommonLabel>
                </p>

                <NuxtLink class="btn btn-blue mt-auto xl:self-start" :to="marketSetupAdmin?.myBritaOrderPage ?? ''">
                    <CommonLabel path="BWF/shop/account/overview/navigateToOrderHistory"> </CommonLabel>
                </NuxtLink>
            </section>

            <section class="flex flex-col border border-grey rounded-lg px-4 py-6 xl:px-6">
                <h3 class="pb-4 font-medium text-lg">
                    <CommonLabel path="BWF/shop/account/overview/abonnementsLabel"></CommonLabel>
                </h3>
                <p class="mb-6 xl:mb-8">
                    <CommonLabel path="BWF/shop/account/overview/abonnementsExplanationTextLabel"></CommonLabel>
                </p>
                <NuxtLink
                    class="btn btn-blue mt-auto xl:self-start"
                    :to="marketSetupAdmin?.myBritaSubscriptionPage ?? ''"
                >
                    <CommonLabel path="BWF/shop/account/overview/abonnements"></CommonLabel>
                </NuxtLink>
            </section>

            <EditableArea
                :content="accountOverviewNewsletter"
                class="xl:col-span-full"
                :custom-view="AccountNewsletterRegistration"
            />
        </div>
    </AccountGrid>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';

import AccountGrid from '~/templates/components/account/AccountGrid.vue';
import AccountNewsletterRegistration from '~/templates/components/shop/account/AccountNewsletterRegistration.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { isDummyUser } from '~/utils/helper/shop/user';
const { user, loadSalutation, userDefaultBillingAddress } = useUser();
const { marketSetupAdmin } = useMarketSetup();
defineProps(['accountOverviewNewsletter']);

defineOptions({
    inheritAttrs: false,
});

const hasNoSalutation = computed(() => {
    return (
        //@ts-ignore
        user?.value?.salutation?.salutationKey === 'not_specified' ||
        //@ts-ignore
        user?.value?.salutation?.salutationKey === 'diverse'
    );
});

onBeforeMount(async () => {
    try {
        if (user?.value?.salutationId) {
            await loadSalutation(user.value.salutationId);
        }
    } catch (e) {
        console.log('error ->', e);
    }
});
</script>
