<template>
    <div>
        <EditableArea :content="main" :custom-view="MultipleImageComponentArea" />
    </div>
</template>

<script setup>
import MultipleImageComponentArea from '~/templates/components/cms/MultipleImageTextArea.vue';
import { EditableArea } from '@magnolia/vue-editor';
</script>

<script>
import { editableComponent } from '~/utils/mixins/editableComponent';

export default {
    name: 'MultipleImageTextComponent',
    mixins: [editableComponent],
    provide() {
        // use function syntax so that we can access `this`
        return {
            columns: parseInt(this.columns),
            slider: this.slider,
            mobileSlider: this.mobileSlider,
            ratio: this.ratio,
        };
    },
    props: ['main', 'columns', 'slider', 'mobileSlider', 'ratio'],
};
</script>
