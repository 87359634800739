export default function () {
    const trackDownload = ({ link, ref, label }) => {
        const downloadData = link?.['download_file'] ?? link?.download?.['download_file'] ?? link?.asset;

        try {
            const gtm = useGtm(); // auto-imported by the module
            gtm?.trackEvent({
                event: 'gx.file_download',
                event_name: 'file_download',
                event_source: 'source_code',
                file_download: {
                    file_extension: downloadData?.metadata?.mimeType.split('/').pop() ?? '',
                    file_name: downloadData?.metadata?.fileName ?? '',
                    link_classes: ref?.className ?? '',
                    link_domain:
                        downloadData?.['@link']
                            .replace(/^https?:\/\//, '')
                            .split('/')
                            .shift() ?? '',
                    link_id: ref?.$attrs?.id ?? '',
                    link_text: label ?? '',
                    link_url: downloadData?.['@link'] ?? '',
                },
            });
        } catch (e) {
            console.error('gx.file_download', e);
        }
    };
    return { trackDownload };
}
