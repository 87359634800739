<template>
    <div>
        <p class="font-medium text-base leading-snug">{{ eshopSetConfigurator?.title }}</p>
        <div class="mt-4 grid gap-y-10 xl:mt-6">
            <div v-for="(eshopSetConfiguratorStep, stepIndex) in sortedEshopSetConfiguratorSteps" :key="stepIndex">
                <p class="font-medium text-base leading-snug">
                    {{ eshopSetConfiguratorStep?.title }}
                </p>
                <div
                    class="mt-4 grid grid-cols-1"
                    :class="[
                        transformedViewTitle(eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? '') ===
                        'product-simple'
                            ? 'md:grid-cols-2 gap-2 auto-cols-auto auto-rows-fr xl:gap-6'
                            : 'gap-4',
                    ]"
                >
                    <button
                        v-for="(
                            eshopSetConfiguratorStepProduct, stepProductIndex
                        ) in eshopSetConfiguratorStep?.eshopSetConfiguratorStepProducts"
                        :key="stepProductIndex"
                        class="relative border rounded-md text-left"
                        :class="{
                            'border-dark-blue outline outline-dark-blue outline-1':
                                eshopSetConfiguratorStepProduct?.selected,
                            'opacity-50': !eshopSetConfiguratorStepProduct?.product?.available,
                        }"
                        :disabled="!eshopSetConfiguratorStepProduct?.product?.available"
                        @click="handleChange(stepIndex, eshopSetConfiguratorStepProduct?.id ?? '')"
                    >
                        <ProductSimpleOption
                            v-if="
                                transformedViewTitle(
                                    eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? ''
                                ) === 'product-simple'
                            "
                            :step-product="eshopSetConfiguratorStepProduct"
                        />
                        <ProductFullOption
                            v-else-if="
                                transformedViewTitle(
                                    eshopSetConfiguratorStep?.eshopSetConfiguratorView?.title ?? ''
                                ) === 'product-full'
                            "
                            :step-product="eshopSetConfiguratorStepProduct"
                        />
                        <ProductDescriptionOption v-else :step-product="eshopSetConfiguratorStepProduct" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import ProductSimpleOption from './ProductSimpleOption.vue';
import ProductFullOption from '~/templates/components/shop/product/EShopSets/ProductFullOption.vue';
import ProductDescriptionOption from '~/templates/components/shop/product/EShopSets/ProductDescriptionOption.vue';

const { eshopSetConfigurator, eshopSetConfiguratorSteps, updateEShopSet, handleChange, hasEShopSet, eShopSet } =
    await useProductEShopSetConfigurator();
if (hasEShopSet && !eShopSet.value?.eshopSetConfiguratorId) {
    // seems to be empty -> init load
    await updateEShopSet();
}

const transformedViewTitle = (title: string) => title?.toLowerCase().replaceAll(' ', '-') ?? '';
const sortedEshopSetConfiguratorSteps = computed(
    () => eshopSetConfiguratorSteps.value?.sort((a, b) => a.position - b.position) ?? []
);
</script>
