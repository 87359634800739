<template>
    <div class="flex flex-col">
        <p v-if="hasBulkPrice">
            {{ `${firstBulkUnit?.quantity} ${bulkProductUnitLabel}` }}
        </p>
        <div class="flex flex-row">
            <SharedPrice
                v-if="firstBulkUnit?.totalPrice"
                class="font-medium text-base text-left"
                :class="{
                    'text-signal-red': isBulkListPrice,
                }"
                :value="firstBulkUnit?.totalPrice"
            />
            <SharedPrice
                v-if="isBulkListPrice"
                class="font-normal text-base leading-snug line-through ml-2"
                :value="firstBulkUnit?.totalPriceWithoutDiscount"
            />
        </div>
        <div v-if="hasBulkPrice && isBulkListPrice" class="flex gap-1 font-normal text-xs leading-tight">
            <CommonLabel path="BWF/shop/productDetailPage/priceSaving" />
            <SharedPrice v-if="firstBulkUnit?.totalPriceWithoutDiscount" :value="bulkProductSavings" />
            {{ `(${firstBulkUnit?.displayDiscountRate}%)` }}
        </div>
    </div>
</template>

<script setup lang="ts">
import CommonLabel from '~/templates/components/CommonLabel.vue';

const { product } = useProduct();
const { hasBulkPrice, firstBulkUnit, isBulkListPrice, bulkProductUnitLabel } = useProductBulkPrices(product);

const bulkProductSavings = computed(() => {
    return firstBulkUnit.value?.listPrice?.discount * firstBulkUnit.value?.quantity;
});
</script>
