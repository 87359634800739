/**
 * This implmenentation was heavily inspired by:
 * https://github.com/Akash52/msal-with-nuxt3/tree/main
 *
 * before it was refactored to use composables agin
 */

import {
    BrowserCacheLocation,
    EventMessageUtils,
    EventType,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser';

let tokenExpirationTimer: never;

export default defineNuxtPlugin(async ({ $config }) => {
    const {
        public: {
            baseUrl,
            msalHost,
            msalClientId,
            msalTenant,
            msalPolicyId,
            msalPolicyIdSignIn,
            msalPolicyIdSignUp,
            msalPolicyIdPasswordChange,
            msalPolicyIdEmailChange,
            msalPolicyIdChangepasswordyourcemigration,
        },
    } = $config;

    const knownAuthorities = [`${msalTenant}.b2clogin.com`];
    if (msalHost) {
        knownAuthorities.push(msalHost);
    }
    const msalConfig = {
        auth: {
            clientId: `${msalClientId}`,
            authority: `${getAuthorityUrl(msalTenant, msalPolicyId)}`,
            knownAuthorities,
            redirectUri: `${baseUrl}/r/mybrita-redirect`,
            scopes: [],
            postLogoutRedirectUri: `${baseUrl}/r/mybrita-redirect`,
            navigateToLoginRequestUrl: true,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
        },
        system: {
            tokenRenewalOffsetSeconds: 300,
            loggerOptions: {
                loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message);
                            return;
                        case LogLevel.Info:
                            console.info(message);
                            return;
                        case LogLevel.Verbose:
                            console.debug(message);
                            return;
                        case LogLevel.Warning:
                            console.warn(message);
                            return;
                        default:
                            return;
                    }
                },
                logLevel: LogLevel.Error,
            },
        },
    };

    const msalInstance = new PublicClientApplication(msalConfig);
    const interActionStatus = ref();
    const progressStatus = ref();

    // init tracking
    const { trackLoginBegin, trackRedirect, trackSignUpBegin, trackLogout } = useMsalTracking();
    await msalInstance.initialize();

    // Handle redirect promise after login or redirect
    await msalInstance
        .handleRedirectPromise() // Handles the redirect promise and obtains the response
        .then(handleResponse)
        .catch(err => {
            //throw new Error(err);
            console.error('msal:::', err);
        });

    // Add event callback for login success
    msalInstance.addEventCallback(event => {
        interActionStatus.value = EventMessageUtils.getInteractionStatusFromEvent(event, interActionStatus.value);
        progressStatus.value = event.eventType;
        if (event.eventType === EventType.LOGIN_SUCCESS) {
            setupTokenExpirationTimer();
        }
    });

    // Set up timer for refreshing access token upon expiration
    function setupTokenExpirationTimer() {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            const account = getActiveAccount();
            if (account.idTokenClaims && account.idTokenClaims.exp) {
                const tokenExpirationTime = account.idTokenClaims.exp * 1000;
                const currentTime = Date.now();
                const timeUntilExpiration = tokenExpirationTime - currentTime;

                clearTimeout(tokenExpirationTimer);

                tokenExpirationTimer = setTimeout(() => {
                    refreshAccessToken(account);
                }, timeUntilExpiration);
            }
        }
    }

    function getAuthorityUrl(tenant: string, policyId: string) {
        const authorityHost = msalHost ? `https://${msalHost}` : `https://${tenant}.b2clogin.com`;
        return `${authorityHost}/${tenant}.onmicrosoft.com/${policyId}`;
    }

    // Refresh access token
    async function refreshAccessToken(account: never) {
        try {
            await msalInstance.acquireTokenSilent(getCurrentAccountRequest());
            setupTokenExpirationTimer();
        } catch (err) {
            console.error('msal :: Token refresh error:', err);
            await signOut(account.homeAccountId);
        }
    }

    // Handle the response after login or redirect
    function handleResponse(resp: never) {
        if (resp?.account) {
            setupTokenExpirationTimer();
        }
        trackRedirect(resp);
    }

    const getCurrentAccountRequest = () => {
        const account = getActiveAccount();
        return {
            scopes: [],
            ...(account
                ? {
                      account: account,
                      authority: getAuthorityUrl(
                          msalTenant,
                          (account?.idTokenClaims?.tfp as string) ?? msalPolicyIdSignIn
                      ),
                  }
                : {}),
        };
    };

    // Acquire access token silently
    async function acquireTokenSilent() {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            const first = accounts[0];
            // const signInAccount = accounts.find(account => LOGIN_POLICIES.includes(account?.tenantId?.toLowerCase()));
            // msalInstance.setActiveAccount(signInAccount ?? first);
            msalInstance.setActiveAccount(first);
            try {
                const response = await msalInstance.acquireTokenSilent(getCurrentAccountRequest());
                return response.idToken;
            } catch (err) {
                /**
                 * consider logout if there are still refresh bugs !?
                 * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6585#issuecomment-1819117291
                 * https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6830
                 */
                console.error('Acquire Token error:', err);
                await msalInstance.clearCache();
                return null;
            }
        } else {
            return null;
        }
    }

    // Get all MSAL accounts
    function getAccounts() {
        return msalInstance.getAllAccounts();
    }
    // Get all MSAL accounts
    function getActiveAccount() {
        const active = msalInstance.getActiveAccount();
        if (!active) {
            const all = getAccounts();
            return all?.[0];
        }
        return active;
    }
    // Check if user is authenticated

    // Check if user is authenticated
    function isAuthenticated() {
        return getAccounts().length > 0;
    }

    // Sign in with redirect
    async function signIn() {
        try {
            trackLoginBegin();
            const request = {
                authority: getAuthorityUrl(msalTenant, msalPolicyIdSignIn),
                redirectUri: `${baseUrl}/r/mybrita-redirect`,
                scopes: [],
            };
            await msalInstance.loginRedirect(request);
        } catch (err) {
            console.log('Login error:', err);
        }
    }

    // Sign up with redirect
    async function signUp() {
        try {
            trackSignUpBegin();
            const request = {
                authority: getAuthorityUrl(msalTenant, msalPolicyIdSignUp),
                redirectUri: `${baseUrl}/r/mybrita-redirect`,
                scopes: [],
            };
            await msalInstance.loginRedirect(request);
        } catch (err) {
            console.log('Signup error:', err);
        }
    }

    // Sign up with redirect
    async function signInSignUp() {
        try {
            // treat it as a signin for know. will ask in ticket on how to handle
            trackLoginBegin();
            const request = {
                authority: getAuthorityUrl(msalTenant, msalPolicyId),
                redirectUri: `${baseUrl}/r/mybrita-redirect`,
                scopes: [],
            };
            await msalInstance.loginRedirect(request);
        } catch (err) {
            console.log('Signup error:', err);
        }
    }

    // Sign out user
    async function signOut(homeAccountId?: string) {
        const accountId = homeAccountId ?? getActiveAccount()?.homeAccountId;

        trackLogout();
        const account = accountId ? msalInstance.getAccountByHomeId(accountId) : null;
        if (account) {
            const token = await msalInstance.acquireTokenSilent(getCurrentAccountRequest());
            const request = {
                account,
                idTokenHint: token.idToken,
            };
            await msalInstance.logoutRedirect(request);
            localStorage.clear();
        } else {
            console.error('Account not found');
        }
    }

    // Change Password redirect
    async function passwordChange() {
        try {
            const request = {
                authority: getAuthorityUrl(msalTenant, msalPolicyIdPasswordChange),
                redirectUri: `${baseUrl}/r/mybrita-redirect`,
                scopes: [],
            };
            await msalInstance.loginRedirect(request);
        } catch (err) {
            console.log('Signup error:', err);
        }
    }

    // Change Email redirect
    async function emailChange() {
        try {
            const request = {
                authority: getAuthorityUrl(msalTenant, msalPolicyIdEmailChange),
                redirectUri: `${baseUrl}/r/mybrita-redirect`,
                scopes: [],
            };
            // init redirect
            await msalInstance.loginRedirect(request);
        } catch (err) {
            console.log('Signup error:', err);
        }
    }

    function migrateYourcePassword(redirectStartPage = `${baseUrl}/r/yourceMigrationSuccessPage`, email: string) {
        try {
            const request = {
                authority: getAuthorityUrl(msalTenant, msalPolicyIdChangepasswordyourcemigration),
                extraQueryParameters: { email: encodeURIComponent(email) },
                redirectUri: `${baseUrl}/r/mybrita-redirect`,
                redirectStartPage,
                scopes: [],
            };
            // init redirect
            msalInstance.loginRedirect(request);
        } catch (err) {
            console.log('Signup error:', err);
        }
    }

    function dynamicRequest(policyId?: string) {
        const {
            public: { msalTenant, baseUrl },
        } = useRuntimeConfig();
        const request = {
            authority: getAuthorityUrl(msalTenant, policyId ?? ''),
            redirectUri: `${baseUrl}/r/mybrita-redirect`,
            scopes: [],
        };
        msalInstance.loginRedirect(request);
    }

    const msalObj = {
        interActionStatus,
        progressStatus,
        getAccounts,
        getActiveAccount,
        isAuthenticated,
        getAuthorityUrl,
        acquireTokenSilent,
        signIn,
        signUp,
        signOut,
        signInSignUp,
        passwordChange,
        emailChange,
        dynamicRequest,
        migrateYourcePassword,
    };

    return {
        provide: {
            msal: msalObj,
        },
    };
});
