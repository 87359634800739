import { getNodeByPath } from '~/utils/content/navigation';
import { cleanFullPath } from '~/utils/content/magnolia';

const useMagnoliaRootNode = function () {
    const {
        public: { mgnlSitePath, mgnlAuthor },
    } = useRuntimeConfig();

    if (!!mgnlAuthor === true) {
        // guess node from url
        const contentPath = cleanFullPath();
        const navigationNode = getNodeByPath(contentPath);

        if (navigationNode && navigationNode !== 'r') {
            // if defined and NOT redirect page (r convention)
            return `/${navigationNode ?? 'DE'}`;
        }

        const authorRootNodeCookie = useCookie('authorRootNode');
        // cookie fallback / guess context and language from last market node for redirect pages on author
        return `/${authorRootNodeCookie.value ?? 'DE'}`;
    }
    return mgnlSitePath;
};

export default useMagnoliaRootNode;
