<template>
    <label class="relative flex gap-4 items-center text-base">
        <SvgIcon
            name="Search"
            class="h-5 w-5 text-medium-grey absolute left-2 xl:left-4 top-1/2 -translate-y-1/2"
        ></SvgIcon>
        <input
            type="text"
            :placeholder="attrs.placeholder"
            :name="attrs.name"
            :autocomplete="attrs.autocomplete"
            :readonly="attrs.readonly"
            :required="attrs.required"
            :disabled="attrs.disabled"
            :pattern="attrs.pattern"
            :value="modelValue"
            class="flex w-full appearance-none bg-light-grey rounded-full pl-10 xl:pl-12 pr-8 py-2 xl:py-3"
            :class="{ 'has-label': !!label, 'has-error': hasError }"
            @input="$emit('update:modelValue', $event.target.value)"
            @blur="$emit('blur', $event)"
            @change="$emit('change', $event)"
        />
        <button
            v-if="modelValue?.length > 0"
            aria-label="clear"
            class="absolute right-4 top-1/2 -translate-y-1/2"
            @click="onClear()"
        >
            <SvgIcon name="icon-menu-close" class="h-4 w-4 text-medium-grey"></SvgIcon>
        </button>
    </label>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';

const props = defineProps(['modelValue', 'label', 'errorMessage']);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);
const emits = defineEmits(['update:modelValue', 'blur', 'change']);

const onClear = () => {
    emits('update:modelValue', '');
};
</script>
