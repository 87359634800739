<template>
    <div>
        <HeadlineComponent
            v-if="headline.text"
            class="text-2xl xl:text-3xl text-dark-blue mb-8 xl:mb-14"
            :headline="headline"
        ></HeadlineComponent>

        <div class="flex flex-col gap-4 items-start">
            <DownloadLinkComponent
                v-for="download in mapNodes(downloads)"
                :key="download['@id']"
                :download="download?.download"
            ></DownloadLinkComponent>
        </div>
    </div>
</template>

<script setup>
import HeadlineComponent from './HeadlineComponent';
import DownloadLinkComponent from './DownloadLinkComponent';
import { mapNodes } from '~/utils/helper/magnolia';
</script>
<script>
export default {
    name: 'DownloadListComponent',
    props: ['headline', 'downloads'],
};
</script>
