<template>
    <div class="contents">
        <div class="flex gap-2 items-center text-2xl lg:text-3xl">
            <SharedPrice :value="subscriptionTotalPrice" class="font-medium text-signal-red" />
            <SharedPrice
                v-if="subscriptionListPrice"
                :value="subscriptionListPrice"
                class="font-normal text-medium-grey line-through"
            />
            <div class="badge is-highlight-green ml-6 shrink-0 lg:ml-auto lg:mr-12 lg:is-large">
                <CommonLabel path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionPriceBadgeLabel" />
            </div>
        </div>

        <div class="mt-1 font-normal text-xxxs leading-tight text-medium-grey lg:text-sm">
            <CommonLabel path="BWF/shop/productDetailPage/plus"></CommonLabel>
            <span>&nbsp;</span>
            <CommonLabel path="BWF/shop/checkout/cart/taxes" />
        </div>
    </div>
</template>

<script setup lang="ts">
import CommonLabel from '~/templates/components/CommonLabel.vue';

import { useProductSubscriptionConfigurator } from '~/composables/shop/useProductSubscriptionConfigurator';

defineProps(['showOptionName', 'optionButtonDefault']);
const { product } = useProduct();

const { subscriptionTotalPrice, subscriptionListPrice } = useProductSubscriptionConfigurator(product);
</script>
