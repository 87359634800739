import { getLineItemsWithoutPromotion } from '~/utils/helper/shop/cart';
import { subtract } from '~/utils/helper/subtract';

const THIRTY_MINUTES_MS = 30 * 60 * 1000; // 30 minutes in milliseconds
export default function () {
    // provide tracking information and items array
    const { currency, selectedShippingMethod, selectedPaymentMethod } = useSessionContext();
    const gtm = useGtm(); // auto-imported by the module
    const { getOrderItemFromStorage, getItemFromStorage } = useItemsArray();
    const { cartItems, cart, appliedPromotionCodes } = useCart();
    const calculateDeposit = lineItems => {
        return lineItems?.reduce((sum, item) => {
            return sum + item.children[1]?.payload?.tauschboxDeposit
                ? item.children[1]?.price?.totalPrice ?? item.children[1]?.totalPrice ?? 0
                : 0;
        }, 0);
    };

    const { user, isGuestSession } = useUser();
    const { paymentMethods } = useCheckout();
    const getPaymentMethodName = paymentMethodId => {
        const selectedPaymentMethod = paymentMethods.value.find(method => method.id === paymentMethodId);
        return selectedPaymentMethod?.shortName;
    };

    const cartItemsArray = computed(() => {
        const items = getLineItemsWithoutPromotion(cartItems.value);
        // overwrite index from storage with cart index
        return items.map((item, index) => ({
            ...getItemFromStorage(item),
            quantity: item.quantity,
            index,
        }));
    });
    const trackItemRemoveFromCart = item => {
        try {
            const removedItem = cartItemsArray.value?.find(cartItem => cartItem?.['item_id'] === item.value.id);
            const lineItemWithDeposit = item.value?.children.find(
                lineItem => lineItem?.extensions?.tauschbox?.totalDepositPrice
            );
            const removedTauschboxItemDeposit = lineItemWithDeposit?.extensions?.tauschbox?.totalDepositPrice;
            const removedTauschboxItemPrice = lineItemWithDeposit?.extensions?.tauschbox?.tauschboxPrice;
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.remove_from_cart',
                event_name: 'remove_from_cart',
                event_source: 'source_code',
                ecommerce: {
                    ...(removedTauschboxItemDeposit ? { deposit: removedTauschboxItemDeposit } : {}),
                    currency: currency.value?.isoCode,
                    value: removedTauschboxItemPrice ? removedTauschboxItemPrice : item.value?.price?.totalPrice,
                    items: [removedItem],
                },
            });
        } catch (e) {
            console.error('eec.remove_from_cart', e);
        }
    };

    const trackCartView = () => {
        try {
            const deposit = calculateDeposit(cartItems.value);
            gtm?.trackEvent({ ecommerce: null });
            gtm?.trackEvent({
                event: 'eec.view_cart',
                event_name: 'view_cart',
                event_source: 'source_code',
                ecommerce: {
                    ...(deposit !== 0 ? { deposit } : {}),
                    currency: currency.value.isoCode,
                    value: subtract(cart.value.price.totalPrice, deposit),
                    coupon: appliedPromotionCodes.value?.[0]?.description,
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.view_cart', e);
        }
    };
    const trackBeginCheckout = () => {
        try {
            const deposit = calculateDeposit(cartItems.value);
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.begin_checkout',
                event_name: 'begin_checkout',
                event_source: 'source_code',
                ecommerce: {
                    ...(deposit !== 0 ? { deposit } : {}),
                    currency: currency.value.isoCode,
                    value: subtract(cart.value.price.totalPrice, deposit),
                    coupon: appliedPromotionCodes.value.map(code => code.label).join(','),
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.begin_checkout', e);
        }
    };

    const trackShippingInfo = () => {
        try {
            const deposit = calculateDeposit(cartItems.value);
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.add_shipping_info',
                event_name: 'add_shipping_info',
                event_source: 'source_code',
                ecommerce: {
                    ...(deposit !== 0 ? { deposit } : {}),
                    currency: currency.value.isoCode,
                    value: subtract(cart.value.price.totalPrice, deposit),
                    coupon: appliedPromotionCodes.value.map(code => code.label).join(','),
                    shipping_tier: selectedShippingMethod.value?.name,
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.add_shipping_info', e);
        }
    };

    const trackPaymentInfo = () => {
        try {
            const deposit = calculateDeposit(cartItems.value);
            gtm?.trackEvent({ ecommerce: null });

            gtm?.trackEvent({
                event: 'eec.add_payment_info',
                event_name: 'add_payment_info',
                event_source: 'source_code',
                ecommerce: {
                    ...(deposit !== 0 ? { deposit } : {}),
                    currency: currency.value.isoCode,
                    value: subtract(cart.value.price.totalPrice, deposit),
                    coupon: appliedPromotionCodes.value.map(code => code.label).join(','),
                    payment_type: selectedPaymentMethod.value?.name,
                    items: toRaw(cartItemsArray.value),
                },
            });
        } catch (e) {
            console.error('eec.add_payment_info', e);
        }
    };
    const trackPurchase = order => {
        try {
            gtm?.trackEvent({ ecommerce: null });

            const userCreationDate = new Date(user?.value?.createdAt);
            const isNewCustomer = Date.now() - userCreationDate <= THIRTY_MINUTES_MS;
            const coupon = order?.customFields?.promotions?.map(promo => promo.code).join(',') ?? '';
            const items = order?.lineItems.map((item, index) => ({
                ...getOrderItemFromStorage(item),
                quantity: item.quantity,
                index,
            }));
            const deposit = calculateDeposit(order?.lineItems);
            const checkout_type = isGuestSession.value
                ? 'as_guest'
                : isNewCustomer
                  ? 'as_new_customer'
                  : 'as_existing_customer';
            gtm?.trackEvent({
                event: 'eec.purchase',
                event_name: 'purchase',
                event_source: 'source_code',
                checkout_type,
                ecommerce: {
                    ...(deposit ? { deposit } : {}),
                    transaction_id: order?.id,
                    currency: order?.currency.isoCode,
                    value: subtract(order?.amountTotal, deposit),
                    tax: order?.amountTotal - order?.amountNet,
                    shipping: order?.shippingTotal,
                    coupon: coupon,
                    items,
                },
            });
        } catch (e) {
            console.error('eec.purchase', e);
        }
    };
    const trackGuestCheckout = () => {
        try {
            gtm?.trackEvent({
                event: 'gx.guest_checkout',
                event_name: 'guest_checkout',
                event_source: 'source_code',
                guest_checkout: {
                    name: 'guest_checkout',
                },
            });
        } catch (e) {}
    };
    const trackPersonalInfo = () => {
        try {
            gtm?.trackEvent({
                event: 'gx.personal_info',
                event_name: 'personal_info',
                event_source: 'source_code',
                order_summary: {
                    name: 'personal_info',
                },
            });
        } catch (e) {}
    };
    const trackOrderSummary = () => {
        try {
            gtm?.trackEvent({
                event: 'gx.order_summary',
                event_name: 'order_summary',
                event_source: 'source_code',
                order_summary: {
                    name: 'order_summary',
                },
            });
        } catch (e) {}
    };
    const trackPlaceOrder = paymentMethodId => {
        const paymentMethod = getPaymentMethodName(paymentMethodId);
        try {
            gtm?.trackEvent({
                event: 'gx.place_order',
                event_name: 'place_order',
                event_source: 'source_code',
                place_order: {
                    payment_method: paymentMethod,
                    name: 'place_order',
                },
            });
        } catch (e) {}
    };
    const trackPaymentError = paymentMethodObject => {
        const paymentMethod = paymentMethodObject?.value.shortName;
        try {
            gtm?.trackEvent({
                event: 'gx.payment_error',
                event_name: 'payment_error',
                event_source: 'source_code',
                payment_error: {
                    payment_method: paymentMethod,
                    name: 'payment_error',
                },
            });
        } catch (e) {}
    };

    return {
        trackCartView,
        trackBeginCheckout,
        trackShippingInfo,
        trackPaymentInfo,
        trackPurchase,
        trackItemRemoveFromCart,
        trackGuestCheckout,
        trackPersonalInfo,
        trackOrderSummary,
        trackPlaceOrder,
        trackPaymentError,
    };
}
