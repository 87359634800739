import { useVuelidate } from '@vuelidate/core';

export default async function () {
    const {
        firstNameValidators,
        lastNameValidators,
        emailValidators,
        streetNumberValidators,
        addressAdditionalValidators,
        cityValidators,
        zipCodeValidators,
        requiredValidator,
    } = await useValidation();

    const checkoutFormState = reactive({
        salutationId: '',
        firstName: '',
        lastName: '',
        email: '',
        billingAddress: {
            street: '',
            zipcode: '',
            city: '',
            countryId: '',
            additionalAddressLine1: '',
        },
        guest: true,
    });

    const shippingFormState = reactive({
        salutationId: '',
        firstName: '',
        lastName: '',
        street: '',
        zipcode: '',
        city: '',
        countryId: '',
        additionalAddressLine1: '',
    });

    const rules = computed(() => ({
        firstName: {
            ...requiredValidator,
            ...firstNameValidators,
        },
        lastName: {
            ...requiredValidator,
            ...lastNameValidators,
        },
        email: {
            ...requiredValidator,
            ...emailValidators,
        },
        billingAddress: {
            street: {
                ...requiredValidator,
                ...streetNumberValidators,
            },
            zipcode: {
                ...requiredValidator,
                ...zipCodeValidators,
            },
            additionalAddressLine1: {
                ...addressAdditionalValidators,
            },
            city: {
                ...requiredValidator,
                ...cityValidators,
            },
            countryId: {
                ...requiredValidator,
            },
        },
    }));

    const shippingRules = computed(() => ({
        firstName: {
            ...requiredValidator,
            ...firstNameValidators,
        },
        lastName: {
            ...requiredValidator,
            ...lastNameValidators,
        },

        street: {
            ...requiredValidator,
            ...streetNumberValidators,
        },
        zipcode: {
            ...requiredValidator,
            ...zipCodeValidators,
        },
        additionalAddressLine1: {
            ...addressAdditionalValidators,
        },
        city: {
            ...requiredValidator,
            ...cityValidators,
        },
        countryId: {
            ...requiredValidator,
        },
    }));

    const checkoutForm$ = useVuelidate(rules, checkoutFormState);
    const shippingForm$ = useVuelidate(shippingRules, shippingFormState);

    return {
        checkoutFormState,
        shippingFormState,
        checkoutForm$,
        shippingForm$,
    };
}
