<template>
    <picture v-if="containsImage(imageNode)">
        <template v-if="hasBreakpoints && isResizerImage(img) && isResizableImage(imageNode)">
            <template v-for="(value, key) in SORTED_BREAKPOINTS" :key="key">
                <template v-if="breakpoints && breakpoints[key]">
                    <source
                        :media="`(min-width: ${value}px)`"
                        :srcset="imageResizerPath(img, breakpoints[key].width, breakpoints[key].height, true)"
                        type="image/webp"
                    />
                    <source
                        :media="`(min-width: ${value}px)`"
                        :srcset="imageResizerPath(img, breakpoints[key].width, breakpoints[key].height)"
                        :type="imageResizerType(img)"
                    />
                </template>
            </template>
        </template>

        <img
            :loading="loading ?? 'lazy'"
            :class="imgClass"
            :style="{ imageRendering }"
            :src="src"
            :alt="getImageAlt(imageNode)"
            :title="getImageTitle(imageNode)"
            :width="getImageWidth(img) ?? 400"
            :height="getImageHeight(img) ?? 300"
        />
    </picture>
</template>

<script setup>
import { BREAKPOINTS } from '~/utils/helper/imaging';
import { getImageTitle, getImageAlt, getImageWidth, getImageHeight } from '~/utils/helper/magnolia';
const SORTED_BREAKPOINTS = Object.fromEntries(Object.entries(BREAKPOINTS).sort(([, a], [, b]) => b - a));
</script>

<script>
import {
    imagePath,
    imageResizerPath,
    imageResizerType,
    isResizerImage,
    isResizableImage,
    containsImage,
} from '~/utils/helper/magnolia';

export default {
    name: 'PictureElement',
    props: ['img', 'imgSrc', 'breakpoints', 'imgClass', 'loading', 'imageRendering'],
    computed: {
        hasBreakpoints() {
            return this.breakpoints && Object.keys(this.breakpoints).length > 0;
        },
        src() {
            return (
                this.imgSrc ||
                (isResizerImage(this.imageNode) && imageResizerPath(this.img, BREAKPOINTS.sm, 0)) ||
                imagePath(this.imageNode) ||
                `data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`
            );
        },
        imageNode() {
            return this.img?.['damID'] ?? this.img;
        },
    },
    methods: {
        imageResizerPath,
        imageResizerType,
        isResizerImage,
        isResizableImage,
        containsImage,
    },
};
</script>
