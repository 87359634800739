<template>
    <label class="relative flex lg:focus-within:custom-focus-outline text-base">
        <select
            v-if="options?.length > 0"
            :id="`select-${attrs.name}`"
            class="absolute opacity-0 inset-0"
            :name="attrs.name"
            :required="attrs.required"
            :disabled="disabled"
            :autocomplete="attrs.autocomplete"
            :value="modelValue"
            @change="onInput($event.target.value)"
        >
            <option ref="firstOptionRef" disabled selected :label="label ?? ' '" value="">{{ label ?? '' }}</option>

            <option
                v-for="{ value, label: optionLabel, disabled: optionDisabled } in options"
                ref="optionRefs"
                :key="value"
                :value="value"
                :disabled="optionDisabled"
                :selected="value === modelValue"
                :label="optionLabel ?? value"
            >
                {{ optionLabel ?? value ?? '' }}
            </option>
        </select>

        <span class="flex gap-1 items-center text-link no-underline pointer-events-none">
            <slot>{{ label }}</slot>
            <SvgIcon name="Arrow-Up" class="h-4 w-4 -rotate-180"></SvgIcon>
        </span>
    </label>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';
defineProps(['modelValue', 'options', 'label', 'errorMessage', 'disabled']);
const attrs = useAttrs();
const emit = defineEmits(['update:modelValue']);

const onInput = value => {
    emit('update:modelValue', value);
};

const firstOptionRef = ref(null);
const optionRefs = ref([]);
const reset = () => {
    optionRefs.value.forEach(option => {
        option.selected = false;
    });
    firstOptionRef.value.selected = true;
    emit('update:modelValue', '');
};
defineExpose({ reset });
</script>

<style scoped>
select {
    @apply appearance-none;
}
[readonly],
[disabled] {
    @apply cursor-not-allowed grayscale opacity-60;
}
</style>
