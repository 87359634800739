<template>
    <SliderElement
        v-if="slider || (isMobile && mobileSlider)"
        class="product-tiles-slider"
        :components="mapNodes(content)"
        :swiper-options="swiperOptions"
    ></SliderElement>
    <div v-else class="grid grid-cols-2 xl:grid-cols-4 responsive-gap-x gap-y-6 xl:gap-y-8">
        <EditableComponent
            v-for="childContent in mapNodes(content)"
            :key="childContent['@id']"
            :content="childContent"
        />
    </div>
</template>

<script setup>
import { EditableComponent } from '@magnolia/vue-editor';
import SliderElement from '~/templates/elements/SliderElement.vue';
import { mapNodes } from '~/utils/helper/magnolia';
import { inject } from 'vue';
import { screens } from '~/tailwind.config.screens';
const { isMobile } = useDevice();
defineProps(['content']);
const slider = inject('slider');
const mobileSlider = inject('mobileSlider');

const swiperOptions = {
    slidesPerView: 1.2,
    spaceBetween: 8,
    centeredSlides: false,
    loop: false,
    breakpoints: {
        [parseInt(screens.md)]: {
            slidesPerView: 2,
            spaceBetween: 16,
        },
        [parseInt(screens.xl)]: {
            slidesPerView: 4,
            spaceBetween: 24,
        },
    },
};
</script>
