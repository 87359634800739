<template>
    <div>
        <EditableArea v-if="success" :content="success" :custom-view="FeedbackArea" />

        <EditableArea v-if="main" :content="main"></EditableArea>

        <EditableArea
            v-if="(showNewsletterRegistration && newsletterArea) || isEditMode()"
            :content="newsletterArea"
            :custom-view="EmptyArea"
            class="container xl:px-20"
        >
            <NewsletterComponentArea :skip-doi="true" :email="user?.email" :content="newsletterArea" />
        </EditableArea>

        <EditableArea v-if="bottom" :content="bottom" />
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import { isEditMode } from '~/utils/content/magnolia';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
import useBritaMiddleware from '~/composables/useBritaMiddleware';
import NewsletterComponentArea from '~/templates/areas/NewsletterComponentArea.vue';
import FeedbackArea from '~/templates/areas/FeedbackArea.vue';
const { getDoiState } = useBritaMiddleware();
const { verifyLogIn } = useMyBritaUser();

const { user } = useUser();
defineOptions({
    inheritAttrs: false,
});
defineProps(['main', 'bottom', 'newsletterArea', 'success']);

const showNewsletterRegistration = ref(false);

onMounted(async () => {
    try {
        await verifyLogIn();
        const data = await getDoiState(user?.value?.email);
        showNewsletterRegistration.value = data?.donotbulkemail === true;
    } catch (e) {
        console.error('yource-success:doistate', e);
    }
});
</script>
