<template>
    <Teleport to="body">
        <Transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200 transform"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div v-show="isOpen" class="fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-50">
                <div
                    class="px-4 py-16 flex items-center justify-center min-h-screen lg:-mt-[3%] text-center xl:py-28 xl:px-0"
                    @mousedown.self="close()"
                >
                    <Transition
                        enter-active-class="transition ease-out duration-500 transform "
                        enter-from-class="opacity-0 translate-y-10 scale-95"
                        enter-to-class="opacity-100 translate-y-0 scale-100"
                        leave-active-class="ease-in duration-200"
                        leave-from-class="opacity-100 translate-y-0 scale-100"
                        leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95"
                    >
                        <div
                            v-if="isOpen"
                            id="modal-content"
                            ref="modalContentElement"
                            class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl px-3 pt-14 pb-8 w-full xl:w-1/2 xl:px-12"
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby="modal-headline"
                            @click.stop
                        >
                            <div class="absolute top-5 right-5">
                                <button @click="close()">
                                    <SvgIcon class="h-4 w-4" name="icon-menu-close"></SvgIcon>
                                </button>
                            </div>
                            <slot></slot>
                        </div>
                    </Transition>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>

<script setup lang="ts">
import { useModal } from '~/composables/useModal';
import SvgIcon from '~/templates/elements/SvgIcon.vue';

const props = defineProps<{
    controller: ReturnType<typeof useModal>;
}>();

const { controller } = toRefs(props);
const { isOpen, close } = controller.value;

const { escape } = useMagicKeys();

watch(escape, () => {
    isOpen.value && close();
});
</script>
