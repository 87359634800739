<template>
    <div v-if="!promoLineItems.length">
        <CommonLabel class="block mb-2 font-medium xl:mb-4" path="BWF/shop/checkout/promo-code/headline"></CommonLabel>
        <div class="flex flex-row gap-4 items-center">
            <InputField
                v-model="promoCode"
                class="form-field-grey xl:form-field-white w-full"
                type="text"
                name="promoCode"
                :placeholder="inputPlaceholder"
                :label="inputLabel"
                :error-message="promotionErrorMsg"
                @keydown.enter="addAndValidatePromoCode()"
                @focusin="trackActivateCouponField()"
            ></InputField>
            <button class="text-link text-black h-10" @click="addAndValidatePromoCode()">
                <CommonLabel path="BWF/shop/checkout/promo-code/add"></CommonLabel>
            </button>
        </div>
    </div>
    <div v-else class="bg-white rounded-lg p-4 flex content-start">
        <SvgIcon class="w-5 h-5 shrink-0 mr-4" name="Tooltip"></SvgIcon>
        <CommonLabel path="BWF/shop/checkout/promo-code/existingCode"></CommonLabel>
    </div>
</template>

<script setup lang="ts">
import InputField from '~/templates/elements/form/InputField.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import { getPromotiontLineItems } from '~/utils/helper/shop/cart';

const { getLabelByPath } = useCommonLabels();
const inputPlaceholder = getLabelByPath('BWF/shop/checkout/promo-code/inputPlaceholder');
const inputLabel = getLabelByPath('BWF/shop/checkout/promo-code/inputLabel');
const { trackAddCouponEvent, trackAddErrorEvent, trackActivateCouponField } = useCouponTracking();

const { getTranslatedCartErrorMessage } = useShopwareNotifications();
const { addPromotionCode, cart } = useCart();

const promoLineItems = computed(() => getPromotiontLineItems(cart.value?.lineItems ?? []));

const promotionErrorMsg = computed(() => {
    const [, foundError] =
        Object.entries(cart?.value?.errors ?? {}).find(([messageKey]) => messageKey.includes('promotion-not')) || [];
    return foundError ? getTranslatedCartErrorMessage(foundError) : '';
});

const addAndValidatePromoCode = async () => {
    await addPromotionCode(promoCode.value);

    if (promotionErrorMsg.value && !promoLineItems.value.length) {
        trackAddErrorEvent(promotionErrorMsg.value);
    } else {
        trackAddCouponEvent(promoCode.value);
        promoCode.value = '';
    }
};

const promoCode = ref<string>('');
</script>
