<template>
    <div class="benefit-tile w-full relative">
        <div
            class="pt-12 px-6 pb-8 h-full space-y-8 rounded-2xl text-center xl:pt-16 xl:px-8 xl:pb-12 xl:space-y-12"
            :class="backgroundColor ? contentToTailwindClasses({ backgroundColor }) : 'bg-transparent'"
        >
            <PictureElement :img="image" img-class="mx-auto h-16 xl:h-24 object-contain"> </PictureElement>

            <div class="space-y-4">
                <LinkComponent :link="link" :stretch-link="true">
                    <h3 class="text-lg font-medium xl:text-2xl">
                        {{ headline?.text }}
                    </h3>
                </LinkComponent>
                <p class="text-base font-normal">{{ subline }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import PictureElement from '~/templates/elements/PictureElement.vue';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';
defineProps(['image', 'headline', 'subline', 'link']);
const backgroundColor = inject('backgroundColor');
</script>
