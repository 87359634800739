export const NAVIGATION_HEADER = 'Header navigation';
export const NAVIGATION_FOOTER = 'Footer navigation';
export const NAVIGATION_SLIDER_CATEGORY = 'CategorySliderComponent';
export const NAVIGATION_SLIDER_STORY = 'StorySliderComponent';

export default function (trackingContext) {
    const context = useTrackingContext(trackingContext);

    const trackNavigation = (navigation_element = '', navigation_bar = context.value) => {
        const valid = [NAVIGATION_HEADER, NAVIGATION_FOOTER];

        try {
            if (valid.includes(navigation_bar)) {
                const gtm = useGtm(); // auto-imported by the module
                gtm?.trackEvent({
                    event: 'gx.navigation',
                    event_name: 'navigation',
                    event_source: 'source_code',
                    navigation: {
                        navigation_bar,
                        navigation_element,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const trackSwipeContent = () => {
        const valid = [NAVIGATION_SLIDER_CATEGORY, NAVIGATION_SLIDER_STORY];

        try {
            if (valid.includes(context.value)) {
                const gtm = useGtm(); // auto-imported by the module
                gtm?.trackEvent({
                    event: 'gx.swipe_content',
                    event_name: 'swipe_content',
                    event_source: 'source_code',
                    swipe_content: {
                        content_type: context.value,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const trackClickLogo = () => {
        try {
            const gtm = useGtm(); // auto-imported by the module
            gtm?.trackEvent({
                event: 'gx.click_logo',
                event_name: 'click_logo',
                event_source: 'source_code',
                click_logo: {},
            });
        } catch (e) {
            console.error(e);
        }
    };

    const trackTechnicalSpecs = (description, name) => {
        try {
            const gtm = useGtm(); // auto-imported by the module
            gtm?.trackEvent({
                event: 'gx.product_accordions',
                event_name: 'product_accordions',
                event_source: 'source_code',
                product_accordions: {
                    description: description,
                    name: name,
                },
            });
        } catch (e) {
            console.error(e);
        }
    };

    const trackProductPageSlider = productImage => {
        try {
            const gtm = useGtm(); // auto-imported by the module
            gtm?.trackEvent({
                event: 'gx.product_image',
                event_name: 'product_image',
                event_source: 'source_code',
                product_image: {
                    image_file: productImage.videoId ?? productImage.media.fileName,
                    image_position: productImage.position.toString(),
                    image_type: productImage.videoId ? 'video' : 'image',
                },
            });
        } catch (e) {
            console.error(e);
        }
    };

    return { trackNavigation, trackSwipeContent, trackClickLogo, trackTechnicalSpecs, trackProductPageSlider };
}
