import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import { getSortedPdpMedia } from '~/utils/helper/shop/media';
import { imageResizerPath } from '~/utils/helper/magnolia';

export default function () {
    const productSchema = (product, configurator) => {
        if (!product) {
            return null;
        }
        let productColorOption;

        // check if product has color
        const colorGroup = configurator?.find(optionGroup => optionGroup.displayType === 'color');
        // find selected color
        productColorOption = colorGroup?.options?.find(currentOption =>
            product?.optionIds?.includes(currentOption?.id)
        );
        /* This description could contain HTML code, so we filter it out using the RegEx at the end*/
        const polishedDescription = getTranslatedProperty(product?.translated, 'description').replace(
            /<[^>]+>|\n/gi,
            ''
        );

        return {
            '@context': 'https://schema.org',
            '@type': 'Product',
            brand: product?.manufacturer?.name,
            ...(product.media
                ? {
                      image: getSortedPdpMedia(product).map(image => {
                          return image.media.url;
                      }),
                  }
                : { image: product?.cover?.media?.url }),
            name: getTranslatedProperty(product?.translated, 'name'),
            description: polishedDescription,
            url: product?.url,
            ...(product?.productNumber ? { sku: product?.productNumber } : {}),
            ...(product?.ean ? { gtin13: product?.ean } : {}),
            ...(product.seoCategory
                ? {
                      category: {
                          '@type': 'CategoryCode',
                          name: product?.seoCategory.name,
                          identifier: product?.seoCategory.id,
                      },
                  }
                : {}),
            ...(productColorOption ? { color: productColorOption.name } : {}),
            offers: {
                '@type': 'Offer',
                itemOffered: 'product',
                businessFunction: 'http://purl.org/goodrelations/v1#Sell',
                price: product?.calculatedPrice?.unitPrice,
                priceCurrency: 'EUR',
                availability: product?.available ? 'InStock' : 'OutOfStock',
                shippingDetails: {
                    '@type': 'OfferShippingDetails',
                    ...(product.height ? { height: product?.height } : {}),
                    ...(product.width ? { width: product?.width } : {}),
                    ...(product.weight ? { weight: product?.weight } : {}),
                },
            },
        };
    };

    const articleSchema = (leadTitle, leadText, leadVisual) => {
        if (!leadTitle && !leadText && !leadVisual) {
            return null;
        }
        return {
            '@context': 'https://schema.org',
            '@type': 'NewsArticle',
            headline: leadTitle,
            image: imageResizerPath(leadVisual),
            articleBody: leadText,
        };
    };
    const productListingSchema = productList => {
        if (!productList) {
            return null;
        }
        const products = productList?.map(product => {
            const configurator = product?.extensions?.groups;
            return productSchema(product, configurator);
        });
        return {
            '@context': 'https://schema.org',
            '@type': 'itemList',
            itemListElement: products,
        };
    };

    return { productSchema, articleSchema, productListingSchema };
}
