<template>
    <div class="mollie-credit-card">
        <div id="mollie-credit-card-container"></div>
    </div>
</template>

<script setup lang="ts">
import type { CreateLocaleInstanceArgs } from '~/plugins/mollie.types';
import { useMollie } from '~/composables/shop/useMollie';
import { useMollieCreditCard } from '~/composables/shop/useMollieCreditCard';

const props = defineProps<{
    settings?: CreateLocaleInstanceArgs;
}>();

const { init, getToken, load } = useMollie(props.settings);
const { mount } = useMollieCreditCard({
    elementId: 'mollie-credit-card-container',
});

onMounted(async () => {
    await load();
    await init();
    await mount();
});

defineExpose({ getToken });
</script>

<style>
#mollie-credit-card-container {
    @apply bg-light-grey p-4 rounded-sm;

    label {
        @apply mt-2 block;
    }

    div[role='alert'] {
        @apply text-signal-red text-sm mb-1;
    }
}

.mollie-component {
    @apply border p-2 rounded-sm bg-white;
}
</style>
