<template>
    <svg
        id="eIVLQPxflRa1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1600 900"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
    >
        <g id="eIVLQPxflRa2_ts" transform="translate(620,60) scale(0.2,0.2)">
            <ellipse
                id="eIVLQPxflRa2"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="eIVLQPxflRa3_ts" transform="translate(1400,60) scale(0.2,0.2)">
            <ellipse
                id="eIVLQPxflRa3"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="eIVLQPxflRa4_ts" transform="translate(1400,320) scale(0.2,0.2)">
            <ellipse
                id="eIVLQPxflRa4"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#84d0f5"
                fill-opacity="0.65"
                stroke-width="0"
            />
        </g>
        <g id="eIVLQPxflRa5_ts" transform="translate(1660,320) scale(0.2,0.2)">
            <ellipse
                id="eIVLQPxflRa5"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="eIVLQPxflRa6_ts" transform="translate(1140,840) scale(0.2,0.2)">
            <ellipse
                id="eIVLQPxflRa6"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
    </svg>
</template>
<style scoped>
#eIVLQPxflRa2_ts {
    animation: eIVLQPxflRa2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa2_ts__ts {
    0% {
        transform: translate(620px, 60px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        transform: translate(620px, 60px) scale(1, 1);
    }
    100% {
        transform: translate(620px, 60px) scale(1, 1);
    }
}
#eIVLQPxflRa2 {
    animation: eIVLQPxflRa2_c_o 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa2_c_o {
    0% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eIVLQPxflRa3_ts {
    animation: eIVLQPxflRa3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa3_ts__ts {
    0% {
        transform: translate(1400px, 60px) scale(0.2, 0.2);
    }
    10% {
        transform: translate(1400px, 60px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        transform: translate(1400px, 60px) scale(1, 1);
    }
    100% {
        transform: translate(1400px, 60px) scale(1, 1);
    }
}
#eIVLQPxflRa3 {
    animation: eIVLQPxflRa3_c_o 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa3_c_o {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eIVLQPxflRa4_ts {
    animation: eIVLQPxflRa4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa4_ts__ts {
    0% {
        transform: translate(1400px, 320px) scale(0.2, 0.2);
    }
    20% {
        transform: translate(1400px, 320px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        transform: translate(1400px, 320px) scale(1, 1);
    }
    100% {
        transform: translate(1400px, 320px) scale(1, 1);
    }
}
#eIVLQPxflRa4 {
    animation: eIVLQPxflRa4_c_o 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa4_c_o {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eIVLQPxflRa5_ts {
    animation: eIVLQPxflRa5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa5_ts__ts {
    0% {
        transform: translate(1660px, 320px) scale(0.2, 0.2);
    }
    30% {
        transform: translate(1660px, 320px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        transform: translate(1660px, 320px) scale(1, 1);
    }
    100% {
        transform: translate(1660px, 320px) scale(1, 1);
    }
}
#eIVLQPxflRa5 {
    animation: eIVLQPxflRa5_c_o 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa5_c_o {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#eIVLQPxflRa6_ts {
    animation: eIVLQPxflRa6_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa6_ts__ts {
    0% {
        transform: translate(1140px, 840px) scale(0.2, 0.2);
    }
    40% {
        transform: translate(1140px, 840px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    90% {
        transform: translate(1140px, 840px) scale(1, 1);
    }
    100% {
        transform: translate(1140px, 840px) scale(1, 1);
    }
}
#eIVLQPxflRa6 {
    animation: eIVLQPxflRa6_c_o 2000ms linear 1 normal forwards;
}
@keyframes eIVLQPxflRa6_c_o {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
