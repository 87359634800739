<template>
    <div class="container grid grid-cols-12 gap-4 xl:gap-x-6 justify-between">
        <LoadingElement
            v-if="!isMyBritaLoggedIn && !isEditMode()"
            class="col-span-full w-full aspect-3/4 lg:aspect-video"
        >
        </LoadingElement>
        <template v-else>
            <div class="md:order-2 col-span-full md:col-span-8 xl:col-start-4 mt-12">
                <slot></slot>
            </div>
            <menu
                class="account-navi col-span-full md:col-span-3 rounded-xl flex flex-col order-1 mt-12 xl:mt-12 xl:col-span-2"
            >
                <li class="px-4">
                    <NuxtLink
                        :to="marketSetupAdmin?.myBritaOverviewPage ?? ''"
                        class="account-link text-icon-link text-black"
                    >
                        <SvgIcon name="Overview" class="h-5 w-5"></SvgIcon>
                        <CommonLabel class="truncate" path="BWF/shop/account/grid/overview"></CommonLabel>
                    </NuxtLink>
                </li>

                <li class="px-4">
                    <NuxtLink
                        :to="marketSetupAdmin?.myBritaProfilePage ?? ''"
                        class="account-link text-icon-link text-black"
                    >
                        <SvgIcon name="User" class="h-5 w-5"></SvgIcon>
                        <CommonLabel class="truncate" path="BWF/shop/account/grid/myProfile"></CommonLabel>
                    </NuxtLink>
                </li>

                <li class="px-4">
                    <NuxtLink
                        :to="marketSetupAdmin?.myBritaAddressPage ?? ''"
                        class="account-link text-icon-link text-black"
                    >
                        <SvgIcon name="Address-Book" class="h-5 w-5"></SvgIcon>
                        <CommonLabel path="BWF/shop/account/grid/addressBook"></CommonLabel>
                    </NuxtLink>
                </li>

                <li class="px-4">
                    <NuxtLink
                        :to="marketSetupAdmin?.myBritaOrderPage ?? ''"
                        class="account-link text-icon-link text-black"
                        :class="{
                            'is-active-parent':
                                marketSetupAdmin?.myBritaOrderPage && path.includes(marketSetupAdmin?.myBritaOrderPage),
                        }"
                    >
                        <SvgIcon name="Orders" class="h-5 w-5"></SvgIcon>
                        <CommonLabel class="truncate" path="BWF/shop/account/grid/orderHistory"></CommonLabel>
                    </NuxtLink>
                </li>

                <li class="px-4">
                    <NuxtLink
                        :to="marketSetupAdmin?.myBritaSubscriptionPage ?? ''"
                        class="account-link text-icon-link text-black"
                    >
                        <SvgIcon name="Subscription" class="h-5 w-5 truncate"></SvgIcon>
                        <CommonLabel class="truncate" path="BWF/shop/account/grid/subscriptions"></CommonLabel>
                    </NuxtLink>
                </li>

                <li>
                    <hr class="my-3" />
                </li>

                <li class="px-4">
                    <button class="account-link text-icon-link text-black" @click="onLogout()">
                        <SvgIcon name="Logout" class="h-5 w-5"></SvgIcon>
                        <CommonLabel path="BWF/shop/account/grid/logout"></CommonLabel>
                    </button>
                </li>
            </menu>
        </template>
    </div>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { isEditMode } from '~/utils/content/magnolia';
import LoadingElement from '~/templates/elements/LoadingElement.vue';

const { path } = useRoute();
const { marketSetupAdmin } = useMarketSetup();

const { myBritaLogout, isMyBritaLoggedIn } = useMyBritaUser();

const onLogout = async () => {
    await myBritaLogout();
};
</script>

<style scoped>
.account-link {
    @apply relative py-3 gap-2;

    &.router-link-active,
    &.is-active-parent {
        @apply bg-pale-blue/30 rounded-r-lg;

        &:before {
            @apply absolute h-full bg-pale-blue/30 -left-4 w-4 rounded-l-lg;
            content: '';
        }
    }
}
</style>
