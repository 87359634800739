<template>
    <div>
        <div class="flex items-end gap-3">
            <p class="font-medium flex leading-snug">
                <SharedPrice v-if="activeBulkChild?.totalPrice" :value="activeBulkChild?.totalPrice"></SharedPrice
                >&nbsp;<CommonLabel class="text-xs content-evenly" path="BWF/shop/checkout/plusTax" />
            </p>

            <p class="font-normal leading-snug flex gap-x-4">
                <SharedPrice
                    v-if="activeBulkChild?.totalPriceWithoutDiscount !== activeBulkChild?.totalPrice"
                    class="line-through text-medium-grey items-center"
                    :value="activeBulkChild?.totalPriceWithoutDiscount"
                />
                <DiscountRateLabel
                    v-if="!isMobile && activeBulkChild?.displayDiscountRate"
                    :discount-rate="activeBulkChild?.displayDiscountRate"
                />
            </p>
        </div>
        <div class="mt-1 font-normal text-xxxs leading-tight text-medium-grey lg:text-sm">
            <span class="flex"
                ><SharedPrice :value="activeBulkChild?.unitPrice"></SharedPrice>&nbsp;<CommonLabel
                    path="BWF/shop/shared/singleUnitPrice"
            /></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import DiscountRateLabel from '~/templates/elements/DiscountRateLabel.vue';
import type { ProductBulkPrice } from '~/composables/shop/useProductBulkPrices';
const { isMobile } = useDevice();
defineProps<{
    product: Product;
    activeBulkChild: ProductBulkPrice;
}>();
</script>
