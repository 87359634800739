<template>
    <div class="image-text-area-component flex flex-col gap-4 md:gap-6 relative">
        <div
            class="rounded-xl overflow-hidden safari-overflow-fix relative"
            :class="
                contentToTailwindClasses({
                    aspectRatio,
                })
            "
        >
            <VideoPictureElement
                :img="image"
                class="absolute inset-0 w-full h-full"
                :img-class="`absolute inset-0 w-full h-full
                ${contentToTailwindClasses({ objectFit: fit ?? 'cover' })}`"
                :breakpoints="BREAKPOINTS"
                :video="video"
            ></VideoPictureElement>
        </div>

        <div class="flex flex-col gap-2" :class="{ 'md:gap-4': columns < 6 }">
            <HeadlineComponent
                v-if="headline"
                :headline="headline"
                class="font-medium break-words"
                :class="[columns < 6 ? 'text-lg xl:text-2xl' : 'text-lg']"
            ></HeadlineComponent>

            <div v-if="copy" class="whitespace-pre-wrap text-base leading-snug break-words">
                {{ copy }}
            </div>
        </div>
        <LinkComponent optional-link-classes="stretched-link absolute inset-0" :link="link"></LinkComponent>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import { DIMENSIONS, contentToRatio, columnToWidth } from '~/utils/helper/imaging';
import VideoPictureElement from '~/templates/elements/VideoPictureElement.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';

const columns = inject('columns');
const aspectRatio = inject('ratio');
const imagingRatio = contentToRatio(aspectRatio);
const columnWidth = columnToWidth(columns);

const props = defineProps(['image', 'video', 'fit', 'headline', 'copy', 'link']);

const isContain = props.fit === 'contain';

let BREAKPOINTS = {
    start: DIMENSIONS['start']?.ratio[isContain ? 'original' : imagingRatio],
    sm: DIMENSIONS['sm']?.ratio[isContain ? 'original' : imagingRatio],
    md: DIMENSIONS['md']?.width[columnWidth]?.ratio[isContain ? 'original' : imagingRatio],
    xl: DIMENSIONS['xl']?.width[columnWidth]?.ratio[isContain ? 'original' : imagingRatio],
    container: DIMENSIONS['container']?.width[columnWidth]?.ratio[isContain ? 'original' : imagingRatio],
};
</script>

<script>
import { editableComponent } from '~/utils/mixins/editableComponent';

export default {
    name: 'MultipleImageTextComponent',
    mixins: [editableComponent],
};
</script>
