<template>
    <div>
        <div class="col-span-full text-medium-grey xl:col-span-full xl:col-start-2 xl:row-start-2">
            <CommonLabel path="BWF/shop/checkout/cart/exchangeBoxItem/subscriptionHint"></CommonLabel>
            <br />
            <CommonLabel
                path="BWF/shop/checkout/cart/exchangeBoxItem/articleNumber"
                :placeholder="{ productNumber: filling?.payload?.productNumber }"
            ></CommonLabel>
        </div>
        <!-- FILLING-->
        <div class="xl:col-start-2 xl:row-start-3 mt-3">
            <CommonLabel path="BWF/shop/checkout/cart/exchangeBoxItem/fillingLabel"></CommonLabel>&nbsp;
            <CommonLabel
                path="BWF/shop/checkout/cart/exchangeBoxItem/quantifierLabel"
                :placeholder="{ amountCylinders: filling?.payload?.amountCylinders }"
            ></CommonLabel>
        </div>
        <SharedPrice
            :value="filling?.totalPrice ?? filling?.price?.totalPrice"
            class="justify-self-end mt-3 xl:col-start-4 xl:row-start-3"
            has-asterisk
        />

        <!-- DEPOSIT-->
        <div class="xl:col-start-2 xl:row-start-4 mt-1">
            <CommonLabel path="BWF/shop/checkout/includes"></CommonLabel> {{ deposit?.label }}&nbsp;
            <CommonLabel
                path="BWF/shop/checkout/cart/exchangeBoxItem/quantifierLabel"
                :placeholder="{ amountCylinders: filling?.payload?.amountCylinders }"
            ></CommonLabel>
        </div>
        <SharedPrice
            :value="deposit?.totalPrice ?? deposit?.price?.totalPrice"
            class="justify-self-end mt-1 xl:col-start-4 xl:row-start-4"
            has-asterisk
        />
    </div>
</template>

<script setup lang="ts">
import type { LineItem, OrderLineItem } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import SharedPrice from '~/templates/components/shop/shared/SharedPrice.vue';
const props = defineProps<{
    cartItem: LineItem | OrderLineItem;
}>();

const { cartItem } = toRefs(props);

// @ts-ignore
const filling = computed(() => cartItem?.value?.children?.[0]);
// @ts-ignore
const deposit = computed(() => cartItem?.value?.children?.[1]);
</script>
