function isObjectLike(value) {
    return typeof value === 'object' && value !== null;
}

const isValidCopyKey = function (key) {
    return !(key.startsWith('mgnl:') || key.startsWith('@id') || key.startsWith('@path'));
};

const filteredValues = function (value) {
    return Object.fromEntries(Object.entries(value).filter(([key]) => isValidCopyKey(key)));
};
const isEmpty = function (value) {
    return (
        // nullish
        value == null ||
        // empty array
        (value.hasOwnProperty('length') && value.length === 0) ||
        // no keys besides magnolia system keys
        (isObjectLike(value) &&
            Object.keys(value).filter(key => !(key.startsWith('@') || key.startsWith('mgnl:'))).length === 0) ||
        // deleted resizer image
        (isObjectLike(value) && value.hasOwnProperty('focusHorizontal') && value.hasOwnProperty('damID') === false)
    );
};

export const copyMasterToClient = function (node) {
    const { masterComponentReference } = node;

    if (masterComponentReference?.['@id']) {
        for (const [masterKey, masterValue] of Object.entries(masterComponentReference)) {
            // do not copy ids / system props
            if (isValidCopyKey(masterKey)) {
                const clientValue = node[masterKey];

                if (masterValue['@nodeType'] === 'mgnl:area') {
                    // found a master area which is empty in client
                    if (!clientValue || !clientValue['@nodes'] || clientValue?.['@nodes']?.length === 0) {
                        // clientProps[masterKey] = masterValue;
                        const masterArea = masterValue;
                        const localArea = clientValue || {};
                        for (const [key] of Object.entries(masterArea)) {
                            if (isEmpty(localArea[key]) && isValidCopyKey(key)) {
                                // copy non system properties
                                localArea[key] = masterArea[key];
                            }
                        }
                        node[masterKey] = localArea;
                    }
                } else if (isEmpty(clientValue)) {
                    // copy flat master values to child if child is considered empty
                    // check if prop is empty or undefined in client and patch with master masterValue
                    node[masterKey] = masterValue;
                } else if (isEmpty(clientValue) && isObjectLike(masterValue)) {
                    // copy flat values to child they are falsy or empty
                    // check if prop is empty or undefined in client and patch with master masterValue
                    node[masterKey] = { ...filteredValues(masterValue), ...(clientValue || {}) };
                }
            }
        }
        // TODO delete master content !?
        // delete node.masterComponentReference;
        // delete node.masterContentTitle;
    }
    return node;
};
