<template>
    <div class="flex flex-col gap-8">
        <div class="flex flex-wrap items-center">
            <div>
                <img
                    v-if="imprint.image"
                    :src="imagePath(imprint.image)"
                    :alt="imageName(imprint.image)"
                    :height="getImageHeight(imprint.image) ?? 96"
                    :width="getImageWidth(imprint.image) ?? 20"
                    class="w-24 lg:w-32"
                />
                <img
                    v-else
                    src="~/assets/img/logo/logo-primary.svg"
                    width="96"
                    height="20"
                    class="w-24 lg:w-32"
                    alt="footer logo"
                />
            </div>
            <div class="ml-auto text-dark-blue flex gap-2">
                <LinkComponent
                    v-for="social in filterNodesByType(mapNodes(imprint.socialIcons ?? {}), 'mgnl:contentNode')"
                    :key="getId(social)"
                    class="btn btn-icon"
                    :link="social.link"
                >
                    <img
                        class="h-5 w-5"
                        :src="imagePath(social.image)"
                        :alt="getImageAlt(social.image)"
                        :title="getImageTitle(social.image)"
                    />
                </LinkComponent>
            </div>
        </div>
        <div class="flex flex-row flex-wrap responsive-gap">
            <div class="flex gap-4 flex-col items-start w-full mb-4 xl:mb-0 xl:w-auto xl:flex-row flex-wrap xl:order-2">
                <CallToActionComponent
                    v-for="link in filterNodesByType(mapNodes(imprint), 'nav:link')"
                    :key="getId(link)"
                    :link="link.link"
                ></CallToActionComponent>
            </div>
            <div v-if="imprint.copyright" class="font-medium xl:order-1">{{ imprint.copyright }}</div>
            <div v-if="imprint.languageSelector" class="ml-auto xl:order-3">{{ currentLanguage }}</div>
        </div>
        <div
            v-if="imprint.disclosureText"
            class="prose prose-sm prose-black text-xs"
            v-html="imprint.disclosureText"
        ></div>
    </div>
</template>

<script setup>
import { getImageAlt, getImageTitle, getImageHeight, getImageWidth } from '~/utils/helper/magnolia';
import UseMagnoliaLanguage from '~/composables/useMagnoliaLanguage';
const { currentLanguage } = UseMagnoliaLanguage();
</script>

<script>
import { editableComponent } from '~/utils/mixins/editableComponent';
import { filterNodesByType, getId, imageName, imagePath, mapNodes } from '~/utils/helper/magnolia';
import LinkComponent from '../../components/cms/LinkComponent';
import CallToActionComponent from '../../components/cms/CallToActionComponent';

export default {
    name: 'FooterImprint',
    components: {
        CallToActionComponent,
        LinkComponent,
    },
    mixins: [editableComponent],
    props: ['imprint'],

    methods: {
        mapNodes,
        filterNodesByType,
        getId,
        imageName,
        imagePath,
    },
};
</script>
