<template>
    <div>
        <div
            class="slide-container relative flex flex-col items-center w-9/12 xl:w-6/12 mx-auto justify-center"
            :class="
                contentToTailwindClasses({
                    textColor: content.textColor,
                    textAlign: content.horizontal,
                })
            "
        >
            <div v-for="childContent in mapNodes(content)" :key="childContent['@id']">
                <FadeContainer ref="fader" class="slide-item">
                    <EditableComponent :content="childContent" />
                </FadeContainer>
            </div>
        </div>
    </div>
</template>

<script>
import { EditableComponent } from '@magnolia/vue-editor';
import FadeContainer from '../../elements/FadeContainer';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import { mapNodes } from '~/utils/helper/magnolia';
import { scrollableComponent } from '~/utils/mixins/scrollableComponent';

export default {
    name: 'EditorialStoryComponentArea',
    components: { EditableComponent, FadeContainer },
    mixins: [scrollableComponent],
    inject: ['slideStore'],
    provide() {
        return {
            horizontal: this.content.horizontal,
        };
    },
    props: ['content'],
    methods: {
        contentToTailwindClasses,
        mapNodes,
        onScrollUpdate() {
            const reference = 0.5;
            const sortedFader = [...(this.$refs?.fader ?? [])].sort(function (a, b) {
                return Math.abs(reference - a.percentage) - Math.abs(reference - b.percentage);
            });
            this.slideStore.activeSlide = this.$refs?.fader?.indexOf(sortedFader[0]) ?? 0;
        },
    },
};
</script>

<style scoped>
.slide-container {
    padding: 20vh 0;
}
.slide-item {
    @apply flex items-center;
    min-height: 60vh;
}
</style>
