<template>
    <div>
        <LoadingElement v-if="isLoading" class="container w-full aspect-square md:aspect-video"></LoadingElement>

        <template v-else>
            <EditableArea v-if="main" :content="main"></EditableArea>

            <EditableArea :content="yource" :custom-view="EmptyArea">
                <div class="container my-12 xl:my-24 flex justify-center-center">
                    <div class="flex flex-col mx-auto gap-4">
                        <HeadlineComponent
                            class="font-medium text-2xl"
                            :headline="yource.headline"
                            tag="h3"
                        ></HeadlineComponent>
                        <div v-if="yource.text" class="prose" v-html="yource.text"></div>
                        <button class="btn btn-blue" @click="startMigration()">
                            <span v-if="yource.buttonLabel">{{ yource.buttonLabel }}</span>
                            <CommonLabel v-else path="BWF/migration/yource/buttonLabel"></CommonLabel>
                        </button>
                    </div>
                </div>
            </EditableArea>

            <EditableArea v-if="bottom" :content="bottom" />
        </template>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import { isEditMode } from '~/utils/content/magnolia';
import EmptyArea from '~/templates/areas/EmptyArea.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
const { $msal } = useNuxtApp();

defineOptions({
    inheritAttrs: false,
});
defineProps(['main', 'yource', 'bottom']);
const { query, fullPath } = useRoute();
const {
    public: { baseUrl },
} = useRuntimeConfig();
const { marketSetupLinks } = useMarketSetup();

// check wether entry page is for active subsriptions or not
const isYourceActivePage = ref(
    fullPath?.includes(marketSetupLinks.value?.yourceMigrationActivePage ?? 'no-migration-value')
);
const isLoading = ref(!isEditMode());
const userEmail = ref(atob(query.token ?? query.encodedEmail ?? query.email ?? ''));

if (!userEmail.value && !isEditMode()) {
    // show Error if email not provided
    showError({ statusCode: 404, statusMessage: 'not found' });
}
const startMigration = () => {
    if (!userEmail.value) {
        return;
    }

    const redirectTarget = isYourceActivePage.value
        ? `${baseUrl}${marketSetupLinks.value.yourceMigrationActiveSuccessPage}`
        : `${baseUrl}${marketSetupLinks.value.yourceMigrationSuccessPage}`;

    $msal.migrateYourcePassword(redirectTarget, userEmail.value);
};

onMounted(async () => {
    if (query?.instant && !isEditMode()) {
        startMigration();
    } else {
        isLoading.value = false;
    }
});
</script>
