<template>
    <svg class="svg-icon" fill="currentColor">
        <use :href="`${prependBaseUrl ? baseUrl : ``}/sprite/icon-sprite.symbol.svg#sprite--svg--${name}`"></use>
    </svg>
</template>

<script setup>
// TODO consider using cdn url ?
const {
    public: { baseUrl },
} = useRuntimeConfig();
defineProps(['name', 'prependBaseUrl']);
</script>
