<template>
    <div ref="productListing" class="flex flex-col gap-8">
        <HeadlineComponent
            class="no-container-page-offset text-2xl xl:text-3xl text-dark-blue"
            :headline="headline"
        ></HeadlineComponent>
        <EditableArea :content="main" :custom-view="ProductTilesArea" />
        <div class="no-container-page-offset flex flex-col gap-2 items-start">
            <div v-if="text" class="xl:text-lg">
                {{ text }}
            </div>
            <CallToActionComponent v-if="link?.label" :link="link" class="btn btn-ghost"></CallToActionComponent>
        </div>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import HeadlineComponent from './HeadlineComponent';
import CallToActionComponent from './CallToActionComponent';
import ProductTilesArea from '~/templates/components/cms/ProductTilesArea.vue';
import { mapNodes } from '~/utils/helper/magnolia';
import { PRODUCT_CARD_ASSOCIATIONS } from '~/utils/helper/shop/product';
import { useShopwareContext } from '@shopware-pwa/composables-next';

const props = defineProps(['main', 'headline', 'text', 'link', 'slider', 'mobileSlider', 'metadata', 'directToCart']);

const productListing = ref(null);
const products = mapNodes(props.main ?? []);

const { apiInstance } = useShopwareContext();

const preloadedElements = ref([]);

const { data: productSearch } = await useAsyncData(`tiles-listing-${products.map(p => p.sku).join('-')}`, async () => {
    const response = await apiInstance.invoke.post('/store-api/product', {
        limit: products?.length,
        ids: products.map(p => p.sku),
        associations: PRODUCT_CARD_ASSOCIATIONS.criteria?.associations,
    });
    return response.data;
});

preloadedElements.value = productSearch?.value?.elements ?? [];

provide('product-tiles', preloadedElements.value);
provide('slider', props.slider);
provide('mobileSlider', props.mobileSlider);
provide('directToCart', props.directToCart);

const trackingItems = products.map((product, index) => {
    // generate tracking items based on magnolia configuration to align order
    const listingProduct = preloadedElements?.value?.find(
        tile => tile.id === product.sku || tile.parentId === product.sku
    ) ?? { id: props.sku };
    return { ...listingProduct, index };
});

useItemListTracking({
    metadata: props.metadata,
    // @ts-ignore
    items: ref(trackingItems),
    rootElement: productListing,
});
</script>

<style>
.product-tiles-slider .swiper .swiper-slide {
    @apply h-auto;

    max-width: calc((10 / 12) * 100% + 8px);
    @screen md {
        max-width: calc((5 / 12) * 100% + 16px);
    }
    @screen xl {
        max-width: calc((3 / 12) * 100% + 24px);
    }
}
</style>
