import { computed, type Ref, ref, watch } from 'vue';

import type { Order, OrderLineItem } from '@shopware-pwa/types';

export type SelectedItem = {
    orderLineItemId: string;
    quantity: number;
};
export type LineItemSelection = {
    [key: string]: {
        item: OrderLineItem;
        selected: boolean;
        quantity: number;
    };
};
export function useOrderSelection(order?: Ref<Order | undefined | null> | Order | undefined | null) {
    const _order = inject('bwfSelectableOrder', order as Ref<Order>);
    provide('bwfSelectableOrder', _order);

    // Order Configuration is the actual configuration state that will be injected through the entire ui
    const orderConfiguration: Ref<LineItemSelection> = inject('bwfOrderConfiguration', ref({}));
    provide('bwfOrderConfiguration', orderConfiguration);

    if (order) {
        // order has been passed to the composable. create configuration ref
        watch(
            _order,
            () => {
                _order?.value?.lineItems?.forEach(lineItem => {
                    orderConfiguration.value[lineItem.id] = {
                        item: lineItem,
                        selected: false,
                        quantity: lineItem.quantity,
                    };
                });
            },
            { immediate: true }
        );
    }

    return {
        order: computed(() => _order.value),
        selectedItems: computed(() => {
            const selectedItems: SelectedItem[] = [];
            Object.entries(orderConfiguration.value).forEach(([, value]) => {
                const { item, quantity, selected } = value;
                if (selected) {
                    selectedItems.push({ orderLineItemId: item.id, quantity });
                }
            });

            return selectedItems;
        }),
        selectedLineItems: computed(() => {
            const selectedLineItems: OrderLineItem[] = [];
            Object.entries(orderConfiguration.value).forEach(([, value]) => {
                const { item, selected } = value;
                if (selected) {
                    selectedLineItems.push(item);
                }
            });

            return selectedLineItems;
        }),
        orderConfiguration,
    };
}
