<template>
    <div class="checkout-complete">
        <div class="col-span-full">
            <HeadlineComponent
                tag="h1"
                :text="headline"
                class="text-lg xl:text-4xl font-medium text-dark-blue"
            ></HeadlineComponent>
        </div>
        <hr class="my-4 xl:my-6" />
        <div class="my-4 grid gap-6 xl:grid-cols-3 xl:gap-8">
            <AccountAddressCard
                v-if="userDefaultBillingAddress"
                :key="userDefaultBillingAddress?.id"
                :address="userDefaultBillingAddress"
                is-billing
            >
                <template #title>
                    <CommonLabel path="BWF/shop/shared/billingAddressLabel"></CommonLabel>
                </template>
            </AccountAddressCard>

            <hr class="xl:hidden" />
            <AccountAddressCard :key="userDefaultShippingAddress?.id" :address="userDefaultShippingAddress" is-shipping>
                <template #title>
                    <CommonLabel path="BWF/shop/shared/shippingAddressLabel"></CommonLabel>
                </template>
            </AccountAddressCard>
            <div v-if="isGuestSession" class="relative flex flex-col xl:gap-4">
                <h3 class="font-medium m-0">
                    <CommonLabel path="BWF/shop/checkout/addressForm/emailAddressLabel"></CommonLabel>
                </h3>
                <span> {{ user?.email }} </span>
                <button
                    class="text-icon-link absolute right-0 top-0 xl:relative text-icon-link xl:top-auto xl:right-auto"
                    @click.prevent="emailModal.open()"
                >
                    <CommonLabel
                        class="hidden xl:block xl:place-self-start"
                        path="BWF/shop/checkout/addressForm/emailAddressChangeLabel"
                    >
                    </CommonLabel>
                    <CommonLabel class="xl:hidden" path="BWF/shop/shared/change"></CommonLabel>

                    <SvgIcon name="help-circle-outline" class="h-4 w-4"></SvgIcon>
                </button>
                <ModalComponent :controller="emailModal">
                    <template #headline>
                        <CommonLabel path="BWF/shop/checkout/addressForm/emailAddressLabel"></CommonLabel>
                    </template>
                    <form @submit.prevent="emailEditSave">
                        <InputField
                            v-model="emailState.email"
                            class="form-field-grey mb-8"
                            name="email-address"
                            type="email"
                            required="required"
                            autocomplete="off"
                            :label="checkoutLabels?.emailAddressLabel"
                            :placeholder="checkoutLabels?.emailAddressPlaceholder"
                            :error-message="emailVuelidate?.$errors[0]?.$message"
                            @change="emailVuelidate?.$touch()"
                        ></InputField>
                        <button type="submit" class="btn btn-blue">
                            <CommonLabel path="BWF/shop/shared/save"></CommonLabel>
                        </button>
                    </form>
                </ModalComponent>
            </div>

            <hr class="col-span-full" />

            <div
                class="flex flex-col col-span-full xl:col-span-2 gap-4"
                :class="{ 'animate-pulse grayscale pointer-events-none	': isLoading['paymentMethods'] }"
            >
                <h3 class="font-medium">
                    <CommonLabel path="BWF/shop/checkout/complete/paymentMethod"></CommonLabel>
                </h3>

                <PaymentMethodSelection
                    ref="paymentMethodSelection"
                    v-model:selected-payment-method="selectedPaymentMethod"
                    :payment-methods="paymentMethods"
                ></PaymentMethodSelection>
            </div>
            <hr class="col-span-full" />

            <fieldset class="contents">
                <legend class="col-span-full">
                    <h3 class="font-medium">
                        <CommonLabel path="BWF/shop/checkout/complete/shippingMethod"></CommonLabel>
                    </h3>
                </legend>
                <div
                    v-for="shippingOption in shippingMethods"
                    :key="shippingOption.id"
                    class="flex items-center"
                    :class="{ 'animate-pulse grayscale pointer-events-none	': isLoading['paymentMethods'] }"
                >
                    <RadioField v-model="selectedShippingMethod" :value="shippingOption.id" name="shipping-method">
                        <div class="flex flex-col gap-1">
                            {{ shippingOption.name }}
                            <div v-if="shippingOption?.deliveryTime?.name" class="text-sm text-medium-grey">
                                <CommonLabel path="BWF/shop/checkout/complete/shippingTime"></CommonLabel>
                                {{ shippingOption?.deliveryTime?.name }}
                            </div>
                        </div>
                    </RadioField>
                </div>
            </fieldset>
            <hr class="col-span-full mb-4 hidden xl:block" />
        </div>

        <div class="my-4 grid gap-4 xl:grid-cols-3 xl:gap-8">
            <div class="xl:col-span-2 flex flex-col gap-4">
                <ul role="list" class="">
                    <li
                        v-for="(cartItem, index) in getLineItemsWithoutPromotion(cartItems)"
                        :key="cartItem.id"
                        role="listitem"
                    >
                        <CheckoutCartItem :cart-item="cartItem" :column-names="index === 0" />
                        <hr v-if="index < getLineItemsWithoutPromotion(cartItems).length - 1" class="my-4 xl:my-6" />
                    </li>
                </ul>

                <CommonLabel class="mt-6" path="BWF/shared/vatFootnote" />
            </div>

            <aside class="xl:col-span-1 flex flex-col gap-4">
                <CheckoutSummary />
                <hr class="xl:hidden" />
                <!--                <CheckoutPromotionCode />-->
            </aside>
        </div>

        <hr class="col-span-full mb-4 hidden xl:block" />

        <form
            id="checkout-create-order"
            name="checkout-create-order"
            class="col-span-full py-8 grid gap-6"
            method="post"
            @submit.prevent="invokeSubmit"
        >
            <template v-if="marketSetupAdmin?.legalCheckbox">
                <p class="hidden xl:block">
                    <CommonLabel path="BWF/shop/checkout/complete/rightOfWithdrawal"></CommonLabel>
                </p>

                <CheckboxField
                    v-if="marketSetupAdmin?.legalCheckbox"
                    v-model="legalState.legal"
                    name="legal"
                    :error-message="v$?.legal?.$errors?.[0]?.$message"
                    @update:model-value="v$.legal.$touch()"
                >
                    <CommonLabel path="BWF/shop/checkout/complete/privacyPolicyNotice"></CommonLabel>
                </CheckboxField>
            </template>
            <template v-else>
                <CommonLabel path="BWF/shop/checkout/complete/rightOfWithdrawalNoCheckbox"></CommonLabel>
                <CommonLabel path="BWF/shop/checkout/complete/privacyPolicyNoticeNoCheckbox"></CommonLabel>
            </template>
        </form>

        <hr class="col-span-full my-10 hidden xl:block" />

        <div class="grid gap-8 xl:grid-cols-3">
            <button
                class="btn btn-blue btn-size-normal mt-4 xl:mt-0"
                :class="{ 'animate-pulse pointer-events-none': isLoading?.['placeOrder'] || false }"
                :disabled="isLoading?.['placeOrder'] || null"
                type="submit"
                form="checkout-create-order"
            >
                <span> <CommonLabel path="BWF/shop/checkout/complete/confirmPurchase"></CommonLabel></span>
            </button>
            <div class="xl:col-span-2 xl:order-first">
                <button class="text-icon-link mx-auto xl:mx-0" @click="$router.go(-1)">
                    <SvgIcon name="Arrow-Up" class="h-3 w-3 -rotate-90"></SvgIcon>
                    <span>
                        <CommonLabel path="BWF/shop/shared/backToShop"></CommonLabel>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import AccountAddressCard from '~/templates/components/shop/account/AccountAddressCard.vue';
import { onMounted, watch } from 'vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import RadioField from '~/templates/elements/form/RadioField.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import { useVuelidate } from '@vuelidate/core';
import useCommonLabels from '~/composables/useCommonLabels';
import { getLineItemsWithoutPromotion } from '~/utils/helper/shop/cart';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import ModalComponent from '~/templates/components/ModalComponent.vue';
import InputField from '~/templates/elements/form/InputField.vue';
import PaymentMethodSelection from '~/templates/components/shop/checkout/PaymentMethodSelection.vue';

const { pushShopwareError } = useShopwareNotifications();

const { marketSetupAdmin } = useMarketSetup();
const { saveOrder, getQueryString } = useOrderSession();

const { paymentMethods, shippingMethods, getPaymentMethods, getShippingMethods, createOrder } = useCheckout();
const { user, isGuestSession, userDefaultBillingAddress, userDefaultShippingAddress } = useUser();
const {
    selectedShippingMethod: shippingMethod,
    selectedPaymentMethod: paymentMethod,
    setShippingMethod,
    setPaymentMethod,
    refreshSessionContext,
} = useSessionContext();

const { cartItems, refreshCart } = useCart();
const { loadCustomerAddresses } = useAddress();
const { trackShippingInfo, trackPaymentInfo, trackPlaceOrder, trackOrderSummary } = useCheckoutTracking();

const isLoading = ref<{ [key: string]: boolean }>({});

const selectedShippingMethod = computed({
    get(): string {
        return shippingMethod.value?.id || '';
    },
    async set(shippingMethodId: string) {
        isLoading.value[shippingMethodId] = true;
        await setShippingMethod({ id: shippingMethodId });
        await refreshCart();
        isLoading.value[shippingMethodId] = false;
    },
});
const selectedPaymentMethod = computed({
    get(): string {
        return paymentMethod.value?.id || '';
    },
    async set(paymentMethodId: string) {
        isLoading.value[paymentMethodId] = true;
        await setPaymentMethod({ id: paymentMethodId });
        isLoading.value[paymentMethodId] = false;
    },
});

const emailModal = useModal();

const { getLabels, getLabelByPath } = useCommonLabels();

const headline = getLabelByPath('/BWF/shop/checkout/complete/completeOrderHeadline');
const checkoutLabels = getLabels('/BWF/shop/checkout/addressForm/', ['emailAddressPlaceholder', 'emailAddressLabel']);

watch(cartItems, async () => {
    isLoading.value['shippingMethods'] = true;
    isLoading.value['paymentMethods'] = true;

    await Promise.all([getShippingMethods({ forceReload: true }), getPaymentMethods({ forceReload: true })]);

    isLoading.value['shippingMethods'] = false;
    isLoading.value['paymentMethods'] = false;
});

watch(shippingMethods, async () => {
    const hasShippingMethod = !!shippingMethods.value.find(method => method.id === selectedShippingMethod.value);
    if (!hasShippingMethod) {
        // the current shipping method is not available fallback to the first available
        await setShippingMethod({ id: shippingMethods.value[0]?.id });
    }
});

watch(paymentMethods, async () => {
    const hasPaymentMethod = !!paymentMethods.value.find(method => method.id === selectedPaymentMethod.value);
    if (!hasPaymentMethod) {
        // current payment method not available fallback to the first available
        await setPaymentMethod({ id: paymentMethods.value[0]?.id });
    }
});

//@ts-ignore
const { emailValidators, requiredValidator, consentValidators } = await useValidation();

const { apiInstance } = useShopwareContext();

const legalRules = computed(() => ({
    legal: {
        ...consentValidators,
    },
}));

// set legal straight to true if it's not required
const legalState = reactive({
    legal: !marketSetupAdmin.value.legalCheckbox,
});
const v$ = useVuelidate(legalRules, legalState);

const emit = defineEmits<{
    (e: 'loadingStart'): void;
    (e: 'loadingEnd'): void;
    (e: 'success'): void;
}>();

const emailState = reactive({
    email: user?.value?.email ?? '',
});

const emailRules = computed(() => ({
    email: {
        ...requiredValidator,
        ...emailValidators,
    },
}));

const emailVuelidate = useVuelidate(emailRules, emailState);

const emailEditSave = async (): Promise<void> => {
    emailVuelidate.value.$touch();
    if (emailState?.email !== user?.value?.email) {
        try {
            await apiInstance.invoke.post(`/store-api/account/guest-customer/change-email`, {
                email: emailState?.email,
            });
            await refreshSessionContext();
            emit('success');
            emailModal.close();
        } catch (error) {
            console.error('error save email', error);
            emailModal.close();
        }
    } else {
        emailModal.close();
    }
};

const paymentMethodSelection = ref(null);
const invokeSubmit = async () => {
    v$.value.$touch();
    // if rendered, mollie components need to be valid as well
    const paymentValid = (await paymentMethodSelection.value?.validate()) ?? true;
    const formValid = await v$.value.$validate();
    const valid = formValid && paymentValid;

    if (valid) {
        try {
            isLoading.value['placeOrder'] = true;
            const {
                public: { baseUrl },
            } = useRuntimeConfig();

            trackShippingInfo();
            trackPaymentInfo();
            trackPlaceOrder(selectedPaymentMethod?.value);
            const order = await createOrder();
            // save order to session
            saveOrder(order);
            const cmsSuccessUrl = `${marketSetupAdmin.value?.checkoutSuccessPage}/${order.id}`;
            const cmsPaymentUrl = `${marketSetupAdmin.value?.orderPaymentPage}/${order.id}`;
            const queryString = isGuestSession.value ? `?${getQueryString()}` : '';
            const paymentData = {
                orderId: order.id,
                finishUrl: `${baseUrl}${cmsSuccessUrl}${queryString}`,
                errorUrl: `${baseUrl}${cmsPaymentUrl}${queryString}`,
            };

            const { data } = await apiInstance.invoke.post('/store-api/handle-payment', paymentData);
            // refresh cart to be sure that its empty
            await refreshCart();
            if (data.redirectUrl) {
                await navigateTo(data.redirectUrl, { external: true });
                return;
            }
            return navigateTo(cmsSuccessUrl, { external: true });
        } catch (e) {
            isLoading.value['placeOrder'] = false;
            await pushShopwareError(e);
        }
    } else {
        // error !?
        console.error('form is not valid');
    }
};

onMounted(async () => {
    // id user is authenticated
    emit('loadingStart');

    isLoading.value['shippingAddress'] = true;
    isLoading.value['shippingMethods'] = true;
    isLoading.value['paymentMethods'] = true;

    await Promise.all([loadCustomerAddresses(), getShippingMethods(), getPaymentMethods()]);

    isLoading.value['shippingAddress'] = false;
    isLoading.value['shippingMethods'] = false;
    isLoading.value['paymentMethods'] = false;
    trackOrderSummary();
    emit('loadingEnd');
});
</script>

<style>
.checkout-complete {
    h3 {
        @apply xl:text-lg;
    }
}
</style>
