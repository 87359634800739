import type { UseAddressReturn } from '@shopware-pwa/composables-next';

import { useAddress as coreAddress } from '@shopware-pwa/composables-next';
import type { CustomerAddress } from '@shopware-pwa/types';
import { getCustomerAddAddressEndpoint, getCustomerAddressEndpoint } from '@shopware-pwa/api-client';

export function useAddress() {
    const coreUseAddress: UseAddressReturn = coreAddress();
    const { apiInstance } = useShopwareContext();

    const skipBritaMwUpdateHeader = {
        headers: { 'sw-brita-mw-update': 'false' },
    };

    /**
     * TODO  remove once shopware core bug is fixed
     * actually the / guest is all thats added to the requests to temporarilly bypass the SW Core Issue
     */
    async function setDefaultCustomerBillingAddress(addressId: string, skipMwUpdate = false): Promise<string> {
        return await apiInstance.invoke.patch(
            `/store-api/account/guest/address/default-billing/${addressId}`,
            {
                addressId,
            },
            skipMwUpdate ? skipBritaMwUpdateHeader : {}
        );
    }

    async function setDefaultCustomerShippingAddress(addressId: string, skipMwUpdate = false): Promise<string> {
        return await apiInstance.invoke.patch(
            `/store-api/account/guest/address/default-shipping/${addressId}`,
            {
                addressId,
            },
            skipMwUpdate ? skipBritaMwUpdateHeader : {}
        );
    }

    /**
     * Add new customer address
     */
    async function createCustomerAddress(
        customerAddress: Partial<CustomerAddress>,
        skipMwUpdate = false
    ): Promise<CustomerAddress> {
        const result = await apiInstance.invoke.post(
            getCustomerAddAddressEndpoint(),
            customerAddress,
            skipMwUpdate ? skipBritaMwUpdateHeader : {}
        );
        return result.data;
    }

    /**
     * Update customer address
     */
    async function updateCustomerAddress(
        customerAddress: Partial<CustomerAddress>,
        skipMwUpdate = false
    ): Promise<CustomerAddress> {
        const result = await apiInstance.invoke.patch(
            getCustomerAddressEndpoint(customerAddress.id),
            customerAddress,
            skipMwUpdate ? skipBritaMwUpdateHeader : {}
        );
        return result.data;
    }

    return {
        ...coreUseAddress,
        setDefaultCustomerBillingAddress,
        setDefaultCustomerShippingAddress,
        updateCustomerAddress,
        createCustomerAddress,
    };
}
