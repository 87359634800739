<template>
    <Suspense>
        <ProductListing v-if="categoryId" v-bind="props"></ProductListing>
        <template #fallback>
            <LoadingElement class="aspect-square" />
        </template>
    </Suspense>
</template>

<script setup>
import LoadingElement from '~/templates/elements/LoadingElement.vue';

const props = defineProps([
    'categoryId',
    'metadata',
    'categoryPageSize',
    'filterAllLabel',
    'filterHint',
    'infoLink',
    'infoTitle',
    'directToCart',
]);

provide('directToCart', props.directToCart);
defineOptions({
    inheritAttrs: false,
});
</script>
