<template>
    <div class="product-exchange-box-information flex flex-col gap-4">
        <div class="flex flex-col gap-1">
            <CommonLabel
                class="xl:text-2xl font-medium"
                path="BWF/shop/productDetailPage/exchangeBox/summaryHeadline"
            ></CommonLabel>
            <CommonLabel
                class="text-medium-grey text-sm"
                path="BWF/shop/productDetailPage/exchangeBox/cylinderAmountHint"
            ></CommonLabel>
        </div>

        <div class="grid grid-cols-4 gap-4">
            <div class="contents text-sm text-black">
                <div class="col-span-3">
                    <CommonLabel
                        path="BWF/shop/productDetailPage/exchangeBox/summaryCylinders"
                        :placeholder="{ amountCylinders }"
                    ></CommonLabel>
                </div>
                <div class="col-span-1 font-medium">
                    <SharedPrice :value="tauschboxPrice"></SharedPrice>
                </div>
                <div class="col-span-3">
                    <CommonLabel
                        path="BWF/shop/productDetailPage/exchangeBox/summaryDeposit"
                        :placeholder="{ amountCylinders, depositLineItemLabel }"
                    ></CommonLabel>
                </div>
                <div class="col-span-1 font-medium">
                    <SharedPrice :value="totalDepositPrice"></SharedPrice>
                </div>
                <slot name="depositInfo"></slot>
                <hr class="col-span-full my-2" />
                <div class="col-span-3 text-black text-base">
                    <CommonLabel
                        path="BWF/shop/productDetailPage/exchangeBox/nextPrice"
                        :placeholder="{ nextAmount }"
                    ></CommonLabel>
                </div>
                <div class="col-span-1 text-black font-medium text-base">
                    <SharedPrice :value="nextPrice"></SharedPrice>
                </div>
                <slot name="priceInfo"></slot>

                <hr class="hidden xl:block col-span-full mt-4" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useExchangeBox } from '~/composables/shop/useExchangeBoxProduct';
import CommonLabel from '~/templates/components/CommonLabel.vue';

const { amountCylinders, totalDepositPrice, tauschboxPrice, nextAmount, nextPrice, depositLineItemLabel } =
    await useExchangeBox();
</script>

<style>
.product-variant-configurator {
    @apply relative flex flex-col gap-2;
}

.switch-container {
    @apply flex flex-wrap gap-2 md:gap-3;
}
.switch-btn {
    @apply h-5 xl:h-7 w-5 xl:w-7 border;
    .switch-btn-inner {
        @apply h-4 xl:h-6 w-4 xl:w-6 border;
    }
}
</style>
