<template>
    <div class="container break-all flex flex-col gap-4 p-6 rounded bg-light-grey">
        <div class="p-4 bg-white rounded">
            <h3 class="text-2xl text-dark-blue font-medium">STATE</h3>
            <p>Progress: {{ inProgress }}</p>
            <p>Interaction Status: {{ interactionProgress }}</p>
            <p>Is authenticated: {{ isAuthenticated }}</p>

            <template v-if="azureUser">
                <p>Welcome, {{ azureUser?.username }} <br /></p>
                <p>Local Account Id, {{ azureUser?.localAccountId }} <br /></p>
                <p v-if="emails?.length > 0">Emails: {{ emails }}</p>
                <p>ID Token: {{ azureUser.idToken }}</p>
            </template>
        </div>
        <div class="p-4 bg-white rounded flex flex-col gap-2">
            <h3 class="text-2xl text-dark-blue font-medium">Default Buttons</h3>
            <template v-if="!isAuthenticated">
                <button class="btn btn-blue" @click="$msal?.signIn()">Login Redirect - BUTTON</button>
            </template>
            <template v-else>
                <button class="btn btn-blue" @click="$msal?.signOut()">Logout Redirect - BUTTON</button>
                <button class="btn btn-blue" @click="$msal?.passwordChange()">Password Change - BUTTON</button>
                <button class="btn btn-blue" @click="$msal?.emailChange()">Email Change - BUTTON</button>
            </template>
        </div>

        <div class="p-4 bg-white rounded"></div>

        <div class="p-4 bg-white rounded flex flex-col gap-2">
            <h3 class="text-2xl text-dark-blue font-medium">Custom Flow</h3>
            <InputField v-model="policyId" label="policyId"></InputField>
            <button class="btn btn-blue" :disabled="policyId?.length === 0" @click.prevent="onAzure()">
                TEST POLICY
            </button>
        </div>
    </div>
</template>

<script setup>
import InputField from '~/templates/elements/form/InputField.vue';

const { $msal } = useNuxtApp();
const { azureUser } = useAzureUser();

watch($msal?.progressStatus, () => {
    console.log('status', $msal.progressStatus);
});

watch($msal?.interActionStatus, () => {
    console.log('i status', $msal.interActionStatus);
});

const inProgress = computed(() => $msal?.progressStatus);
const interactionProgress = computed(() => $msal?.interActionStatus);
const isAuthenticated = computed(() => $msal?.isAuthenticated());

// @ts-ignore
let emails = computed(() => azureUser?.idTokenClaims?.emails ?? []);

const policyId = ref('B2C_1_D2CSignIn');
const onAzure = () => {
    // instance.loginRedirect(dynamicRequest(policyId.value));
    $msal?.dynamicRequest(policyId.value);
};
</script>
