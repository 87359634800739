<template>
    <dialog
        ref="dialog"
        class="p-0 bg-transparent h-full w-full flex-col items-center justify-center"
        :class="{ 'is-open flex': reactiveOpen }"
        @close="onClose"
        @cancel="onClose"
        @click="onClick"
        @mousedown="stopPropagation"
        @touchstart="stopPropagation"
    >
        <form method="dialog">
            <button
                v-if="reactiveOpen"
                class="fixed z-10 top-4 right-4 h-12 w-12 rounded-full flex items-center justify-center bg-white group"
                value="cancel"
            >
                <div class="btn btn-icon group-hover:text-dark-blue">
                    <SvgIcon name="icon-menu-close"></SvgIcon>
                </div>
            </button>
            <div class="container">
                <header class="flex justify-end"></header>
                <article>
                    <slot></slot>
                </article>
                <footer></footer>
            </div>
        </form>
    </dialog>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import { watch, onUpdated } from 'vue';

const dialog = ref(null);
const props = defineProps({ open: Boolean });

const emit = defineEmits(['open', 'close']);

const reactiveOpen = ref(props.open);

function onClose() {
    reactiveOpen.value = false;
}

function onClick(event) {
    const { target } = event;
    // event.stopImmediatePropagation();

    if (target.nodeName === 'DIALOG') {
        dialog.value.close('dismiss');
    }
}

function stopPropagation(event) {
    if (reactiveOpen.value) {
        event.stopPropagation();
    }
}

watch(reactiveOpen, isOpen => {
    if (isOpen) {
        dialog.value.showModal();
        emit('open');
    } else {
        dialog.value.close();
        emit('close');
    }
});
// prop changes need to be watched and copied to internal state
watch(
    () => props.open,
    isOpen => {
        reactiveOpen.value = isOpen;
    }
);

onUpdated(() => {
    const video = dialog.value.querySelector('video');
    if (video) {
        video.focus();
    }
});
</script>

<style scoped>
dialog::backdrop {
    @apply bg-black bg-opacity-75;
}

.is-open {
    @apply translate-y-0;
}
</style>
