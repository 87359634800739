<template>
    <div ref="menuTeaser" class="menu-teaser group/teaser relative flex flex-col gap-4 w-full">
        <div
            v-if="containsImage(img)"
            class="aspect-3/2 overflow-hidden safari-overflow-hidden-fix rounded-2xl md:w-6/12 xl:w-full"
        >
            <PictureElement
                :img="img"
                :breakpoints="BREAKPOINTS"
                img-class="aspect-3/2 object-cover rounded-2xl transition-transform group-hover/teaser:scale-110"
            ></PictureElement>
        </div>
        <div class="flex flex-col items-start justify-center md:w-5/12 md:ml-auto xl:w-full">
            <div class="mb-4 text-xl md:text-2xl">
                <div class="font-medium">{{ headline }}</div>
                <div>{{ subline }}</div>
            </div>
            <LinkComponent
                v-if="link?.label"
                class="btn-size-small inline-flex"
                :stretch-link="true"
                :link="link"
                @click="trackPromoClick"
            >
                {{ link?.label }}
            </LinkComponent>
        </div>
    </div>
</template>

<script setup>
import PictureElement from '../../elements/PictureElement';
import { DIMENSIONS } from '~/utils/helper/imaging';
import LinkComponent from '../../components/cms/LinkComponent';
import { containsImage } from '~/utils/helper/magnolia';
const props = defineProps(['headline', 'subline', 'img', 'link']);

const menuTeaser = ref(null);
const { trackPromoClick, trackViewPromotionIfVisibleAndActive } = usePromoTracking({
    creativeName: props.headline,
    promoName: 'menu_teaser',
    promoIndex: 0,
});

const { isMenuOpen } = useMainNavigationState();

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['3/2'],
    md: DIMENSIONS['md'].width['1/2'].ratio['3/2'],
};

onMounted(() => {
    trackViewPromotionIfVisibleAndActive(menuTeaser, isMenuOpen);
});
</script>

<style>
.is-mobile .menu-teaser {
    @apply md:flex-row xl:flex-col;
}
</style>
