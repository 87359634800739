export default function () {
    const gtm = useGtm(); // auto-imported by the module

    const newsletterSoiTracking = () => {
        try {
            gtm?.trackEvent({
                event: 'gx.newsletter_soi',
                event_name: 'newsletter_soi',
                event_source: 'source_code',
                newsletter_soi: {
                    newsletter_selection: 'brita newsletter',
                },
            });
        } catch (e) {
            console.error(e);
        }
    };
    const newsletterDoiTracking = () => {
        try {
            gtm?.trackEvent({
                event: 'gx.newsletter_doi',
                event_name: 'newsletter_doi',
                event_source: 'source_code',
                newsletter_doi: {
                    newsletter_selection: 'brita newsletter',
                },
            });
        } catch (e) {
            console.error(e);
        }
    };

    return { newsletterSoiTracking, newsletterDoiTracking };
}
