import { ref } from 'vue';
import { createSharedComposable } from '@vueuse/core';

function singleUseAzureUser() {
    const { $msal } = useNuxtApp();
    // this causes warnings due to injection issues
    // add type either empty OR user + idToken
    const azureUser = ref({});

    const isAzureAuthenticated = computed(() => {
        // rely on token being added by middleware
        return azureUser.value?.idToken && $msal?.isAuthenticated();
    });

    // expose managed state as return value
    return {
        azureUser,
        isAzureAuthenticated,
    };
}

export default createSharedComposable(singleUseAzureUser);
