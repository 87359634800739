<template>
    <!-- Image gallery / Slider -->
    <div class="product-gallery relative -mx-[var(--container-padding)] aspect-square lg:mx-0 lg:col-span-6">
        <Swiper
            class="h-full w-full xl:bg-light-grey xl:rounded-lg xl:overflow-hidden"
            :navigation="{ prevEl: '.swiper-navigation.prev', nextEl: '.swiper-navigation.next' }"
            :pagination="{ clickable: true }"
            :modules="[Pagination, Navigation]"
            @slide-change="swiper => trackProductPageSlider(sortedProductMedia[swiper.activeIndex])"
        >
            <SwiperSlide v-for="(media, index) of sortedProductMedia" :key="index">
                <ProductVideo
                    v-if="
                        //@ts-ignore
                        media?.videoId
                    "
                    class="w-full h-full object-contain"
                    :src="
                        //@ts-ignore
                        media?.videoId
                    "
                    :thumbnail-media="
                        //@ts-ignore
                        media?.thumbnailMedia
                    "
                    :loading="index === 0 ? 'eager' : 'lazy'"
                ></ProductVideo>
                <ProductImage
                    v-else
                    :product-media="media.media"
                    :class="{ 'opacity-50': !product.available }"
                    main-class="w-full h-full object-contain"
                    fallback-class="w-full h-full object-contain"
                    :product-name="getProductName({ product })"
                    :loading="index === 0 ? 'eager' : 'lazy'"
                ></ProductImage>
            </SwiperSlide>

            <div class="absolute inset-4 z-10 isolate flex justify-between items-center pointer-events-none">
                <div class="swiper-navigation prev pointer-events-auto cursor-pointer">
                    <svg-icon name="Arrow-Up" class="w-4 h-4 -rotate-90"></svg-icon>
                </div>
                <div class="swiper-navigation next pointer-events-auto cursor-pointer">
                    <svg-icon name="Arrow-Up" class="w-4 h-4 rotate-90"></svg-icon>
                </div>
            </div>
        </Swiper>
    </div>
</template>

<script setup lang="ts">
import { getProductName } from '@shopware-pwa/helpers-next';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import { getSortedPdpMediaWithVideos } from '~/utils/helper/shop/media';
import ProductVideo from '~/templates/elements/ProductVideo.vue';
import ProductImage from '~/templates/elements/ProductImage.vue';

const { product } = useProduct();

const sortedProductMedia = computed(() => getSortedPdpMediaWithVideos(product.value));

//@ts-ignore
const { trackProductPageSlider } = useNavigationTracking();
</script>

<!--<style src="~/assets/css/thirdparty/swiper.css"></style>-->
<style>
@import url('~/assets/css/thirdparty/swiper.css');

.product-gallery {
    @apply lg:sticky transition-all;
    @screen lg {
        top: var(--sticky-header-height, 0px);
    }

    .swiper-navigation {
        @apply transition-opacity duration-700;
    }

    .swiper-navigation.swiper-button-disabled {
        @apply opacity-0 pointer-events-none;
    }

    .swiper-pagination-bullets {
        --swiper-pagination-bottom: theme('spacing.4');
        --swiper-pagination-bullet-horizontal-gap: theme('spacing.3');
    }
}
</style>
