<template>
    <img
        v-if="image"
        class="w-16 md:w-24"
        :width="getImageWidth(image) ?? 24"
        :height="getImageHeight(image) ?? 24"
        :src="imagePath(image)"
        :alt="imageName(image)"
    />
</template>

<script setup>
import { imagePath, imageName, getImageWidth, getImageHeight } from '~/utils/helper/magnolia';
</script>

<script>
export default {
    name: 'EditorialStoryImageComponent',
    props: ['image'],
};
</script>
