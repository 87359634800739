<template>
    <PageLayout>
        <section
            v-if="mgnlNotFound"
            class="container mt-8 flex-1 w-full overflow-x-clip flex flex-col gap-4 items-center justify-center bg-light-grey rounded-xl p-8 xl:p-18"
        >
            <div class="prose text-center">
                <h1 class="text-dark-blue">We're experiencing technical difficulties</h1>
                <p>
                    Our team is diligently working to resolve this as quickly as possible. While we sort this out, we
                    encourage you to try refreshing the page in a few moments or come back later to see if things are
                    back to normal.
                </p>
            </div>

            <template v-if="!!mgnlAuthor">
                <NuxtLink class="btn btn-blue" @click="gotoLastKnownMarket">Go to last Market</NuxtLink>
                <span class="text-xs">Only for debug purposes / magnolia authors ;) </span>
            </template>
        </section>

        <EditablePage v-if="hasCustomErrorContent" :content="content" :config="config" />
        <main
            v-if="query.debug"
            class="container mt-8 flex-1 w-full overflow-x-clip flex flex-col gap-4 items-center justify-center bg-light-grey rounded-xl p-8 xl:p-18"
            role="main"
        >
            <h2 class="text-4xl text-dark-blue font-medium">{{ error?.statusCode }}</h2>
            <div v-if="error?.message" class="prose break-all">{{ error?.message }}</div>
            <div class="max-w-full overflow-x-auto" v-html="error?.stack"></div>
        </main>
    </PageLayout>
</template>

<script setup>
import { DefaultFallbackComponent, EditablePage } from '@magnolia/vue-editor';
import PageLayout from '~/templates/partials/PageLayout';
import { provide } from 'vue';
import { componentMappings } from '~/utils/config/components';
import { getTemplate } from '~/utils/helper/magnolia';

const { query } = useRoute();

const error = useError();

const config = {
    componentMappings,
    fallbackComponent: DefaultFallbackComponent,
};

const { initMagnoliaContext, contentPath, magnoliaAuthorRedirect } = useMagnoliaContext();
const { initLanguages } = useMagnoliaLanguage();
// load market setup
const { marketSetup, loadMarketContent } = useMarketContent();
const { pageHeader, pageFooter, loadContent, content } = useMagnoliaContent();

const hasCustomErrorContent = computed(() => getTemplate(content.value) === 'd2c-lm:pages/error');
const mgnlNotFound = ref(false);
//
const authenticationProblem = error?.value?.statusCode === 401 || error?.value?.statusCode === 403;
try {
    await initLanguages();
    await initMagnoliaContext();

    if (!contentPath.value) {
        mgnlNotFound.value = true;
    } else {
        await loadMarketContent(contentPath.value);

        let errorPath = contentPath.value;
        if (error?.value?.statusCode === 412) {
            mgnlNotFound.value = true;
        } else if (error?.value?.statusCode === 404 && marketSetup.value?.pageNotFoundPage) {
            errorPath = marketSetup.value.pageNotFoundPage;
        } else if (authenticationProblem && marketSetup.value?.unauthorizedPage) {
            errorPath = marketSetup.value.unauthorizedPage;
        } else if (error?.value?.statusCode === 500 && marketSetup.value?.internalServerErrorPage) {
            errorPath = marketSetup.value.internalServerErrorPage;
        }
        // load content by error path
        await loadContent(errorPath);
        // load header
        provide('pageHeader', pageHeader);
        // // load footer
        provide('pageFooter', pageFooter);
        // this needs to be provided for unmapped components
        provide('fallbackComponent', DefaultFallbackComponent);
    }
} catch (e) {
    console.error('ERRORPAGE: ', e);
    mgnlNotFound.value = true;
}

const {
    public: { mgnlAuthor },
} = useRuntimeConfig();
const gotoLastKnownMarket = () => {
    magnoliaAuthorRedirect();
};

useHead({ title: content.value?.title ?? marketSetup?.fallbackTitle });
</script>

<script>
export default {
    name: 'ErrorPage',
};
</script>
