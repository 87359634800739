<template>
    <div
        ref="slide"
        class="carousel-slide"
        :class="{
            'is-active': isActiveOrEditMode,
            'is-editmode': isEditMode,
        }"
        @transitionend="onSlideTransitionEnd"
    >
        <div class="overflow-hidden relative">
            <div class="img-container relative w-full aspect-[6/7] md:aspect-video max-h-screen">
                <PictureElement
                    :img="image"
                    :breakpoints="BREAKPOINTS"
                    :class="{
                        hidden: containsImage(imageMobile),
                        'md:inline': containsImage(imageMobile),
                    }"
                    :loading="isActive ? 'eager' : 'lazy'"
                    img-class=" absolute inset-0  block object-cover w-full h-full"
                ></PictureElement>
                <PictureElement
                    v-if="containsImage(imageMobile)"
                    :img="imageMobile"
                    :breakpoints="BREAKPOINTS"
                    class="md:hidden"
                    :loading="isActive ? 'eager' : 'lazy'"
                    img-class=" absolute inset-0 block object-cover w-full h-full"
                ></PictureElement>
                <div
                    class="invisible md:visible absolute left-0 top-0 inset-x-0 h-full w-8/12 md:w-2/3 bg-gradient-to-r from-black via-black to-transparent"
                    :class="contentToTailwindClasses({ opacity: opacity })"
                ></div>
                <DotPattern
                    v-if="dotsVisible || isEditMode"
                    ref="dots"
                    :variant="dotPattern"
                    class="block absolute inset-0 w-full h-full"
                >
                </DotPattern>
            </div>
        </div>
        <div class="bg-light-beige md:bg-transparent md:absolute md:inset-0">
            <div
                class="text-container py-8 md:pb-0 md:h-full container flex flex-col justify-center items-center md:items-start text-center md:text-left"
            >
                <div class="text-3xl xl:text-6xl mb-6">
                    <HeadlineComponent
                        class="font-medium text-dark-blue md:text-white"
                        :headline="headline"
                    ></HeadlineComponent>
                    <HeadlineComponent class="text-black md:text-white" :headline="text"></HeadlineComponent>
                </div>
                <CallToActionComponent
                    v-if="link?.label ?? false"
                    class="text-sm lg:text-base lg:py-6"
                    :stretch-link="stretchLink"
                    :link="link"
                    @click="trackPromoClick"
                ></CallToActionComponent>
            </div>
        </div>
    </div>
</template>

<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import DotPattern from '~/templates/elements/DotPattern.vue';
import { containsImage } from '~/utils/helper/magnolia';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import { isComponentPreview, isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import CallToActionComponent from '~/templates/components/cms/CallToActionComponent.vue';
import PictureElement from '~/templates/elements/PictureElement.vue';
import usePromoTracking from '~/composables/tracking/usePromoTracking';

const props = defineProps([
    'text',
    'headline',
    'image',
    'imageTablet',
    'imageMobile',
    'link',
    'isActive',
    'dotPattern',
    'opacity',
    'navText',
    'metadata',
    'stretchLink',
]);

const isEditMode = evaluateEditMode();
const dotsVisible = ref(false);
const slide = ref(null);
const isActive = toRef(() => props.isActive);

const { trackViewPromotionIfVisibleAndActive, trackPromoClick } = usePromoTracking({
    metadata: props.metadata,
    promoIndex: props.index,
    creativeName: props.navText,
});
const onSlideTransitionEnd = () => {
    dotsVisible.value = props.isActive;
};
const isActiveOrEditMode = computed(() => {
    return props.isActive || isEditMode || isComponentPreview();
});

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['6/7'],
    sm: DIMENSIONS['sm'].ratio['6/7'],
    md: DIMENSIONS['md'].ratio.video,
    xl: DIMENSIONS['xl'].ratio.video,
    container: DIMENSIONS['container'].ratio.video,
    page: DIMENSIONS['page'].ratio['video'],
};

onMounted(() => {
    trackViewPromotionIfVisibleAndActive(slide, isActive);
});
</script>

<style scoped>
.carousel-slide {
    @apply transition-opacity opacity-0 duration-2000 pointer-events-none;
    @apply absolute inset-0;
}

.img-container {
    @apply transition-all scale-105 duration-2000;
}

.text-container {
    @apply transition-all md:-translate-x-4 duration-300;
    @apply opacity-0;

    @screen md {
        padding-top: var(--fixed-header-height, 0px);
    }
}

.carousel-slide.is-active,
.carousel-slide.is-editmode {
    @apply opacity-100 z-10 pointer-events-auto;
    @apply relative;

    .img-container {
        @apply scale-100;
        @apply delay-300;
    }

    .text-container {
        @apply transition-all;
        @apply md:delay-300;
        @apply md:duration-700;
        @apply md:translate-x-0;
        @apply opacity-100;
    }
}
</style>
