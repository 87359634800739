<template>
    <section class="flex flex-col border border-grey rounded-lg px-4 py-6 xl:px-6">
        <h3 class="font-medium text-lg">
            <CommonLabel path="BWF/shop/account/profile/personalDataLabel"></CommonLabel>
        </h3>
        <form class="space-y-6" @submit.prevent="invokeUpdate">
            <div class="grid gap-4 xl:gap-6 xl:grid-cols-2 mt-6">
                <SelectField
                    v-model="state.salutationId"
                    :label="personalDataLabels?.salutationsLabel"
                    class="form-field-grey"
                    name="salutationId"
                    :options="salutationOptions(getSalutations)"
                />
                <InputField
                    v-model="state.firstName"
                    class="form-field-grey xl:row-start-2"
                    name="first-name"
                    required="required"
                    :label="personalDataLabels?.firstNameLabel"
                    :error-message="$validatedPersonals?.firstName?.$errors?.[0]?.$message"
                    :placeholder="personalDataLabels?.firstNamePlaceholder"
                    @blur="$validatedPersonals.firstName.$touch()"
                ></InputField>

                <InputField
                    v-model="state.lastName"
                    class="form-field-grey xl:row-start-2"
                    name="last-name"
                    required="required"
                    :error-message="$validatedPersonals?.lastName?.$errors?.[0]?.$message"
                    :label="personalDataLabels?.lastNameLabel"
                    :placeholder="personalDataLabels?.lastNamePlaceholder"
                    @blur="$validatedPersonals.lastName.$touch()"
                ></InputField>
            </div>
            <p class="text-sm text-medium-grey mt-6 xl:mt-7">
                <CommonLabel path="BWF/shop/shared/mandatoryFieldsLabel"></CommonLabel>
            </p>

            <button class="btn btn-blue btn-size-sm mt-6 xl:mt-8 xl:self-start xl:btn-size-normal">
                <CommonLabel path="BWF/shared/save"></CommonLabel>
            </button>
        </form>
    </section>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import InputField from '~/templates/elements/form/InputField.vue';
import SelectField from '~/templates/elements/form/SelectField.vue';
import type { ClientApiError } from '@shopware-pwa/types';
import { salutationOptions } from '~/utils/helper/shop/salutations';
import useAccountTracking from '~/composables/tracking/useAccountTracking';

const { pushSuccess } = useNotifications();
const { getSalutations } = useSalutations();

const { user, refreshUser, updatePersonalInfo } = useUser();
const { refreshSessionContext } = useSessionContext();

const { getLabels, getLabelByPath } = useCommonLabels();

const personalDataLabels = getLabels('/BWF/shop/shared/addressForm/', [
    'salutationsLabel',
    'chooseSalutationLabel',
    'firstNameLabel',
    'firstNamePlaceholder',
    'lastNameLabel',
    'lastNamePlaceholder',
]);

const successMessage = getLabelByPath('BWF/shop/account/profile/editSuccessMessage');

const errorMessages = ref<string[]>([]);

const isSuccess = ref(false);
const updated = ref(false);
const isUpdating = ref(false);

const state = reactive({
    firstName: user?.value?.firstName ?? '',
    lastName: user?.value?.lastName ?? '',
    salutationId: user?.value?.salutationId ?? '',
    title: user?.value?.title ?? '',
});

const isNameChanging = computed(
    () =>
        state.firstName !== user.value?.firstName ||
        state.lastName !== user.value?.lastName ||
        state.salutationId !== user.value?.salutationId
);

//@ts-ignore
const { firstNameValidators, lastNameValidators, requiredValidator } = await useValidation();

const rules = computed(() => ({
    firstName: {
        ...requiredValidator,
        ...firstNameValidators,
    },
    lastName: {
        ...requiredValidator,
        ...lastNameValidators,
    },
}));

const $validatedPersonals = useVuelidate(rules, state);
//@ts-ignore
const { trackAccountModification } = useAccountTracking();
const invokeUpdate = async (): Promise<void> => {
    errorMessages.value = [];
    isSuccess.value = false;
    try {
        updated.value = false;
        $validatedPersonals.value.$touch();
        if ($validatedPersonals.value.$invalid || !isNameChanging.value) {
            return;
        }
        isUpdating.value = true;

        if (isNameChanging.value) {
            await updatePersonalInfo({
                firstName: state.firstName,
                lastName: state.lastName,
                salutationId: state.salutationId,
                title: state.title,
            });
            isSuccess.value = true;
            pushSuccess(successMessage);
            trackAccountModification('modify name');
        }

        isUpdating.value = false;

        await refreshSessionContext();
    } catch (err) {
        const e = err as ClientApiError;
        errorMessages.value = e.messages.map(m => m.detail);
    }
};
onMounted(async () => {
    await refreshUser();
    state.firstName = user.value?.firstName || '';
    state.lastName = user.value?.lastName || '';
    state.salutationId = user.value?.salutationId || '';
    state.title = user.value?.title || '';
});
</script>
