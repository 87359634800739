<template>
    <AccountAddressForm
        :address="address"
        :filter-shipping-countries="true"
        @address-submit="invokeSave"
        @close-modal="emit('close-modal')"
    ></AccountAddressForm>
</template>

<script setup lang="ts">
import type { CustomerAddress } from '@shopware-pwa/types';
import useAccountTracking from '~/composables/tracking/useAccountTracking';
import useShopwareNotifications from '~/composables/useShopwareNotifications';
const { refreshSessionContext } = useSessionContext();
const { defaultBillingAddressId, defaultShippingAddressId } = useUser();
const props = defineProps<{
    address: CustomerAddress;
}>();

const { createCustomerAddress, updateCustomerAddress, setDefaultCustomerShippingAddress } = useAddress();
const { pushSuccess } = useNotifications();
const { pushShopwareError } = useShopwareNotifications();

const { getLabels } = useCommonLabels();
const addressFormPushLabels = getLabels('BWF/shared/notifications/', [
    'setDefaultShippingAddressError',
    'setDefaultShippingAddressSuccess',
    'setDefaultBillingAddressError',
    'setDefaultBillingAddressSuccess',
]);

const emit = defineEmits<{
    (e: 'close-modal'): void;
}>();
const { trackAccountModification } = useAccountTracking();
const identicalAddresses = defaultShippingAddressId.value === defaultBillingAddressId.value;
const isDummyAddress = computed(() => !!props.address?.customFields?.dummyAddress);
const currentAddressId = computed(() => props.address?.id);
const invokeSave = async (addressFormState: never): Promise<void> => {
    try {
        // update payload with id
        const updateAddress: CustomerAddress = {
            ...addressFormState,
            id: currentAddressId.value,
        };
        // clone address
        const clonedAddress: CustomerAddress = {
            ...(isDummyAddress.value ? addressFormState : props.address),
        };
        // rm id from clone just in case
        delete clonedAddress.id;

        if (identicalAddresses) {
            // create a new one
            const newShippingAddress = await createCustomerAddress(addressFormState, true);
            await setDefaultCustomerShippingAddress(newShippingAddress.id);
        } else {
            await updateCustomerAddress(updateAddress);
        }

        await refreshSessionContext();
        trackAccountModification('modify shipping');
        emit('close-modal');
        pushSuccess(`${addressFormPushLabels.setDefaultShippingAddressSuccess}`);
    } catch (error) {
        await pushShopwareError(error);
    }
};
</script>
