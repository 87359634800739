<template>
    <div class="space-y-8 xl:space-y-12">
        <h2 class="relative text-center text-2xl font-medium xl:text-3xl">
            {{ headline?.text }}
        </h2>
        <EditableArea :content="main" :custom-view="BenefitsComponentArea"></EditableArea>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import BenefitsComponentArea from '~/templates/components/cms/BenefitsComponentArea.vue';
const props = defineProps(['headline', 'main', 'backgroundColor']);
provide('backgroundColor', props.backgroundColor);
</script>
