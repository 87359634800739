<template>
    <EditableArea v-if="main" :content="main" />
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';

const props = defineProps(['title', 'metadata', 'main', 'leadTitle', 'leadText', 'leadVisual', 'tags']);
const { trackArticlePage } = useArticleTracking();
trackArticlePage(props?.title);
defineOptions({
    inheritAttrs: false,
});
const { articleSchema } = useD2CStructuredData();
useJsonld(articleSchema(props?.leadTitle, props?.leadText, props?.leadVisual));
</script>
