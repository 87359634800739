<template>
    <div
        :class="[
            !separatedColumns || hasNoColumns ? contentToTailwindClasses({ backgroundColor }) : '',
            (!separatedColumns || hasNoColumns) && hasValue(backgroundColor) ? 'p-6 border rounded-2xl' : '',
        ]"
        class="prose hyphens-auto max-w-none"
    >
        <div v-if="hasNoColumns" v-html="richText"></div>
        <div v-else class="responsive-gap-x gap-y-6 grid md:grid-cols-2 xl:flex">
            <div
                :class="[
                    separatedColumns ? contentToTailwindClasses({ backgroundColor }) : '',
                    separatedColumns && hasValue(backgroundColor) ? ' p-6 border rounded-2xl' : '',
                ]"
                class="flex-1"
                v-html="richText"
            ></div>
            <div
                v-for="(column, index) in textColumns"
                :key="index"
                :class="[
                    separatedColumns ? contentToTailwindClasses({ backgroundColor }) : '',
                    separatedColumns && hasValue(backgroundColor) ? ' p-6 border rounded-2xl' : '',
                ]"
                class="flex-1"
                v-html="column"
            ></div>
        </div>
    </div>
</template>

<script setup>
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import { hasValue } from '~/utils/helper/magnolia';

const props = defineProps(['richText', 'textColumns', 'backgroundColor', 'separatedColumns']);

const hasNoColumns = computed(() => {
    return (props.textColumns ?? []).length === 0;
});
</script>
