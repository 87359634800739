<template>
    <svg
        id="e0lpcUxJ3DZ1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 375 375"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
    >
        <g id="e0lpcUxJ3DZ2_ts" transform="translate(380,132) scale(0.2,0.2)">
            <ellipse
                id="e0lpcUxJ3DZ2"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#84d0f5"
                fill-opacity="0.65"
                stroke-width="0"
            />
        </g>
        <g id="e0lpcUxJ3DZ3_ts" transform="translate(380,244) scale(0.2,0.2)">
            <ellipse
                id="e0lpcUxJ3DZ3"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#00569d"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="e0lpcUxJ3DZ4_ts" transform="translate(268,132) scale(0.2,0.2)">
            <ellipse
                id="e0lpcUxJ3DZ4"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#00569d"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="e0lpcUxJ3DZ5_ts" transform="translate(268,20) scale(0.2,0.2)">
            <ellipse
                id="e0lpcUxJ3DZ5"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#00569d"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
    </svg>
</template>
<style scoped>
#e0lpcUxJ3DZ2_ts {
    animation: e0lpcUxJ3DZ2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ2_ts__ts {
    0% {
        transform: translate(380px, 132px) scale(0.2, 0.2);
    }
    30% {
        transform: translate(380px, 132px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        transform: translate(380px, 132px) scale(1, 1);
    }
    100% {
        transform: translate(380px, 132px) scale(1, 1);
    }
}
#e0lpcUxJ3DZ2 {
    animation: e0lpcUxJ3DZ2_c_o 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ2_c_o {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#e0lpcUxJ3DZ3_ts {
    animation: e0lpcUxJ3DZ3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ3_ts__ts {
    0% {
        transform: translate(380px, 244px) scale(0.2, 0.2);
    }
    20% {
        transform: translate(380px, 244px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        transform: translate(380px, 244px) scale(1, 1);
    }
    100% {
        transform: translate(380px, 244px) scale(1, 1);
    }
}
#e0lpcUxJ3DZ3 {
    animation: e0lpcUxJ3DZ3_c_o 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ3_c_o {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#e0lpcUxJ3DZ4_ts {
    animation: e0lpcUxJ3DZ4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ4_ts__ts {
    0% {
        transform: translate(268px, 132px) scale(0.2, 0.2);
    }
    10% {
        transform: translate(268px, 132px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        transform: translate(268px, 132px) scale(1, 1);
    }
    100% {
        transform: translate(268px, 132px) scale(1, 1);
    }
}
#e0lpcUxJ3DZ4 {
    animation: e0lpcUxJ3DZ4_c_o 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ4_c_o {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#e0lpcUxJ3DZ5_ts {
    animation: e0lpcUxJ3DZ5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ5_ts__ts {
    0% {
        transform: translate(268px, 20px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        transform: translate(268px, 20px) scale(1, 1);
    }
    100% {
        transform: translate(268px, 20px) scale(1, 1);
    }
}
#e0lpcUxJ3DZ5 {
    animation: e0lpcUxJ3DZ5_c_o 2000ms linear 1 normal forwards;
}
@keyframes e0lpcUxJ3DZ5_c_o {
    0% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
