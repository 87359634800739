<template>
    <div class="relative flex w-full flex-col gap-4 p-4 text-left lg:py-6">
        <ProductBadge class="absolute top-0 right-4" :product="product" />
        <div class="flex items-center gap-4" :class="{ '-ml-4': mainImage }">
            <div v-if="mainImage" class="w-18 shrink-0">
                <div class="aspect-square">
                    <ProductImage
                        :product-media="product?.cover?.media"
                        main-class="object-contain w-full h-full"
                    ></ProductImage>
                </div>
            </div>
            <div class="flex-1">
                <div class="flex gap-0.5 justify-between">
                    <h4 class="font-medium text-base leading-snug text-black">{{ title }}</h4>
                    <div
                        v-if="absPriceDifference && !stepProduct?.selected"
                        class="inline-flex items-center gap-1 self-start shrink-0 font-normal text-sm leading-5 text-black"
                    >
                        <SharedPrice :value="absPriceDifference">
                            <template #beforePrice>{{ priceSign }}</template>
                        </SharedPrice>
                    </div>
                </div>
                <div
                    class="flex-none font-normal text-sm leading-5 text-black"
                    v-html="productShortDescriptionEshopStep"
                />
            </div>
        </div>
        <template v-if="productLongDescriptionEshopStep">
            <hr />
            <div class="prose" v-html="productLongDescriptionEshopStep" />
        </template>
    </div>
</template>
<script setup lang="ts">
import { getSmallestThumbnailUrl, getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { EshopSetConfiguratorStepProduct } from '~/composables/shop/eShopSetConfiguratorTypes';
import type { Product } from '@shopware-pwa/types';
import { getValueSign } from '~/utils/helper/number';
import ProductImage from '~/templates/elements/ProductImage.vue';

const props = defineProps<{ stepProduct: EshopSetConfiguratorStepProduct }>();

const product = computed<Product>(() => props.stepProduct?.product as Product);
const title = computed(() => getTranslatedProperty(product.value, 'name'));
const productShortDescriptionEshopStep = computed(() =>
    // @ts-ignore
    getTranslatedProperty(product?.value?.translated?.customFields, 'brita_product_short_description_eshop_step')
);
const productLongDescriptionEshopStep = computed(() => props?.stepProduct?.description);
const absPriceDifference = computed(() => Math.abs(props?.stepProduct?.differencePrice ?? 0));
const priceSign = computed(() => getValueSign(props?.stepProduct?.differencePrice));
const mainImage = computed(() => getSmallestThumbnailUrl(product?.value?.cover?.media));
</script>
