<template>
    <div class="border-t xl:border xl:rounded-lg py-4 xl:p-6 xl:pb-4 text-sm flex flex-col gap-4 xl:gap-6">
        <div class="flex gap-4 items-start relative">
            <OrderProductImage :line-item="order?.lineItems?.[0]"></OrderProductImage>
            <div class="w-full grid xl:grid-cols-5 gap-1 xl:gap-4">
                <div class="grid xl:col-span-2 grid-cols-2 gap-1 xl:gap-x-2 items-center hyphens-auto">
                    <AccountOrderStateLabel
                        :state="order?.stateMachineState"
                        class="col-span-full xl:col-span-1 place-self-start mb-1 xl:mb-0"
                    ></AccountOrderStateLabel>
                    <span class="font-medium xl:font-normal xl:-order-1">
                        <CommonLabel path="BWF/shop/account/orderHistory/orderNumber"></CommonLabel>
                    </span>
                    <span class="xl:text-2xl xl:font-medium">{{ order.orderNumber }}</span>
                    <div class="contents xl:flex">
                        <div class="font-medium xl:font-normal xl:order-2">
                            <CommonLabel
                                v-if="orderArticleCount(order) > 1"
                                path="BWF/shop/shared/articlePlural"
                            ></CommonLabel>
                            <CommonLabel v-else path="BWF/shop/shared/articleSingular"></CommonLabel>
                        </div>
                        <div>{{ orderArticleCount(order) }}</div>
                        <div class="hidden xl:block">&nbsp;</div>
                    </div>
                </div>
                <button
                    v-if="paymentCancelled && containsSubscriptionProduct"
                    class="hidden stretched-link xl:block text-link no-underline hover:underline col-start-5 place-self-start justify-self-end"
                    @click="gotoSubscriptions()"
                >
                    <CommonLabel path="BWF/shop/account/orderHistory/finalizePayment"></CommonLabel>
                </button>
                <button
                    v-else-if="paymentCancelled"
                    class="hidden stretched-link xl:block text-link no-underline hover:underline col-start-5 place-self-start justify-self-end"
                    @click="finalizePayment()"
                >
                    <CommonLabel path="BWF/shop/account/orderHistory/finalizePayment"></CommonLabel>
                </button>
                <button
                    v-else
                    class="hidden stretched-link xl:block text-link no-underline hover:underline col-start-5 place-self-start justify-self-end"
                    @click="gotoOrderDetails()"
                >
                    <CommonLabel path="BWF/shop/account/orderHistory/orderDetails"></CommonLabel>
                </button>
                <hr class="hidden xl:block col-span-full" />
                <div class="hidden xl:flex flex-col items-start gap-y-1 gap-2">
                    <span class="font-medium"
                        ><CommonLabel path="BWF/shop/account/orderHistory/dateOfOrder"></CommonLabel
                    ></span>
                    <span class="">{{ orderDate }}</span>
                </div>
                <div class="grid grid-cols-2 xl:flex flex-col items-start gap-2">
                    <span class="font-medium"
                        ><CommonLabel path="BWF/shop/account/orderHistory/status"></CommonLabel
                    ></span>
                    <span class="">{{ order.deliveries?.[0]?.stateMachineState.name }}</span>
                </div>
                <div class="hidden xl:flex flex-col items-start gap-2">
                    <span class="font-medium"
                        ><CommonLabel path="BWF/shop/account/orderHistory/paymentStatus"></CommonLabel
                    ></span>
                    <span :class="{ 'text-signal-red': paymentCancelled }">{{
                        order.transactions?.[0]?.stateMachineState?.name
                    }}</span>
                </div>
                <div class="hidden xl:flex flex-col items-start gap-2">
                    <span class="font-medium">
                        <CommonLabel path="BWF/shop/account/orderHistory/paymentMethod"></CommonLabel>
                    </span>
                    <span class="">{{ order.transactions?.[0]?.paymentMethod?.name }}</span>
                </div>
                <div class="hidden xl:flex flex-col items-start gap-2">
                    <span class="font-medium">
                        <CommonLabel path="BWF/shop/account/orderHistory/shippingMethod"></CommonLabel>
                    </span>
                    <span class="">{{ order.deliveries?.[0]?.shippingMethod?.name }}</span>
                </div>
            </div>

            <button
                v-if="!paymentCancelled"
                class="w-6 h-6 flex self-center flex-shrink-0 items-center justify-center stretched-link xl:hidden"
                @click="gotoOrderDetails()"
            >
                <SvgIcon class="h-4 w-4 -rotate-90" name="Arrow-Down"></SvgIcon>
            </button>
        </div>
        <button v-if="paymentCancelled" class="btn btn-blue xl:hidden" @click="finalizePayment()">
            <CommonLabel path="BWF/shop/account/orderHistory/finalizePayment"></CommonLabel>
        </button>
    </div>
</template>

<script setup lang="ts">
import type { Order } from '@shopware-pwa/types';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import AccountOrderStateLabel from '~/templates/components/shop/account/AccountOrderStateLabel.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import OrderProductImage from '~/templates/components/shop/account/OrderProductImage.vue';
import { hasFailedPayment, ORDER_DETAILS_ASSOCIATIONS, orderArticleCount } from '~/utils/helper/shop/order';

const { push } = useRouter();

const props = defineProps<{
    order: Order;
}>();

const { order: refreshedOrder } = useOrderDetails(props.order.id, ORDER_DETAILS_ASSOCIATIONS);

const { marketSetupAdmin, marketSetupLinks } = useMarketSetup();

const gotoOrderDetails = async () => {
    await push(`${marketSetupAdmin.value?.myBritaOrderDetailsPage}/${props.order.id}`);
};

const gotoSubscriptions = async () => {
    await push(`${marketSetupAdmin.value?.myBritaSubscriptionPage}`);
};
const finalizePayment = async () => {
    await navigateTo(`${marketSetupLinks.value?.orderPaymentPage}/${props.order.id}`, { external: true });
};

const order = computed(() => {
    return refreshedOrder.value ?? props.order;
});

const paymentCancelled = computed(() => hasFailedPayment(order.value));

const orderDate = computed(() =>
    new Date(order?.value?.orderDate).toLocaleDateString(
        (typeof navigator !== 'undefined' && navigator.language) || 'en-US'
    )
);

const containsSubscriptionProduct = computed(
    () => !!order.value.lineItems?.find(lineItem => lineItem?.children?.[0]?.payload?.hasOwnProperty('contractPlan'))
);
</script>

<script lang="ts">
export default {
    name: 'AccountOrder',
};
</script>
