import { useOrderDetails as coreOrderDetails, type UseOrderDetailsReturn } from '@shopware-pwa/composables-next';
import type { ShopwareAssociation } from '@shopware-pwa/types';

export type UseBwfOrderDetailsReturn = UseOrderDetailsReturn & {
    changePaymentMethod(paymentMethodId: string, customer_email: string): Promise<void>;
};
export function useOrderDetails(orderId: string, associations?: ShopwareAssociation): UseBwfOrderDetailsReturn {
    const coreDetails = coreOrderDetails(orderId, associations);
    const { apiInstance } = useShopwareContext();

    async function changePaymentMethod(paymentMethodId: string, customer_email: string) {
        await apiInstance.invoke.post(`/store-api/order/payment`, {
            orderId,
            paymentMethodId,
            customer_email,
        });

        await coreDetails.loadOrderDetails();
    }

    // @ts-ignore
    return { ...coreDetails, changePaymentMethod };
}
