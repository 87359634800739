<script setup lang="ts">
import { usePrice } from '~/composables/shop/usePrice';

const { getIntlFormattedPrice } = usePrice();
const props = defineProps<{
    hasAsterisk?: boolean;
    value: number | undefined;
}>();

const getPrice = computed<string>(() => getIntlFormattedPrice(props.value));
</script>

<template>
    <p class="flex gap-1">
        <slot name="beforePrice" />
        <span>{{ getPrice }}<span v-if="hasAsterisk" class="text-black font-normal pl-0.5">*</span></span>
        <slot name="afterPrice" />
    </p>
</template>
