<template>
    <div class="order-grid hyphens-auto">
        <div v-if="!isEshopSet" class="row-span-4 col-start-1">
            <OrderProductImage v-if="!isEshopSet" :line-item="lineItem"></OrderProductImage>
        </div>

        <span class="text-base xl:mb-2" :class="{ 'col-span-2': isEshopSet }">
            {{ lineItem.label }}
        </span>

        <template v-if="!isSetChild">
            <SelectField
                v-if="quantityChange"
                v-model="selectedQuantity"
                :readonly="!orderConfiguration[lineItem.id].selected"
                :disabled="!orderConfiguration[lineItem.id].selected"
                class="self-start"
                :options="quantityOptions"
            ></SelectField>
            <div v-else class="xl:text-center col-start-2 row-start-3 xl:col-start-auto xl:row-start-auto">
                <span class="xl:hidden">x</span>
                <span v-if="returnItem">
                    {{ returnItem?.quantity }}
                </span>
                <span v-else-if="isConfigured && orderConfiguration[lineItem.id]?.quantity">
                    {{ orderConfiguration[lineItem.id]?.quantity }}
                </span>
                <span v-else>
                    {{ lineItem.quantity }}
                </span>
            </div>
        </template>

        <div class="product-options text-sm text-medium-grey col-span-2 xl:col-span-3">
            <div v-if="!isEshopSet" class="text-sm text-medium-grey">
                <CommonLabel path="/BWF/shop/account/orderHistory/itemNumber"></CommonLabel><span>:&nbsp;</span>

                <span>
                    {{
                        //@ts-ignore
                        lineItem?.payload?.productNumber ?? lineItem?.children?.[0]?.payload?.productNumber
                    }}
                </span>
            </div>

            <template v-if="itemOptions.length > 0">
                <template v-for="option in itemOptions" :key="option.group">
                    <span class="">{{ option.group }}:&nbsp;</span>
                    <span>
                        {{
                            //@ts-ignore
                            (option as PropertyGroupOptionCart).option
                        }}
                    </span>
                </template>
            </template>
        </div>
        <div v-if="!isSetChild" class="justify-self-end row-start-1 col-start-3 xl:col-start-4">
            <SharedPrice
                v-if="isConfigured && !isSubscriptionProduct"
                :value="orderConfiguration[lineItem.id]?.quantity * lineItem.unitPrice"
                class="font-medium"
                has-asterisk
            />
            <SharedPrice
                v-else-if="!isSubscriptionProduct && (returnItem?.refundAmount || totalPriceWithoutPromo)"
                :value="returnItem?.refundAmount || totalPriceWithoutPromo"
                class="font-medium"
                has-asterisk
            />
        </div>
        <template v-if="isSubscriptionProduct">
            <OrderLineItemExchangeBoxChildren :line-item="lineItem"></OrderLineItemExchangeBoxChildren>
            <div class="notification is-blue col-span-full gap-4 mt-4 mb-0">
                <SvgIcon name="Tooltip" class="notification-icon"></SvgIcon>
                <CommonLabel
                    class="prose-black"
                    path="BWF/shop/account/orderDetails/exchangeBox/exchangeBoxLink"
                ></CommonLabel>
            </div>
        </template>
        <div
            v-if="isEshopSet && (lineItem?.children?.length ?? 0) > 0"
            class="col-span-full grid gap-2 mt-4"
            :class="{ 'col-start-2': !isEshopSet }"
        >
            <!--            SET ITEM CHILDREN-->
            <template v-for="(childItem, index) of lineItem.children" :key="childItem.id">
                <OrderLineItem v-if="!isChildHidden(index)" :line-item="childItem" :is-set-child="true"></OrderLineItem>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { OrderLineItem as OrderLineItemType, Product } from '@shopware-pwa/types';
import SharedPrice from '~/templates/components/shop/shared/SharedPrice.vue';
import type { PropertyGroupOptionCart } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import SelectField from '~/templates/elements/form/SelectField.vue';
import { useOrderSelection } from '~/composables/shop/useOrderSelection';
import OrderProductImage from '~/templates/components/shop/account/OrderProductImage.vue';
import OrderLineItemExchangeBoxChildren from '~/templates/components/shop/account/OrderLineItemExchangeBoxChildren.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';

const props = defineProps<{
    lineItem: OrderLineItemType;
    returnItem?: never;
    quantityChange?: boolean;
    isSetChild?: boolean;
    isConfigured?: boolean;
}>();

const { orderConfiguration } = useOrderSelection();

const isEshopSet = computed(
    () =>
        // @ts-ignore
        (props.lineItem?.extensions?.hasOwnProperty('eshopSetData') ||
            props.lineItem?.customFields?.hasOwnProperty('eshopSetData')) ??
        false
);

const totalPriceWithoutPromo = computed(
    () => props.lineItem?.payload?.promotionDetails?.originalTotalPrice ?? props.lineItem?.totalPrice
);

const isSubscriptionProduct = computed(() => props.lineItem?.children?.[0]?.payload?.hasOwnProperty('contractPlan'));

const itemOptions = computed(
    () => (props.lineItem.type === 'product' && (props.lineItem?.payload as Product)?.options) || []
);

const maxQty = ref(props.lineItem.quantity ?? 1);
const selectedQuantity: Ref<number> = ref(props.lineItem.quantity ?? '1');

/*
 * checks wether a child is the first item AND should be hidden
 * OR
 * ist a hidden Item/ SKU
 * */
const isChildHidden = (index: number) => {
    const child = props.lineItem?.children?.[index];
    return !!(
        ((index === 0 && (props.lineItem?.extensions?.brita_eshop_set_hide_main_product_image?.value ?? false)) ||
            child?.customFields?.eshopSet?.eshopSetProductIsHidden) ??
        false
    );
};

watch(selectedQuantity, () => {
    // update selected quatity while making sure it is an integer
    orderConfiguration.value[props.lineItem?.id].quantity = parseInt(selectedQuantity.value);
});

const quantityOptions = computed(() => {
    const options: never[] = [];
    for (let i = 1; i <= maxQty.value; i++) {
        options.push({ value: i });
    }
    return options;
});
</script>
