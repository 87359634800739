<template>
    <div
        class="relative rounded-2xl p-4 bg-light-blue hover:bg-medium-blue transition-colors duration-300 flex items-start gap-4"
    >
        <div class="flex flex-col gap-1 min-h-[6rem]">
            <LinkComponent class="text-black font-medium text-sm stretched-link" :link="link">
                {{ link?.label }}
            </LinkComponent>
            <div class="text-sm leading-snug font-normal">
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script>
import LinkComponent from '../../components/cms/LinkComponent';

export default {
    name: 'ProductOverviewLink',
    components: { LinkComponent },
    props: ['text', 'link'],
};
</script>
