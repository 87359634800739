<template>
    <div class="image-teaser-component">
        <HeadlineComponent
            class="text-2xl xl:text-3xl text-dark-blue mb-8"
            :headline="headline"
            tag="h2"
        ></HeadlineComponent>
        <EditableArea :content="main" :custom-view="ImageTeaserComponentArea" />
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import ImageTeaserComponentArea from './ImageTeaserComponentArea';
import HeadlineComponent from './HeadlineComponent';

const props = defineProps(['main', 'headline', 'ratio']);

provide('ratio', props?.ratio);
</script>
