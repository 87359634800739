<template>
    <div
        v-if="components.length > 0"
        ref="swiperElement"
        class="swiper-element relative"
        :class="{
            'has-animation': revealAnimation,
            'animation-end': revealAnimationEnd,
            'is-edit-mode': isEditMode,
            'has-pagination': pagination,
        }"
    >
        <NuxtErrorBoundary>
            <Swiper
                class="slider-component"
                :modules="[Autoplay, Pagination, Navigation, Controller]"
                :autoplay="interval"
                :enabled="!isEditMode"
                :initial-slide="revealAnimation ? components.length : 0"
                :pagination="pagination && { clickable: true }"
                :space-between="swiperOptions?.spaceBetween"
                :slides-per-view="swiperOptions?.slidesPerView"
                :centered-slides="swiperOptions?.centeredSlides"
                :loop="swiperOptions?.loop"
                :breakpoints="swiperOptions?.breakpoints"
                @swiper="onSwiper"
                @slide-change="onSlideChange"
                @slide-change-transition-end="onSlideEnd"
            >
                <SwiperSlide v-for="(childContent, index) in components" :key="childContent['@id']">
                    <EditableComponent :content="childContent" :index="parseInt(index)" />
                </SwiperSlide>
                <slot></slot>
            </Swiper>
        </NuxtErrorBoundary>
        <template v-if="!disableButtons">
            <div
                v-if="!isTabletOrMobile"
                class="swiper-btn btn btn-blue btn-circle absolute right-0 container:right-4 -translate-y-1/2 top-1/2 z-10 h-16 w-16"
                :class="{
                    'swiper-btn-disabled': (!swiper || swiper?.isEnd || (isTabletOrMobile && activeIndex > 0)) ?? true,
                }"
                @click="onClickNext"
            >
                <SvgIcon name="icon-arrow-long-right" class="w-6 h-6"></SvgIcon>
            </div>
            <div
                v-if="!isTabletOrMobile"
                class="swiper-btn btn btn-blue btn-circle absolute left-0 container:left-4 -translate-y-1/2 top-1/2 z-10 h-16 w-16"
                :class="{ 'swiper-btn-disabled': (!swiper || swiper?.isBeginning) ?? true }"
                @click="onClickPrev"
            >
                <SvgIcon name="icon-arrow-long-right" class="w-6 h-6 rotate-180"></SvgIcon>
            </div>
        </template>
    </div>
</template>

<script setup>
import useNavigationTracking from '~/composables/tracking/useNavigationTracking';
import { EditableComponent } from '@magnolia/vue-editor';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Pagination, Navigation, Controller } from 'swiper/modules';
import SvgIcon from '../elements/SvgIcon';
const { trackSwipeContent } = useNavigationTracking();
const { isTabletOrMobile } = useDevice();
import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
// Import Swiper styles

const VISIBLE_THRESHOLD = 0.5;

const props = defineProps([
    'components',
    'revealAnimation',
    'swiperOptions',
    'interval',
    'pagination',
    'disableButtons',
]);

const isEditMode = evaluateEditMode();

const swiper = ref(null);
const swiperElement = ref(null);
const activeIndex = ref(0);
const isVisible = ref(false);
const revealAnimationEnd = ref(!(props.revealAnimation ?? false));
// enable tracking after reveal animation finished
provide('trackingActive', revealAnimationEnd);
const onClickNext = () => {
    swiper.value?.slideNext();
};
const onClickPrev = () => {
    swiper.value?.slidePrev();
};
const onSwiper = swiperInstance => {
    swiper.value = swiperInstance;
};

const onSlideEnd = () => {
    if (isVisible.value && !revealAnimationEnd.value) {
        revealAnimationEnd.value = true;
    }
};
const onSlideChange = () => {
    activeIndex.value = swiper.value?.activeIndex;
    if (revealAnimationEnd.value) {
        trackSwipeContent();
    }
};

onMounted(() => {
    const { stop } = useIntersectionObserver(
        swiperElement,
        ([{ isIntersecting, intersectionRatio }]) => {
            if (isIntersecting && intersectionRatio >= VISIBLE_THRESHOLD) {
                stop();
                const duration = props.components.length * 300;
                isVisible.value = true;
                swiper.value?.slideTo(0, duration);
            } else if (!isIntersecting) {
                isVisible.value = false;
            }
        },
        { threshold: [0, VISIBLE_THRESHOLD] }
    );
});
</script>

<!--<style src="~/assets/css/thirdparty/swiper.css"></style>-->
<style>
@import url('~/assets/css/thirdparty/swiper.css');

:root {
    --swiper-theme-color: theme('colors.dark-blue');
}

.swiper-btn {
    @apply transition-opacity duration-700;
}

.has-animation {
    .swiper-btn {
        @apply opacity-0;
    }

    &.animation-end {
        .swiper-btn:not(.swiper-btn-disabled) {
            @apply opacity-100;
        }
    }
}

.btn.swiper-btn-disabled {
    @apply opacity-0 pointer-events-none;
}

.swiper-element .swiper {
    @apply overflow-visible;
}

.slider-component.swiper .swiper-pagination {
    @apply relative bottom-0 h-16 flex items-center gap-2  justify-center;
}

.no-container .slider-component .swiper-wrapper {
    .swiper-slide:first-child {
        margin-left: var(--container-page-offset);
    }

    .swiper-slide:last-child {
        margin-right: var(--container-page-offset);
    }
}

/**
    Disable slider in edit mode and make area scrollable
*/
.is-edit-mode .swiper-wrapper {
    @apply overflow-auto;
    transform: none !important;
}
</style>
