<template>
    <div class="stage-carousel-component" :class="{ container }">
        <div v-if="isEditMode" class="editmode-hint">[Stage Carousel Area]</div>
        <EditableArea :content="main" :custom-view="StageCarouselAreaComponent" />
    </div>
</template>
<script setup>
import { EditableArea } from '@magnolia/vue-editor';

import { isEditMode as evaluateEditMode } from '~/utils/content/magnolia';
import StageCarouselAreaComponent from './StageCarouselAreaComponent';

const isEditMode = evaluateEditMode();
defineProps(['container', 'main']);
</script>
