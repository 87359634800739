<template>
    <div class="grid grid-cols-2 text-sm xl:grid-cols-6 gap-1 xl:gap-x-2 hyphens-auto items-center">
        <div class="contents xl:flex flex-col items-start gap-y-1 gap-2">
            <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/dateOfOrder"> </CommonLabel>
            <span class="">{{ orderDate }}</span>
        </div>
        <div class="contents xl:flex flex-col items-start gap-2">
            <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/status"></CommonLabel>
            <span class="">{{ order.deliveries?.[0]?.stateMachineState?.name }}</span>
        </div>
        <div class="contents xl:flex flex-col items-start gap-2">
            <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/paymentStatus"> </CommonLabel>
            <span class="">{{ order?.transactions?.[0]?.stateMachineState?.name }}</span>
        </div>
        <div class="contents xl:flex flex-col items-start gap-2">
            <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/paymentMethod"> </CommonLabel>
            <span class="">{{ order?.transactions?.[0]?.paymentMethod?.name }}</span>
        </div>
        <div class="contents xl:flex flex-col items-start gap-2">
            <CommonLabel class="font-medium" path="BWF/shop/account/orderHistory/shippingMethod"> </CommonLabel>
            <span class="">{{ order?.deliveries?.[0]?.shippingMethod?.name }}</span>
        </div>
    </div>
    <hr class="mt-2 xl:my-0" />

    <div class="flex flex-col mt-2 gap-4">
        <div class="font-medium text-base xl:text-2xl">
            <CommonLabel path="BWF/shop/account/orderHistory/returnArticles"></CommonLabel>
        </div>
        <OrderLineItems :order="order" :selectable="order.lineItems?.length > 0" />
    </div>
</template>

<script setup>
import CommonLabel from '~/templates/components/CommonLabel.vue';
import OrderLineItems from '~/templates/components/shop/account/OrderLineItems.vue';

const props = defineProps(['order', 'selectable']);

const orderDate = computed(() => {
    return props.order?.orderDate
        ? new Date(props.order?.orderDate ?? '').toLocaleDateString(
              (typeof navigator !== 'undefined' && navigator.language) || 'de-DE'
          )
        : '';
});
</script>
