export default function ({ type, filters, activeFilters }) {
    const gtm = useGtm(); // auto-imported by the module
    let oldFilterState = new Set([]);

    watch(
        activeFilters,
        newFilters => {
            const newFilterState = new Set(newFilters);

            for (const filter of oldFilterState) {
                if (!newFilterState.has(filter)) {
                    trackFilterRemove(filter);
                }
            }

            for (const filter of newFilterState) {
                if (!oldFilterState.has(filter)) {
                    trackFilterSelect(filter);
                }
            }

            /**
             * From Vue Docs for watcher()
             *   [...] Note: `newValue` will be equal to `oldValue` here
             *   because they both point to the same object!
             *   Manual save oldValue of activeFilters.
             * */
            oldFilterState = new Set(newFilterState);
        },
        { deep: true }
    );

    const selectedFilterLabel = selectedFilterValue =>
        filters.find(category => category.value === selectedFilterValue).label || '';

    const activeFilterLabels = computed(() =>
        activeFilters.value
            .map(activeFilter => {
                const category = filters.find(cat => cat.value === activeFilter);
                return category.label;
            })
            .join(',')
    );

    const trackFilterSelect = selectedFilterValue => {
        try {
            gtm?.trackEvent({
                event: 'gx.filter_select',
                event_name: 'filter_select',
                event_source: 'source_code',
                filter_select: {
                    filter: activeFilterLabels.value,
                    term: selectedFilterLabel(selectedFilterValue),
                    type: type,
                },
            });
        } catch (e) {
            console.error('gx.filter_select', e);
        }
    };

    const trackFilterRemove = selectedFilterValue => {
        try {
            gtm?.trackEvent({
                event: 'gx.filter_remove',
                event_name: 'filter_remove',
                event_source: 'source_code',
                filter_remove: {
                    filter: activeFilterLabels.value,
                    term: selectedFilterLabel(selectedFilterValue),
                    type: type,
                },
            });
        } catch (e) {
            console.error('gx.filter_remove', e);
        }
    };

    return { trackFilterSelect, trackFilterRemove };
}
