<template>
    <div class="md:col-span-2">
        <LinkComponent :link="link">
            <div class="relative image-tile bg-light-grey rounded-xl px-5 h-full pb-6 pt-3">
                <div class="flex flex-col gap-1.5 h-full">
                    <PictureElement
                        :breakpoints="imageBreakpoints"
                        :img="image"
                        class="my-auto"
                        img-class="mx-auto object-contain w-auto"
                    />
                    <h3 class="relative text-center font-medium mt-auto text-sm xl:text-lg xl:mt-0">
                        {{ headline }}
                    </h3>
                </div>
            </div>
        </LinkComponent>
    </div>
</template>

<script setup>
import PictureElement from '~/templates/elements/PictureElement.vue';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';
import { DIMENSIONS_FIXED } from '~/utils/helper/imaging';
defineProps(['headline', 'image', 'link']);
const imageBreakpoints = {
    start: DIMENSIONS_FIXED['200x200'],
};
</script>
