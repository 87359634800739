<template>
    <button @click="product.available ? addToCartProxy() : pushError(addToCartAvailabilityError)">
        <slot>
            <template v-if="product.available">
                <svg-icon
                    name="Checkmark-1"
                    class="text-dark-blue transition-all"
                    :class="[addedToCart ? 'w-4 h-4' : 'w-0 h-0']"
                ></svg-icon>
                <span v-if="addedToCart"> <CommonLabel path="BWF/shop/addToCart/itemAdded"></CommonLabel></span>
                <span v-else><CommonLabel path="BWF/shop/addToCart/addToCart"></CommonLabel></span>
            </template>
            <span v-else><CommonLabel path="BWF/shop/addToCart/notifyMe"></CommonLabel></span>
        </slot>
    </button>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import { useProductEShopSetConfigurator } from '~/composables/shop/useProductEShopSetConfigurator';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import { useCartInformation } from '~/composables/shop/useCartInformation';
import useItemTracking from '~/composables/tracking/useItemTracking';
import useShopwareNotifications from '~/composables/useShopwareNotifications';
import { useProductSubscriptionConfigurator } from '~/composables/shop/useProductSubscriptionConfigurator';
const props = defineProps(['productQuantity']);

const { getLabels } = useCommonLabels();

const { addToCartAvailabilityError, addedToCartPush } = getLabels('BWF/shop/addToCart', [
    'addedToCartPush',
    'addToCartAvailabilityError',
]);

const { pushSuccess, pushError } = useNotifications();

const { product } = useProduct();

const { addToCart, quantity } = useAddToCart(product);
const { count } = useCartInformation();

const addedToCart = ref(false);
const { hasEShopSet, addEShopSetToCart } = await useProductEShopSetConfigurator();
const { pushShopwareCartErrors } = useShopwareNotifications();

const { subscriptionChooserActive, addSubscriptionToCart } = useProductSubscriptionConfigurator(product);

const timeout = null;

const { trackItemAddToCart } = useItemTracking({
    item: product,
    addToCartQuantity: props.productQuantity ?? quantity.value ?? 1,
});
const addToCartProxy = async () => {
    if (props?.productQuantity) quantity.value = parseInt(props?.productQuantity);
    const beforeCount = count.value;
    const cart = hasEShopSet.value
        ? await addEShopSetToCart()
        : subscriptionChooserActive.value
          ? await addSubscriptionToCart()
          : await addToCart();

    let reachedStock = Object.values(cart?.errors ?? {}).includes(
        error => error.messageKey === 'product-stock-reached'
    );

    if (cart?.errors) {
        pushShopwareCartErrors(cart?.errors);
    }

    if (!reachedStock && count.value !== beforeCount) {
        pushSuccess(`${product.value?.translated?.name} ${addedToCartPush}`);
        clearTimeout(timeout);
        addedToCart.value = true;
        setTimeout(() => {
            addedToCart.value = false;
        }, 5000);
        trackItemAddToCart();
    }
};
</script>
