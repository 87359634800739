<template>
    <div>
        <div class="p-4 pt-6 pb-8 mt-10 bg-light-grey rounded-2xl reset-container-padding lg:mx-0 lg:p-6">
            <div class="lg:w-3/4">
                <RadioField v-model="subscriptionChooserActive" class="mb-8" :value="false">
                    <div class="flex flex-row font-medium text-base">
                        <CommonLabel
                            path="BWF/shop/productDetailPage/subscriptionProduct/singlePurchaseLabel"
                        ></CommonLabel
                        >&nbsp;<SharedPrice class="ml-auto" :value="unitPrice" />
                    </div>
                </RadioField>
                <!--            <RevealComponent :open="!subscriptionChooserActive">-->
                <!--                <CommonLabel-->
                <!--                    class="font-medium text-base"-->
                <!--                    path="BWF/shop/productDetailPage/subscriptionProduct/packSizeLabel"-->
                <!--                ></CommonLabel>-->
                <!--                <br />-->
                <!--                <CommonLabel-->
                <!--                    class="font-medium text-base"-->
                <!--                    path="BWF/shop/productDetailPage/subscriptionProduct/packSizeLabel"-->
                <!--                ></CommonLabel>-->
                <!--            </RevealComponent>-->
                <div v-if="!subscriptionChooserActive">
                    <CommonLabel
                        class="font-medium text-base"
                        path="BWF/shop/productDetailPage/subscriptionProduct/packSizeLabel"
                    ></CommonLabel>

                    <ProductVariantConfigurator
                        class="mt-6"
                        :show-option-name="false"
                        :option-button-default="optionButtonDefault"
                        @before-change="beforeChange"
                        @change="change"
                    />
                </div>
            </div>

            <div class="relative p-8 pt-7 bg-white rounded-2xl mt-10 space-y-6">
                <div class="absolute badge is-highlight-green right-8 top-0 -translate-y-1/2 shrink-0 lg:is-large">
                    <CommonLabel path="BWF/shop/productDetailPage/subscriptionProduct/savingsBadgeLabel" /><span
                        >&nbsp;{{ activeSubscriptionOption?.subscriptionPrice?.displayDiscountRate + '%' }}</span
                    >
                </div>

                <CommonLabel
                    class="font-medium text-base"
                    path="BWF/shop/productDetailPage/subscriptionProduct/recommendationLabel"
                ></CommonLabel>
                <RadioField v-model="subscriptionChooserActive" :value="true">
                    <div class="flex flex-row font-medium text-base">
                        <CommonLabel
                            path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionLabel"
                        ></CommonLabel>
                        <SharedPrice
                            class="ml-auto text-medium-grey line-through font-medium"
                            :value="subscriptionListPrice"
                        />&nbsp;<SharedPrice :value="subscriptionTotalPrice" />
                    </div>
                </RadioField>
                <div v-if="subscriptionChooserActive">
                    <div class="mt-8">
                        <SelectField
                            v-model="activeSubscriptionIntervalId"
                            :label="subscriptionIntervalLabel"
                            :options="subscriptionChooserOptions"
                        ></SelectField>
                    </div>
                </div>
                <p>{{ activeSubscriptionOption?.description }}</p>
                <button
                    v-if="hasSubscriptionModalArea"
                    class="mt-6 text-link text-black text-start"
                    @click="subscriptionModal.open()"
                >
                    <CommonLabel
                        class="font-medium underline"
                        path="BWF/shop/productDetailPage/subscriptionProduct/subscriptionModalLinkLabel"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import RadioField from '~/templates/elements/form/RadioField.vue';
import { useProductSubscriptionConfigurator } from '~/composables/shop/useProductSubscriptionConfigurator';
import SelectField from '~/templates/elements/form/SelectField.vue';

import type { Product } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import RevealComponent from '~/templates/elements/RevealComponent.vue';
defineProps(['optionButtonDefault']);
const { product } = useProduct();
const { unitPrice } = useProductPrice(product);
const {
    subscriptionChooserOptions,
    subscriptionChooserActive,
    activeSubscriptionOption,
    activeSubscriptionIntervalId,
    subscriptionTotalPrice,
    subscriptionListPrice,
} = useProductSubscriptionConfigurator(product);

const { getLabelByPath } = useCommonLabels();
const subscriptionIntervalLabel = getLabelByPath(
    'BWF/shop/productDetailPage/subscriptionProduct/subscriptionIntervalDropdownLabel'
);
const subscriptionModal = inject('subscriptionModal', useModal());
const hasSubscriptionModalArea = inject('hasSubscriptionModalArea');
const emit = defineEmits<{
    (e: 'beforeChange'): void;
    (e: 'change', changedProduct: Partial<Product>): void;
}>();

watch(activeSubscriptionIntervalId, () => {
    emit('beforeChange');
    let changedSubscriptionProductId = product.value?.extensions?.subscription?.intervalConfigurations?.find(
        (configurationToLoad: SubscriptionConfiguration) =>
            configurationToLoad.intervalConfigurationId === activeSubscriptionIntervalId.value
    ).productId;
    emit('change', { id: changedSubscriptionProductId });
});

watch(
    [product, activeSubscriptionOption],
    () => {
        activeSubscriptionIntervalId.value = activeSubscriptionOption.value?.intervalConfigurationId;
    },
    { immediate: true }
);

const change = (changedProduct: Product) => {
    emit('change', changedProduct);
};
const beforeChange = () => {
    emit('beforeChange');
};
</script>
