<template>
    <div
        class="loading-dots inset-0 h-full w-full flex items-center justify-center"
        :class="{ 'absolute bg-black/40 z-10': overlay, relative: !overlay }"
    >
        <div class="relative aspect-square" :class="[small ? 'w-20' : 'w-20 md:w-32']">
            <img
                v-if="setupImagePath"
                loading="lazy"
                class="absolute inset-0 w-full h-full object-contain"
                :src="setupImagePath"
                alt="loading image .. "
            />
            <img
                v-else
                loading="lazy"
                class="absolute inset-0 w-full h-full object-contain"
                src="~/assets/img/BRITA_Preloader_CSS.svg"
                alt="loading default image.. "
            />
        </div>
    </div>
</template>

<script setup>
import { imagePath } from '~/utils/helper/magnolia';
const { marketSetup } = useMarketSetup();
defineProps(['small', 'overlay']);

const setupImagePath = imagePath(marketSetup.value?.loadingImage);
</script>
