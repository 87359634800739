<template>
    <div class="contact-box-tile bg-light-grey rounded-2xl p-6">
        <div v-if="headline.text || subline" class="border-b border-medium-grey flex flex-col gap-1.5 mb-6">
            <HeadlineComponent
                v-if="headline.text"
                class="text-lg text-dark-blue font-medium last:mb-6"
                :headline="headline"
            />

            <p v-if="subline" class="last:mb-6">{{ subline }}</p>
        </div>

        <div v-if="hasPhone || hasMail" class="flex flex-col gap-2">
            <LinkComponent
                v-if="hasPhone"
                class="text-icon-link text-black text-base gap-2"
                :link="contact['phone-number']"
            >
                <SvgIcon class="h-5 w-5" name="Phone" />
                <span class="underline">
                    {{ contact['phone-number']?.label ?? contact['phone-number']?.phone }}
                </span>
            </LinkComponent>

            <LinkComponent v-if="hasMail" class="text-icon-link text-black text-base gap-2" :link="contact?.email">
                <SvgIcon class="h-5 w-5" name="Mail" />
                <span class="underline">{{ contact?.email?.label ?? contact?.email?.email }}</span>
            </LinkComponent>
        </div>
        <p v-if="contact['additional-info'] && visible?.includes('additional-info')" class="text-sm mt-4 only:mt-0">
            {{ contact['additional-info'] }}
        </p>
    </div>
</template>
<script setup>
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';
import { hasValue } from '~/utils/helper/magnolia';

const props = defineProps(['headline', 'subline', 'contact', 'visible']);
const hasPhone = ref(
    hasValue(props?.contact['phone-number']?.type === 'phone') && props?.visible?.includes('phone-number')
);
const hasMail = ref(hasValue(props?.contact?.email?.type === 'mail') && props?.visible?.includes('email'));
</script>
