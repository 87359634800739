<template>
    <div>
        <LoadingElement
            v-if="!showActualContent && !isEditMode()"
            class="container col-span-full w-full aspect-3/4 lg:aspect-video"
        >
        </LoadingElement>
        <template v-else>
            <EditableArea v-if="main" :content="main"></EditableArea>

            <div class="container my-24">
                <MyBritaCheckInComponent class="md:w-3/4 xl:w-1/2 mx-auto"></MyBritaCheckInComponent>
            </div>

            <EditableArea v-if="bottom" :content="bottom" />
        </template>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import { isEditMode } from '~/utils/content/magnolia';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import MyBritaCheckInComponent from '~/templates/components/account/MyBritaCheckInComponent.vue';

defineOptions({
    inheritAttrs: false,
});
defineProps(['main', 'bottom']);

const { marketSetupLinks } = useMarketSetup();
const { verifyLogIn } = useMyBritaUser();
const showActualContent = ref(false);

onMounted(async () => {
    try {
        if (isEditMode()) {
            // skip verify and login in editmode
            showActualContent.value = true;
            return;
        }
        await verifyLogIn();
        await navigateTo(marketSetupLinks.value?.myBritaOverviewPage);
    } catch (e) {
        showActualContent.value = true;
    }
});
</script>
