<template>
    <SliderElement
        v-if="slider || (isMobile && mobileSlider)"
        class="multiple-image-text-slider"
        :components="mapNodes(content)"
        :swiper-options="swiperOptions"
    ></SliderElement>
    <div
        v-else
        class="grid responsive-gap-x gap-y-8"
        :class="{
            'grid-cols-1 md:grid-cols-2': columns === 2,
            'grid-cols-1 md:grid-cols-3': columns === 3,
            'grid-cols-1 md:grid-cols-4': columns === 4,
            'grid-cols-2 md:grid-cols-6': columns === 6,
        }"
    >
        <EditableComponent
            v-for="childContent in mapNodes(content)"
            :key="childContent['@id']"
            :content="childContent"
        />
    </div>
</template>

<script setup>
import { EditableComponent } from '@magnolia/vue-editor';
import SliderElement from '~/templates/elements/SliderElement.vue';
import { mapNodes } from '~/utils/helper/magnolia';
import { inject } from 'vue';
import { screens } from '~/tailwind.config.screens';
const columns = inject('columns');
const slider = inject('slider');
const mobileSlider = inject('mobileSlider');
const { isMobile } = useDevice();

const swiperOptions = {
    slidesPerView: columns > 5 ? 2 : 1.2,
    spaceBetween: 8,
    centeredSlides: false,
    loop: false,
    breakpoints: {
        [parseInt(screens.md)]: {
            slidesPerView: columns,
            spaceBetween: 16,
        },
        [parseInt(screens.xl)]: {
            slidesPerView: columns,
            spaceBetween: 24,
        },
    },
};

const mobileMaxWidth = columns > 5 ? '50%' : '84%';
const tabletMaxWidth = `${(12 / columns / 12) * 100}%`;
</script>

<script>
export default {
    name: 'MultipleImageTextArea',
    props: ['content'],
};
</script>

<style>
.multiple-image-text-slider .swiper-slide {
    @apply h-auto;
    max-width: calc(v-bind(mobileMaxWidth) + 8px);
    @screen md {
        max-width: calc(v-bind(tabletMaxWidth) + 16px);
    }
}
</style>
