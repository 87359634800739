<template>
    <dl class="space-y-1.5 lg:space-y-2">
        <div v-for="shopBenefit in shopBenefits" :key="shopBenefit.label" class="flex gap-7 lg:gap-6 items-center">
            <dt>
                <img
                    v-if="containsImage(shopBenefit.image)"
                    class="h-4 w-4 lg:h-5 lg:w-5"
                    :src="imagePath(shopBenefit.image)"
                    :alt="imageName(shopBenefit.image)"
                    aria-hidden="true"
                />
                <span class="sr-only">{{ shopBenefit.label?.value }}</span>
            </dt>
            <dd class="font-normal text-sm text-black">{{ shopBenefit.label?.value ?? 'Shop Benefit*' }}</dd>
        </div>
    </dl>
</template>
<script setup lang="ts">
import { imageName, imagePath, mapNodes, containsImage } from '~/utils/helper/magnolia';

const props = defineProps(['benefits']);

const { marketSetup } = useMarketSetup();
const shopBenefits = mapNodes(props.benefits ?? marketSetup.value?.shopBenefits);
</script>
