<template>
    <div v-if="cols?.length > 0" class="list-grid" role="list">
        <template v-for="(col, index) in cols" :key="`col-${index}`">
            <div v-if="index < 4" role="listitem">
                <PictureElement
                    v-if="containsImage(col.image)"
                    :img="col.image"
                    :breakpoints="BREAKPOINTS"
                    img-class="hidden xl:block aspect-3/2 rounded-2xl w-full mb-4"
                >
                </PictureElement>
                <div class="text-base xl:text-lg font-medium mb-2 xl:mb-4">
                    <LinkComponent
                        :link="col?.groupLink"
                        optional-link-classes="transition-colors hover:text-deep-blue"
                    >
                        {{ col.title }}
                    </LinkComponent>
                </div>
                <ul class="flex flex-col gap-2 xl:gap-4 text-base">
                    <li v-for="link in filterNodesByType(mapNodes(col), 'nav:link')" :key="link?.link?.label">
                        <LinkComponent
                            :link="link?.link"
                            optional-link-classes="transition-colors hover:text-deep-blue"
                        >
                            {{ link?.link?.label }}
                        </LinkComponent>
                    </li>
                </ul>
            </div>
        </template>
        <MenuTeaser
            v-if="cols.length < 4 && level.teaserHeadline"
            role="listitem"
            class="menu-teaser col-span-full xl:col-start-4 xl:col-end-4"
            :headline="level.teaserHeadline"
            :subline="level.teaserSubline"
            :link="level.teaserLink"
            :img="level.image"
        ></MenuTeaser>
    </div>
</template>

<script setup>
import { containsImage, filterNodesByType, mapNodes } from '~/utils/helper/magnolia';
import { DIMENSIONS } from '~/utils/helper/imaging';
import PictureElement from '../../elements/PictureElement';

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['3/2'],
    md: DIMENSIONS['md'].width['1/2'].ratio['3/2'],
};
</script>

<script>
import MenuTeaser from './MenuTeaser';
import LinkComponent from '../../components/cms/LinkComponent';
import { mapNodes, filterNodesByType, imagePath, imageName } from '~/utils/helper/magnolia';

export default {
    name: 'LevelTwoList',
    components: { MenuTeaser, LinkComponent },
    props: {
        level: Object,
    },
    computed: {
        cols() {
            return filterNodesByType(mapNodes(this.level), 'nav:linkgroup');
        },
    },
    methods: {
        imageName,
        imagePath,
        mapNodes,
        filterNodesByType,
    },
};
</script>

<style scoped>
.is-mobile .menu-teaser {
    @apply col-span-full xl:col-span-1;
    @apply my-8 xl:my-0;
}

.list-grid {
    @apply relative grid md:grid-cols-2 xl:grid-cols-4 gap-4;
}
</style>
