<template>
    <div v-for="paymentOption in paymentMethods" :key="paymentOption.id" class="col-span-full border p-4 rounded-lg">
        <RadioField v-model="selectedPaymentMethod" :value="paymentOption.id" name="payment-method">
            <div class="flex gap-2 w-full">
                <div class="flex flex-col gap-1">
                    {{ paymentOption.name }}
                    <div v-if="paymentOption.description" class="text-sm text-medium-grey">
                        {{ paymentOption.description }}
                    </div>
                </div>
                <img
                    v-if="paymentOption?.media?.url"
                    class="h-8 ml-auto"
                    :src="paymentOption.media?.url"
                    :alt="paymentOption.name"
                />
            </div>
        </RadioField>

        <div
            v-if="paymentOption.customFields?.mollie_payment_method_name === 'creditcard'"
            class="mt-4"
            :class="{ hidden: !creditCardSelected }"
        >
            <ShopwareFrontendsCreditCard ref="creditCardComponent" />
        </div>
    </div>
</template>

<script setup lang="ts">
import RadioField from '~/templates/elements/form/RadioField.vue';
import ShopwareFrontendsCreditCard from '~/templates/components/shop/checkout/ShopwareFrontendsCreditCard.vue';
import type { PaymentMethod } from '@shopware-pwa/types';
const props = defineProps(['paymentMethods', 'selectedMethod']);
const emit = defineEmits(['change']);

const creditCardComponent = ref(null);

const selectedPaymentMethod = defineModel('selectedPaymentMethod');

const selectedOption = computed(() => {
    return props.paymentMethods?.find((method: PaymentMethod) => method.id === selectedPaymentMethod.value);
});

const creditCardSelected = computed(() => {
    return selectedOption.value?.customFields?.mollie_payment_method_name === 'creditcard';
});

watch(selectedPaymentMethod, () => {
    emit('change', selectedPaymentMethod.value);
});

const validate = async () => {
    let valid = !!selectedPaymentMethod.value;
    if (creditCardSelected.value && creditCardComponent.value?.[0]) {
        const token = await creditCardComponent.value?.[0]?.checkFieldsAndSubmitToken?.();
        valid = !!token;
    }

    return valid;
};

defineExpose({ validate });
</script>
