import type { Customer } from '@shopware-pwa/types';

const AZURE_DEFAULT_CUSTOMER_GROUP_ID = '2a5fb36d75eb4dbabb7690dab22d7dac';

/**
 * Whenever a new user is logged in at shopware he will be added tu a certain "dummy" user group with a fixed id after the first registration as he is still "incomplete" from shopwares pov.
 * This method checks if the user is still in that group and can be used to determines if the user is able to access certain views / functionality of the app.
 * @param user
 */
export const isDummyUser = (user: Partial<Customer> | undefined) => {
    return user?.groupId === AZURE_DEFAULT_CUSTOMER_GROUP_ID;
};
