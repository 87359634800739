<template>
    <div>
        <EditableArea
            :content="main"
            class="flex flex-col gap-6 mg:gap-8"
            :class="
                contentToTailwindClasses({
                    flexItems: horizontal,
                })
            "
        />
    </div>
</template>

<script>
import { editableComponent } from '~/utils/mixins/editableComponent';
import { EditableArea } from '@magnolia/vue-editor';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';

export default {
    name: 'EditorialStorySlideComponent',
    components: { EditableArea },
    mixins: [editableComponent],
    inject: ['horizontal'],
    props: ['main'],
    methods: { contentToTailwindClasses },
};
</script>
