<template>
    <section class="flex flex-col border border-grey rounded-lg gap-6 px-4 py-6 xl:px-6">
        <HeadlineComponent class="pb-4 font-medium text-lg" :headline="content?.headline"></HeadlineComponent>

        <p>
            {{ content?.text }}
        </p>

        <button class="btn btn-ghost btn-size-sm md:self-start" @click="emit('delete-account')">
            {{ content?.buttonLabel ?? '' }}
        </button>
    </section>
</template>
<script setup>
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
const emit = defineEmits(['delete-account']);

defineProps(['content']);
</script>
