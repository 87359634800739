import { promiseTimeout } from '@vueuse/core';

const DURATION = 60000;
export default function () {
    const gtm = useGtm();
    const staysSixtySeconds = ref(false);

    const trackArticleView = title => {
        try {
            gtm.trackEvent({
                event: 'gx.article_view',
                event_name: 'article_view',
                event_source: 'source_code',
                article_view: {
                    name: title,
                },
            });
        } catch (e) {
            console.error('gx.article_view', e);
        }
    };

    const trackArticleFinishReading = title => {
        try {
            gtm?.trackEvent({
                event: 'gx.article_finish_reading',
                event_name: 'article_finish_reading',
                event_source: 'source_code',
                article_finish_reading: {
                    name: title,
                },
            });
        } catch (e) {
            console.error('gx.article_finish_reading', e);
        }
    };

    const trackArticleSelect = title => {
        try {
            const gtm = useGtm();

            gtm?.trackEvent({
                event: 'gx.article_select',
                event_name: 'article_select',
                event_source: ' source_code',
                article_select: {
                    name: title,
                },
            });
        } catch (e) {
            console.error('gx.article_select', e);
        }
    };

    function trackArticlePage(title) {
        const { scrolledToBottom } = useScrollTracking();
        watch([staysSixtySeconds, scrolledToBottom], () => {
            if (staysSixtySeconds.value && scrolledToBottom.value) {
                trackArticleFinishReading(title);
            }
        });
        onMounted(async () => {
            trackArticleView(title);
            await promiseTimeout(DURATION);
            staysSixtySeconds.value = true;
        });
    }
    return { trackArticlePage, trackArticleSelect };
}
