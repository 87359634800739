//
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
//
const scrollLock = {
    mounted(el, binding) {
        if (binding.value) {
            disableBodyScroll(el);
        }
    },

    updated(el, binding) {
        if (binding.value) {
            disableBodyScroll(el);
        } else {
            enableBodyScroll(el);
        }
    },

    // On unmount (removed)
    unmounted(el) {
        enableBodyScroll(el);
    },
};
export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.vueApp.directive('scroll-lock', scrollLock);
});
