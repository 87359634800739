<template>
    <Suspense>
        <ProductHero v-bind="props"></ProductHero>
        <template #fallback>
            <LoadingElement class="aspect-2/3 lg:aspect-video" />
        </template>
    </Suspense>
</template>
<script setup lang="ts">
import ProductHero from '~/templates/components/cms/ProductHero.vue';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
const props = defineProps(['sku', 'main', 'metadata']);
defineOptions({
    inheritAttrs: false,
});
</script>
