<template>
    <component :is="tag ?? headline?.tag ?? 'h4'" class="headline-component hyphens-auto">
        {{ text ?? headline?.text }}
        <slot></slot>
    </component>
</template>
<script>
export default {
    name: 'HeadlineComponent',
    props: {
        headline: Object,
        tag: String,
        text: String,
    },
};
</script>
