<template>
    <div
        class="relative newsletter-registration-component rounded-xl overflow-hidden"
        :class="[contentToTailwindClasses({ backgroundColor })]"
    >
        <DotPattern v-if="dotPattern" :variant="dotPattern"></DotPattern>
        <div
            class="relative grid gap-6 px-4 py-8 md:mx-auto md:w-10/12 md:gap-y-8 md:px-0 md:py-12 xl:grid-cols-5"
            :class="{
                'xl:px-12 md:px-16 xl:w-full': hasImage,
            }"
        >
            <PictureElement
                v-if="hasImage"
                :img="image"
                class="z-10 xl:col-span-2 xl:row-start-1 xl:col-start-4 xl:order-first"
                :class="{ 'order-last': !imageFirst && isTabletOrMobile }"
                img-class="object-cover aspect-4/3 rounded-xl overflow-hidden mx-auto"
            ></PictureElement>
            <div
                ref="contentGroup"
                class="content-group flex flex-col gap-6"
                :class="[hasImage && isDesktop ? 'col-span-3' : 'col-span-full']"
            >
                <TransitionGroup name="content" @enter="onEnter">
                    <div
                        v-if="(!isAlreadyRegistered && !isSubmitted) || isEditMode()"
                        class="editmode-info-box flex flex-col"
                    >
                        <div v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Registration Content:</div>
                        <HeadlineComponent
                            v-if="registrationHeadline?.text"
                            class="mb-4 text-dark-blue font-medium text-2xl xl:text-3xl"
                            :headline="registrationHeadline"
                        ></HeadlineComponent>
                        <p v-if="registrationSubline" class="text-base text-black mb-6">
                            {{ registrationSubline }}
                        </p>
                        <NewsletterBenefits v-if="hasNodes(benefits)" :benefits="benefits" />
                        <form @submit.prevent="onSubmitNewsletterRegistration()">
                            <InputField
                                v-model="newsletterFormState.email"
                                name="email"
                                type="email"
                                required="required"
                                :label="emailFormLabels?.emailAddressLabel"
                                :placeholder="emailFormLabels?.emailInputPlaceholder"
                                class="block w-full form-field-white mb-4 mt-2 md:w-96"
                                :class="{ hidden: !!props.email }"
                                :error-message="newsletterVuelidate?.email?.$errors[0]?.$message"
                                @blur="newsletterVuelidate?.email.$touch()"
                            />

                            <InputField
                                v-model="newsletterFormState.middlename"
                                name="middlename"
                                type="text"
                                label="middlename"
                                placeholder="middlename"
                                autocomplete="off"
                                class="form-field-umbrella"
                            />
                            <CheckboxField
                                v-model="newsletterFormState.consent"
                                name="consent"
                                class="text-sm"
                                :error-message="newsletterVuelidate?.consent?.$errors[0]?.$message"
                                @change="newsletterVuelidate.consent.$touch()"
                            >
                                <CommonLabel path="/BWF/components/newsletter/newsletterConsent"></CommonLabel>
                            </CheckboxField>
                            <button class="z-10 btn btn-blue mt-4 md:mt-6 md:self-start">
                                <CommonLabel path="BWF/components/newsletter/submit"></CommonLabel>
                            </button>
                        </form>
                    </div>

                    <div v-if="isEditMode() || isSubmitted" class="editmode-info-box flex flex-col">
                        <p v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Confirmation Content:</p>
                        <HeadlineComponent
                            v-if="confirmationHeadline?.text"
                            class="mb-4 text-dark-blue font-medium text-2xl xl:text-3xl"
                            :headline="confirmationHeadline"
                        ></HeadlineComponent>
                        <p v-if="confirmationSubline" class="text-base text-black mb-6">
                            {{ confirmationSubline }}
                        </p>
                        <NewsletterBenefits v-if="hasNodes(confirmationSteps)" :benefits="confirmationSteps" />
                    </div>

                    <div v-if="isEditMode() || isAlreadyRegistered" class="editmode-info-box flex flex-col">
                        <p v-if="isEditMode()" class="editmode-text">[EDITOR VIEW] Already Registered Content:</p>
                        <HeadlineComponent
                            v-if="informationHeadline?.text"
                            class="text-dark-blue mb-4 font-medium text-2xl xl:text-3xl"
                            :headline="informationHeadline"
                        ></HeadlineComponent>
                        <p v-if="informationSubline" class="text-base text-black mb-6">{{ informationSubline }}</p>
                        <NewsletterBenefits v-if="hasNodes(informationSteps)" :benefits="informationSteps" />
                    </div>
                </TransitionGroup>
            </div>
        </div>

        <Transition name="loading" mode="out-in">
            <LoadingElement v-if="isLoading" overlay="true" class="bg-white/70"></LoadingElement>
        </Transition>
    </div>
</template>

<script setup>
import HeadlineComponent from './HeadlineComponent';
import PictureElement from '~/templates/elements/PictureElement.vue';
import CheckboxField from '~/templates/elements/form/CheckboxField.vue';
import InputField from '~/templates/elements/form/InputField.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { containsImage, hasNodes } from '~/utils/helper/magnolia';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import DotPattern from '~/templates/elements/DotPattern.vue';
import { isEditMode } from '~/utils/content/magnolia';
import useBritaMiddleware from '~/composables/useBritaMiddleware';
import LoadingElement from '~/templates/elements/LoadingElement.vue';
import { useVuelidate } from '@vuelidate/core';
import NewsletterBenefits from '~/templates/components/shop/product/NewsletterBenefits.vue';

const props = defineProps([
    'backgroundColor',
    'registrationHeadline',
    'registrationSubline',
    'image',
    'imageFirst',
    'benefits',
    'confirmationHeadline',
    'confirmationSubline',
    'confirmationSteps',
    'informationHeadline',
    'informationSubline',
    'informationSteps',
    'dotPattern',
    'interactionId',
    'actionId',
    'skipDoi',
    'email',
]);

const { isDesktop, isTabletOrMobile } = useDevice();
const { user } = useUser();
const { register, getDoiState } = useBritaMiddleware();
const { pushError } = useNotifications();

const { getLabelByPath, getLabels } = useCommonLabels();

const hasImage = containsImage(props.image);

const newsletterFormState = reactive({
    email: props?.email ?? user?.value?.email ?? '',
    middlename: '',
    consent: null,
});

watch(
    () => props.email,
    () => {
        if (props?.email) {
            newsletterFormState.email = props?.email;
        }
    }
);

const contentMinHeight = ref('0px');

const { emailValidators, consentValidators, requiredValidator } = await useValidation();

const rules = computed(() => ({
    email: {
        ...requiredValidator,
        ...emailValidators,
    },
    consent: {
        ...consentValidators,
    },
}));
const newsletterVuelidate = useVuelidate(rules, newsletterFormState);

const isSubmitted = ref(false);
const isAlreadyRegistered = ref(false);
const isLoading = ref(false);

const emailFormLabels = getLabels('/BWF/shared/', ['emailAddressLabel', 'emailInputPlaceholder']);
const technicalErrorMessage = getLabelByPath('/BWF/newsletter/doi/technicalErrorMessage');

const contentGroup = ref();
onMounted(() => {
    contentMinHeight.value = `${contentGroup?.value?.offsetHeight}px`;
});
const onEnter = async el => {
    contentMinHeight.value = `${el.offsetHeight}px`;
};

const onSubmitNewsletterRegistration = async () => {
    isLoading.value = true;
    newsletterVuelidate.value.$touch();
    const valid = await newsletterVuelidate.value.$validate();
    if (valid) {
        try {
            const data = props?.skipDoi === true ? {} : await getDoiState(newsletterFormState.email);
            if (data?.donotbulkemail === false) {
                // user has already opted in
                isAlreadyRegistered.value = true;
                isLoading.value = false;
                return;
            }
            try {
                await register(
                    newsletterFormState?.email,
                    props.interactionId,
                    props.actionId,
                    newsletterFormState.middlename
                );
                isSubmitted.value = true;
            } catch (e) {
                if (registerError?.value?.statusCode === 406) {
                    // 406
                    isAlreadyRegistered.value = true;
                } else if (error?.value) {
                    pushError(technicalErrorMessage);
                } else {
                }
            }
        } catch (e) {
            pushError(technicalErrorMessage);
        }
    } else {
        pushError(technicalErrorMessage);
    }
    isLoading.value = false;
};
</script>

<style scoped>
.content-group {
    position: relative;
    @apply relative transition-all duration-1000;
    min-height: v-bind(contentMinHeight);
}
</style>

<style>
.newsletter-registration-component {
    /* TODO why is this a different than elsewhere*/

    .checkbox-border {
        @apply border-none rounded-none border-transparent;
    }

    .checkbox-border,
    .has-error {
        input {
            @apply bg-white rounded-sm;
        }
    }

    .editmode-text {
        @apply text-green font-bold text-xl border-b-2 mb-4;
    }

    .content-enter-active,
    .content-leave-active {
        @apply transition-all duration-700;
    }

    .content-enter-from,
    .content-leave-to {
        position: absolute;
        opacity: 0;
        transform: translateX(30px);
    }

    .loading-enter-active,
    .loading-leave-active {
        @apply transition-opacity duration-500;
    }

    .loading-enter-from,
    .loading-leave-to {
        opacity: 0;
    }
}
</style>
