<template>
    <div class="faq-group-component">
        <div class="xl:grid grid-cols-12 gap-4">
            <div class="col-span-3 hidden xl:block">
                <div class="sticky-container">
                    <nav class="prose hidden xl:block">
                        <CommonLabel path="BWF/components/faq-with-directory/directoryHeadline"></CommonLabel>

                        <ul class="mt-8">
                            <li v-for="faq in faqComponents" :key="getId(faq)" class="my-4">
                                <a :href="`#faq-navigation-${getId(faq)}`" class="text-link">{{ faq.headline }}</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>

            <div class="sticky-container xl:hidden py-2">
                <SelectField
                    v-model="selection"
                    :label="directoryHeadline"
                    class="select-field bg-light-grey xl:hidden mb-4"
                    name="warrantyReason"
                    :options="selectableLinks"
                    @change="onSelection"
                ></SelectField>
            </div>

            <EditableArea class="col-span-9" :content="main" :custom-view="FAQWithDirectoryArea"></EditableArea>
        </div>
    </div>
</template>

<script setup>
import { EditableArea } from '@magnolia/vue-editor';
import { filterNodesByTemplate, getId, mapNodes } from '~/utils/helper/magnolia';
import SelectField from '~/templates/elements/form/SelectField.vue';
import FAQWithDirectoryArea from '~/templates/components/cms/FAQWithDirectoryArea.vue';
import useCommonLabels from '~/composables/useCommonLabels';
import CommonLabel from '~/templates/components/CommonLabel.vue';

const { getLabelByPath } = useCommonLabels();

const props = defineProps(['main']);
const nodes = mapNodes(props.main) ?? [];
const directoryHeadline = getLabelByPath('BWF/components/faq-with-directory/directoryHeadline');

const faqComponents = filterNodesByTemplate(nodes, 'd2c-lm:components/faq');

const selectableLinks =
    faqComponents?.map(faq => ({
        value: getId(faq),
        label: faq?.headline,
    })) ?? [];

const selection = ref('');
const onSelection = () => {
    globalThis.location.hash = `faq-navigation-${selection.value}`;
};
</script>

<style scoped>
.sticky-container {
    @apply sticky transition-all duration-300;
    top: var(--sticky-header-height, 0);
    z-index: 5;
}

.select-field {
    filter: drop-shadow(0 0 1.5rem white);
}
</style>
