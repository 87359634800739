import { cleanFullPath, getCurrentLanguage } from '~/utils/content/magnolia';
import useMagnoliaSite from '~/composables/useMagnoliaSite';

const useMagnoliaLanguages = function () {
    const currentLanguage = useState('currentLanguage', () => '');
    const defaultLanguage = useState('defaultLanguage', () => '');
    const availableLanguages = useState('availableLanguages', () => []);

    const {
        public: { mgnlAuthor, mgnlLanguages },
    } = useRuntimeConfig();

    const getLanguagesFromAuthor = async function (fullPath) {
        const {
            public: { mgnlApi },
        } = useRuntimeConfig();
        const languageApiUrl = `${mgnlApi}/.rest/site/config/v1/languages${fullPath}`;
        const key = `mgnl-languages`;
        const { mgnlSiteHeaders } = useMagnoliaSite();
        const { data, error } = await useFetch(languageApiUrl, { key, headers: mgnlSiteHeaders });
        if (error.value) {
            throw createError({ statusCode: 412, statusMessage: 'No language in magnolia found' });
        }

        const defaultLocale = data.value?.defaultLocale;
        const locales = data.value?.locals;

        // sort by length to prefer locales with country's
        locales.sort((x, y) => {
            return y.length - x.length;
        });

        return [locales, defaultLocale];
    };

    const initLanguages = async (staticPath = '') => {
        const isOnAuthorInstance = !!mgnlAuthor;
        let languages = [];
        let mgnlDefaultLanguage = '';
        const fullPath = staticPath || cleanFullPath();
        if (isOnAuthorInstance) {
            if (fullPath.length > 1) {
                // const rootNode = useMagnoliaRootNode();
                // TODO consider sending only the first segment, rootnode is wrong in this case. this needs to request based for author
                [languages, mgnlDefaultLanguage] = await getLanguagesFromAuthor(fullPath);
            }
        } else {
            languages = mgnlLanguages.split(' ');
            mgnlDefaultLanguage = languages[0];
        }
        // set all languages
        availableLanguages.value = languages;
        // set default language
        defaultLanguage.value = mgnlDefaultLanguage;
        // set current Language
        currentLanguage.value = getCurrentLanguage(fullPath, languages, defaultLanguage.value) || '';
    };

    return {
        currentLanguage,
        defaultLanguage,
        availableLanguages,
        initLanguages,
    };
};

export default useMagnoliaLanguages;
