<template>
    <div v-if="!product?.available" class="badge">
        <CommonLabel path="BWF/shop/productCard/soldOutLabel"></CommonLabel>
    </div>
    <div v-else-if="product?.isNew" class="badge is-blue">
        <CommonLabel path="BWF/shop/productCard/newLabel"></CommonLabel>
    </div>
    <div v-else-if="isListPrice || product?.markAsTopseller" class="flex gap-1">
        <div v-if="isListPrice" class="badge is-pink">
            <CommonLabel path="BWF/shop/productCard/sale"></CommonLabel>
        </div>
        <div v-if="product?.markAsTopseller" class="badge">
            <CommonLabel path="BWF/shop/productCard/topsellerLabel"></CommonLabel>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { Product } from '@shopware-pwa/types';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { useProductPrice } from '@shopware-pwa/composables-next';

const props = defineProps<{
    product: Product;
}>();
const { product } = toRefs(props);
const { isListPrice } = useProductPrice(product);
</script>
