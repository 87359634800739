<template>
    <NuxtLink :to="marketSetupAdmin?.shopEntrancePage ?? ''">
        <button class="btn btn-blue btn-size-small">
            <span>Online Shop</span>
        </button>
    </NuxtLink>
</template>

<script setup>
const { marketSetupAdmin } = useMarketSetup();
</script>
