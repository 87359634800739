import { intersectingComponent } from './intersectingComponent';
export const scrollableComponent = {
    data() {
        return {
            observer: null,
        };
    },
    props: {
        enableObserverOnMount: {
            type: Boolean,
            default: true,
        },
    },
    mixins: [intersectingComponent],
    methods: {
        onIntersection(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.enableScrollListener();
                } else {
                    this.disableScrollListener();
                }
            });
        },
        onScrollUpdate() {
            console.warn('implement scroll update handler');
        },

        enableScrollListener() {
            document.addEventListener('scroll', this.onScrollUpdate, { passive: true });
            // calc initial state
            this.onScrollUpdate();
        },
        disableScrollListener() {
            document.removeEventListener('scroll', this.onScrollUpdate, { passive: true });
        },
    },

    mounted() {
        if (this.enableObserverOnMount) {
            this.enableObserver();
        }
    },
    unmounted() {
        this.disableScrollListener();
    },
};
