import { computed, ref, inject, provide } from 'vue';
import { useShopwareContext } from '#imports';

/**
 * Composable to manage order payment
 * @public
 * @category Checkout / Order Payment
 */

import { useCheckout as useCoreCheckout, type UseCheckoutReturn } from '@shopware-pwa/composables-next';
export function useCheckout(): UseCheckoutReturn {
    const { apiInstance } = useShopwareContext();

    const storePaymentMethods = inject('swPaymentMethods', ref());
    provide('swPaymentMethods', storePaymentMethods);

    const coreCheckout = useCoreCheckout();
    const paymentMethods = computed(() => storePaymentMethods.value || []);

    async function getPaymentMethods({ forceReload, orderId } = { forceReload: false, orderId: '' }) {
        if (paymentMethods.value.length && !forceReload) return paymentMethods;
        const { data } = await apiInstance.invoke.post('/store-api/payment-method', {
            onlyAvailable: true,
            ...(orderId ? { orderId } : {}),
        });
        storePaymentMethods.value = data?.elements ?? [];
        return paymentMethods;
    }

    return {
        ...coreCheckout,
        getPaymentMethods,
        paymentMethods,
    };
}
