<template>
    <svg
        id="ef6WMU5HD9K1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 375 667"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
    >
        <g id="ef6WMU5HD9K2_ts" transform="translate(44,468) scale(0.2,0.2)">
            <ellipse
                id="ef6WMU5HD9K2"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="ef6WMU5HD9K3_ts" transform="translate(380,244) scale(0.2,0.2)">
            <ellipse
                id="ef6WMU5HD9K3"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="ef6WMU5HD9K4_ts" transform="translate(380,132) scale(0.2,0.2)">
            <ellipse
                id="ef6WMU5HD9K4"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#84d0f5"
                fill-opacity="0.65"
                stroke-width="0"
            />
        </g>
        <g id="ef6WMU5HD9K5_ts" transform="translate(156,20) scale(0.2,0.2)">
            <ellipse
                id="ef6WMU5HD9K5"
                rx="52"
                ry="52"
                transform="translate(0,0)"
                opacity="0"
                fill="#fff"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
    </svg>
</template>
<style scoped>
#ef6WMU5HD9K2_ts {
    animation: ef6WMU5HD9K2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K2_ts__ts {
    0% {
        transform: translate(44px, 468px) scale(0.2, 0.2);
    }
    30% {
        transform: translate(44px, 468px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        transform: translate(44px, 468px) scale(1, 1);
    }
    100% {
        transform: translate(44px, 468px) scale(1, 1);
    }
}
#ef6WMU5HD9K2 {
    animation: ef6WMU5HD9K2_c_o 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K2_c_o {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#ef6WMU5HD9K3_ts {
    animation: ef6WMU5HD9K3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K3_ts__ts {
    0% {
        transform: translate(380px, 244px) scale(0.2, 0.2);
    }
    20% {
        transform: translate(380px, 244px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        transform: translate(380px, 244px) scale(1, 1);
    }
    100% {
        transform: translate(380px, 244px) scale(1, 1);
    }
}
#ef6WMU5HD9K3 {
    animation: ef6WMU5HD9K3_c_o 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K3_c_o {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#ef6WMU5HD9K4_ts {
    animation: ef6WMU5HD9K4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K4_ts__ts {
    0% {
        transform: translate(380px, 132px) scale(0.2, 0.2);
    }
    10% {
        transform: translate(380px, 132px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        transform: translate(380px, 132px) scale(1, 1);
    }
    100% {
        transform: translate(380px, 132px) scale(1, 1);
    }
}
#ef6WMU5HD9K4 {
    animation: ef6WMU5HD9K4_c_o 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K4_c_o {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#ef6WMU5HD9K5_ts {
    animation: ef6WMU5HD9K5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K5_ts__ts {
    0% {
        transform: translate(156px, 20px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        transform: translate(156px, 20px) scale(1, 1);
    }
    100% {
        transform: translate(156px, 20px) scale(1, 1);
    }
}
#ef6WMU5HD9K5 {
    animation: ef6WMU5HD9K5_c_o 2000ms linear 1 normal forwards;
}
@keyframes ef6WMU5HD9K5_c_o {
    0% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
