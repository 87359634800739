<template>
    <div class="stage-content-component">
        <div
            class="stage-content relative w-full max-h-[80vh] aspect-2/3 md:aspect-[7/4] overflow-hidden rounded-b-xl md:rounded-b-none"
        >
            <div class="absolute inset-0 bg-light-grey">
                <PictureElement
                    :img="image"
                    :breakpoints="BREAKPOINTS"
                    loading="eager"
                    img-class=" absolute inset-0  block object-cover w-full h-full"
                ></PictureElement>
                <div
                    class="absolute inset-0 bg-black to-transparent"
                    :class="contentToTailwindClasses({ opacity: opacity })"
                ></div>
                <DotPattern ref="dots" :variant="dotPattern" class="block absolute inset-0 w-full h-full"></DotPattern>
                <div class="text-container container relative h-full w-full py-8">
                    <div
                        class="w-full xl:w-8/12 h-full flex flex-col responsive-gap justify-end md:justify-center items-start"
                    >
                        <div
                            v-if="subline"
                            class="font-medium text-sm xl:text-lg"
                            :class="[containsImage(image) ? 'text-white' : 'text-black']"
                        >
                            {{ subline }}
                        </div>
                        <HeadlineComponent
                            v-if="headline"
                            class="font-medium text-3xl md:text-4xl xl:text-6xl"
                            :class="[containsImage(image) ? 'text-white' : 'text-dark-blue']"
                            :headline="headline"
                            tag="h1"
                        ></HeadlineComponent>
                        <CallToActionComponent
                            v-if="link?.label ?? false"
                            :stretch-link="stretchLink"
                            alignment="left"
                            :link="link"
                        ></CallToActionComponent>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import DotPattern from '~/templates/elements/DotPattern.vue';
import { containsImage } from '~/utils/helper/magnolia';
import { contentToTailwindClasses } from '~/utils/helper/tailwind';
import CallToActionComponent from '~/templates/components/cms/CallToActionComponent.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import PictureElement from '~/templates/elements/PictureElement.vue';

defineProps(['subline', 'headline', 'image', 'link', 'dotPattern', 'opacity', 'backgroundColor', 'stretchLink']);

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['2/3'],
    sm: DIMENSIONS['sm'].ratio['2/3'],
    md: DIMENSIONS['md'].ratio['7/4'],
    xl: DIMENSIONS['xl'].ratio['7/4'],
    container: DIMENSIONS['container'].ratio['7/4'],
    page: DIMENSIONS['page'].ratio['7/4'],
};
</script>

<style scoped>
.stage-content {
    @apply transition-all duration-500;
}

.text-container {
    @screen md {
        padding-top: var(--fixed-header-height, 0px);
    }
}
</style>
