<template>
    <SliderElement
        class="relative slider-area"
        :components="components"
        :swiper-options="swiperOptions ?? {}"
        :reveal-animation="revealAnimation"
    ></SliderElement>
</template>

<script setup>
import SliderElement from '~/templates/elements/SliderElement.vue';
import { mapNodes } from '~/utils/helper/magnolia';

const swiperOptions = inject('swiperOptions');
const props = defineProps(['content']);

const components = computed(() => {
    return mapNodes(props.content);
});
const revealAnimation = computed(() => {
    return !!props.content.revealAnimation;
});
</script>
