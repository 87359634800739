<template>
    <div class="rounded-lg grid grid-cols-[2fr_1fr] gap-2 text-sm xl:text-base xl:p-8 xl:bg-light-grey">
        <HeadlineComponent tag="h2" class="text-base font-medium text-gray-900 col-span-2 mb-4 xl:text-2xl">
            <CommonLabel path="BWF/shop/checkout/cart/summary" />
        </HeadlineComponent>

        <p><CommonLabel path="BWF/shop/checkout/cart/subtotal" /></p>
        <SharedPrice :value="subTotalWithoutPromotion" class="font-medium place-self-end" has-asterisk />

        <template v-for="promoLineItem in promoLineItems" :key="promoLineItem.id">
            <p>
                <CommonLabel path="BWF/shop/checkout/cart/voucher" />
                <span class="break-all">&nbsp;{{ promoLineItem.payload?.code }}</span>
            </p>

            <div class="flex flex-col items-end">
                <SharedPrice
                    :value="`-${promoLineItem.extensions?.promotionDetails?.absoluteDiscount ?? 0}`"
                    class="font-medium text-signal-red"
                />
                <button class="text-link text-black text-right" type="button" @click="removeItem(promoLineItem)">
                    <CommonLabel class="text-xs mb-2 block" path="BWF/shop/checkout/cart/removeVoucher" />
                </button>
            </div>
        </template>
        <p>
            <CommonLabel path="BWF/shop/checkout/cart/shippingCosts" />
        </p>
        <SharedPrice :value="shippingTotal" class="font-medium place-self-end" has-asterisk />
        <hr class="bg-medium-grey col-span-full my-4" />
        <p class="font-medium">
            <CommonLabel path="BWF/shop/checkout/cart/total" />
        </p>
        <SharedPrice :value="totalPrice" class="place-self-end font-medium" />
        <div class="grid gap-1 col-span-full text-medium-grey text-xs">
            <CommonLabel path="BWF/shop/checkout/plusTax" />
        </div>
        <hr class="bg-medium-grey col-span-full my-4" />
        <CheckoutPromotionCode class="col-span-full" />
    </div>
</template>

<script setup>
import { getPromotiontLineItems } from '~/utils/helper/shop/cart';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import HeadlineComponent from '~/templates/components/cms/HeadlineComponent.vue';
import SharedPrice from '~/templates/components/shop/shared/SharedPrice.vue';
const { subtotal, totalPrice, shippingTotal, cart, cartItems, removeItem } = useCart();

const promoLineItems = computed(() => getPromotiontLineItems(cartItems.value ?? []));
const subTotalWithoutPromotion = computed(() => {
    // has promo extension ???

    return cart?.value?.extensions?.priceWithoutPromotion?.price ?? subtotal.value ?? 0;
});
</script>
