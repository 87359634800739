<template>
    <svg
        id="elyIkp2BQoe1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1600 544"
        shape-rendering="geometricPrecision"
        text-rendering="geometricPrecision"
    >
        <g id="elyIkp2BQoe2_ts" transform="translate(620,60) scale(0.2,0.2)">
            <ellipse
                id="elyIkp2BQoe2"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#00569d"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="elyIkp2BQoe3_ts" transform="translate(1400,60) scale(0.2,0.2)">
            <ellipse
                id="elyIkp2BQoe3"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#00569d"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
        <g id="elyIkp2BQoe4_ts" transform="translate(1400,320) scale(0.2,0.2)">
            <ellipse
                id="elyIkp2BQoe4"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#84d0f5"
                fill-opacity="0.65"
                stroke-width="0"
            />
        </g>
        <g id="elyIkp2BQoe5_ts" transform="translate(1660,320) scale(0.2,0.2)">
            <ellipse
                id="elyIkp2BQoe5"
                rx="120"
                ry="120"
                transform="translate(0,0)"
                opacity="0"
                fill="#00569d"
                fill-opacity="0.35"
                stroke-width="0"
            />
        </g>
    </svg>
</template>
<style scoped>
#elyIkp2BQoe2_ts {
    animation: elyIkp2BQoe2_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe2_ts__ts {
    0% {
        transform: translate(620px, 60px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        transform: translate(620px, 60px) scale(1, 1);
    }
    100% {
        transform: translate(620px, 60px) scale(1, 1);
    }
}
#elyIkp2BQoe2 {
    animation: elyIkp2BQoe2_c_o 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe2_c_o {
    0% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#elyIkp2BQoe3_ts {
    animation: elyIkp2BQoe3_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe3_ts__ts {
    0% {
        transform: translate(1400px, 60px) scale(0.2, 0.2);
    }
    10% {
        transform: translate(1400px, 60px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        transform: translate(1400px, 60px) scale(1, 1);
    }
    100% {
        transform: translate(1400px, 60px) scale(1, 1);
    }
}
#elyIkp2BQoe3 {
    animation: elyIkp2BQoe3_c_o 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe3_c_o {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#elyIkp2BQoe4_ts {
    animation: elyIkp2BQoe4_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe4_ts__ts {
    0% {
        transform: translate(1400px, 320px) scale(0.2, 0.2);
    }
    20% {
        transform: translate(1400px, 320px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        transform: translate(1400px, 320px) scale(1, 1);
    }
    100% {
        transform: translate(1400px, 320px) scale(1, 1);
    }
}
#elyIkp2BQoe4 {
    animation: elyIkp2BQoe4_c_o 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe4_c_o {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
#elyIkp2BQoe5_ts {
    animation: elyIkp2BQoe5_ts__ts 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe5_ts__ts {
    0% {
        transform: translate(1660px, 320px) scale(0.2, 0.2);
    }
    30% {
        transform: translate(1660px, 320px) scale(0.2, 0.2);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        transform: translate(1660px, 320px) scale(1, 1);
    }
    100% {
        transform: translate(1660px, 320px) scale(1, 1);
    }
}
#elyIkp2BQoe5 {
    animation: elyIkp2BQoe5_c_o 2000ms linear 1 normal forwards;
}
@keyframes elyIkp2BQoe5_c_o {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
</style>
