<template>
    <footer class="w-full z-10" role="contentinfo">
        <div
            class="bg-light-grey w-full rounded-t-2xl shadow-md p-4 text-center mt-4 flex gap-8 justify-center text-xs"
        >
            <div class="container py-8 text-black text-left">
                <div v-for="row in rows" :key="getId(row)">
                    <FooterGrid :row="row"></FooterGrid>
                    <hr class="my-8 border-black" />
                </div>
                <FooterImprint v-if="imprint" :imprint="imprint"></FooterImprint>
            </div>
        </div>
    </footer>
</template>

<script setup>
import FooterImprint from './footer/FooterImprint';
import FooterGrid from './footer/FooterGrid';
import { inject } from 'vue';
import { filterNodesByType, mapNodes, getId } from '~/utils/helper/magnolia';

import useNavigationTracking, { NAVIGATION_FOOTER } from '~/composables/tracking/useNavigationTracking';
useNavigationTracking(NAVIGATION_FOOTER);

const pageFooter = inject('pageFooter', '');

const rows = filterNodesByType(mapNodes(pageFooter.value), 'footer:row');
const imprint = filterNodesByType(mapNodes(pageFooter.value), 'footer:imprint')?.[0];
</script>
