<template>
    <button class="btn btn-icon relative" @click="onClick">
        <SvgIcon name="User"></SvgIcon>
        <ClientOnly>
            <span
                v-if="isAuthenticated"
                class="absolute bottom-1 right-0 h-4 w-4 bg-light-blue text-black rounded-full text-xxs flex items-center justify-center"
            ></span>
        </ClientOnly>
    </button>
</template>

<script setup>
import SvgIcon from '../../elements/SvgIcon';

import useMyBritaUser from '~/composables/useMyBritaUser';

const { $msal } = useNuxtApp();
const { isAuthenticated } = useMyBritaUser();
const { marketSetupLinks } = useMarketSetup();
const { push } = useRouter();

const onClick = async () => {
    if (isAuthenticated.value) {
        await push(marketSetupLinks.value?.myBritaOverviewPage);
    } else {
        if (marketSetupLinks?.value?.myBritaRegisterPage) {
            await push(marketSetupLinks.value?.myBritaRegisterPage);
        } else {
            await $msal.signInSignUp();
        }
    }
};
</script>
