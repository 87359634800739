<template>
    <div
        class="flex flex-col relative h-full w-full rounded-2xl overflow-hidden safari-overflow-hidden-fix bg-light-grey group"
    >
        <div class="overflow-hidden bg-light-beige">
            <PictureElement
                v-if="image"
                img-class="Image aspect-3/2 gradient object-cover transition-transform group-hover:scale-110 duration-300 w-full h-full"
                :img="image"
                :breakpoints="imageBreakpoints"
            ></PictureElement>
        </div>
        <div class="flex flex-col flex-grow justify-between p-6 min-h-32 overflow-hidden">
            <div>
                <component
                    :is="isDownloadLink(link) ? 'div' : LinkComponent"
                    :link="link"
                    optional-link-classes="stretched-link"
                >
                    <HeadlineComponent
                        class="text-2xl pb-2 font-medium"
                        :headline="headline"
                        tag="h3"
                    ></HeadlineComponent>
                </component>

                <p>{{ text }}</p>
            </div>
            <DownloadLinkComponent
                v-if="isDownloadLink(link)"
                class="stretched-link mt-12"
                :link="link"
                :label="link?.label"
            ></DownloadLinkComponent>
        </div>
    </div>
</template>

<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import { isDownloadLink } from '~/utils/helper/magnolia';
import DownloadLinkComponent from '~/templates/components/cms/DownloadLinkComponent.vue';
import HeadlineComponent from './HeadlineComponent';
import PictureElement from '../../elements/PictureElement';
import LinkComponent from '~/templates/components/cms/LinkComponent.vue';

defineProps(['text', 'headline', 'image', 'link']);

const imageBreakpoints = {
    start: DIMENSIONS['start'].width['10/12'].ratio['3/2'],
    sm: DIMENSIONS['sm'].width['10/12'].ratio['3/2'],
    md: DIMENSIONS['md'].width['1/2'].ratio['3/2'],
    xl: DIMENSIONS['xl'].width['1/3'].ratio['3/2'],
};
</script>
