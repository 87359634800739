<template>
    <div v-if="hasContent" class="relative">
        <template v-for="spec in specsWithContent" :key="spec">
            <div class="w-full">
                <AccordionComponent
                    class="w-full border-b"
                    :open="content?.openSpecs?.includes(spec)"
                    :data-test="content?.openSpecs?.[spec]"
                    @on-toggle="trackTechnicalSpecs(spec, product?.translated?.name)"
                >
                    <template #header>
                        <div class="text-xl xl:text-2xl py-6 font-medium truncate min-w-0">
                            <CommonLabel :path="`BWF/shop/product/specs/${spec}`"></CommonLabel>
                        </div>
                    </template>
                    <template #content>
                        <div class="prose prose-black pb-6" v-html="product.customFields[spec]"></div>
                    </template>
                </AccordionComponent>
            </div>
        </template>
        <div v-if="content?.downloadVisible" class="w-full">
            <AccordionComponent class="w-full border-b" :open="!!content?.downloadOpen">
                <template #header>
                    <div class="text-xl xl:text-2xl py-6 font-medium">
                        <template v-if="content?.downloadHeadline">
                            {{ content?.downloadHeadline }}
                        </template>
                        <CommonLabel v-else path="BWF/shop/product/specs/cmsDownloadHeadline"></CommonLabel>
                    </div>
                </template>
                <template #content>
                    <div class="grid gap-4 items-start pb-6">
                        <div v-if="content?.richText" class="prose" v-html="content?.richText"></div>
                        <DownloadLinkComponent
                            v-for="download in mapNodes(content?.downloads)"
                            :key="download['@id']"
                            :download="download?.download"
                        ></DownloadLinkComponent>
                    </div>
                </template>
            </AccordionComponent>
        </div>
    </div>
</template>

<script setup>
import AccordionComponent from '~/templates/elements/AccordionComponent.vue';
import CommonLabel from '~/templates/components/CommonLabel.vue';
import { mapNodes } from '~/utils/helper/magnolia';
import DownloadLinkComponent from '~/templates/components/cms/DownloadLinkComponent.vue';
import useNavigationTracking from '~/composables/tracking/useNavigationTracking';
const props = defineProps(['product', 'downloads', 'content']);

const VISIBLE_SPECS_DEFAULT = [
    'brita_product_data_safety_instructions',
    'brita_product_data_details_and_dimensions',
    'brita_product_data_materials_and_maintenance',
    'brita_product_data_compatibility',
    'brita_product_data_technical_details',
    'brita_product_data_installation_requirements',
    'brita_product_data_instructions',
    'brita_product_data_nutritional_values',
    'brita_product_data_ingredients',
    'brita_product_data_preparation',
    'brita_product_data_varieties_overview',
];

const { trackTechnicalSpecs } = useNavigationTracking();

// prefilter specs
const specsWithContent = computed(() => {
    const hiddenSpecs = props.content?.hiddenSpecs ?? [];
    return VISIBLE_SPECS_DEFAULT?.filter(spec => {
        // check if there is actual content for the key in product
        return !hiddenSpecs.includes(spec) && props.product?.customFields?.[spec]?.length > 0;
    });
});

const hasContent = computed(() => {
    return specsWithContent.value.length > 0 || props.content?.downloadVisible;
});
</script>
