import { computed, type Ref } from 'vue';

import type { Order } from '@shopware-pwa/types';
import { useSessionStorage } from '@vueuse/core';
import type { RemovableRef } from '@vueuse/core';
import { orderQueryString } from '~/utils/helper/shop/order';

const D2C_ORDER_SESSION = 'D2C_ORDER_SESSION';
const orderSession = function () {
    const _order: RemovableRef<Order> = useSessionStorage(D2C_ORDER_SESSION, {} as Order);

    const saveOrder = (order: Ref<Order> | Order) => {
        _order.value = toRaw(order) as Order;
    };
    const clearOrder = () => {
        _order.value = null;
    };

    const getQueryString = () => {
        return orderQueryString(_order.value);
    };

    return {
        sessionOrder: computed(() => _order?.value),
        sessionOrderId: computed(() => _order?.value?.id),
        getQueryString,
        saveOrder,
        clearOrder,
    };
};

export const useOrderSession = createSharedComposable(orderSession);
