<template>
    <div class="form-field input-field">
        <div
            class="form-field-wrapper"
            :class="{
                'has-error': hasError,
                hidden: attrs?.type === 'hidden',
            }"
        >
            <input
                :id="`input-${attrs?.name}`"
                :type="attrs?.type ?? 'text'"
                :placeholder="attrs.placeholder"
                :name="attrs?.name"
                :autocomplete="attrs?.autocomplete"
                :readonly="attrs?.readonly"
                :disabled="attrs?.disabled"
                :pattern="attrs?.pattern"
                :max="attrs?.max"
                :min="attrs?.min"
                :value="modelValue"
                class="appearance-none bg-transparent h-10 w-full px-2 rounded-t-sm"
                :class="{ 'has-label': !!label, 'has-error': hasError }"
                @input="$emit('update:modelValue', $event.target.value)"
                @blur="$emit('blur', $event)"
                @change="$emit('change', $event)"
            />
            <label v-if="label" :for="`input-${attrs?.name}`" :class="{ 'border-signal-red': hasError }">
                {{ label }}
                <span v-if="label && attrs.required">{{ requiredChar }}</span>
            </label>
        </div>
        <div v-if="errorMessage" class="px-2 pointer-events-none text-signal-red text-xs">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
const props = defineProps(['modelValue', 'label', 'errorMessage']);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

const requiredChar = inject('input-required-char', '*');

defineEmits(['update:modelValue', 'blur', 'change']);
</script>
