<template>
    <div class="relative text-base">
        <label class="flex gap-2 w-full" :class="{ 'has-label': !!label }">
            <span class="relative h-5 w-5 shrink-0 p-1" :class="hasError ? 'has-error' : 'checkbox-border'">
                <input
                    type="checkbox"
                    :placeholder="attrs.placeholder"
                    :name="attrs.name"
                    :disabled="attrs.disabled"
                    :value="modelValue"
                    :checked="modelValue"
                    class="h-full w-full appearance-none absolute inset-0"
                    :class="{ 'has-label': !!label }"
                    @input="$emit('update:modelValue', $event.target.checked)"
                    @blur="$emit('blur', $event)"
                    @change="$emit('change', $event)"
                />
                <SvgIcon
                    name="Checkmark-1"
                    class="absolute hidden pointer-events-none h-full w-full inset-0 p-1 text-dark-blue"
                ></SvgIcon>
            </span>
            <span class="leading-5 w-full relative">
                {{ label }}
                <slot />
            </span>
        </label>
        <div v-if="errorMessage" class="pl-7 pt-1 pr-2 pointer-events-none text-signal-red text-sm">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script setup>
import SvgIcon from '~/templates/elements/SvgIcon.vue';

const props = defineProps(['modelValue', 'label', 'errorMessage']);
const attrs = useAttrs();
const hasError = computed(() => !!props.errorMessage);

// TODO check preselection

defineEmits(['update:modelValue', 'blur', 'change']);
</script>

<style scoped>
[disabled] {
    @apply cursor-not-allowed grayscale opacity-60;
}

input:checked + svg {
    @apply block;
}

.has-error {
    @apply border rounded border-signal-red;
}

.checkbox-border {
    @apply border rounded border-dark-blue;
}
</style>
