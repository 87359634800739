import { screens } from '~/tailwind.config.screens.js';

const DESKTOP_BREAKPOINT = parseInt(screens?.xl ?? 1200);
const TABLET_BREAKPOINT = parseInt(screens?.md ?? 768);

import { watch } from 'vue';

function evaluateDevice() {
    const { viewPortWidth } = useWindow();
    const { 'user-agent': ua } = useRequestHeaders(['user-agent']);
    const mobileExpression = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i;
    const isMobile = useState('isMobile', () => mobileExpression.test(ua));
    const tabletExpression = /iPad/i;
    const isTablet = useState('isTablet', () => tabletExpression.test(ua));
    const isTabletOrMobile = useState('isTabletOrMobile', () => isMobile.value || isTablet.value);
    const isDesktop = useState('isDesktop', () => !isTabletOrMobile.value);

    const evaluateSize = () => {
        // viewPortWidth.value = window.innerWidth ?? 0;
        isDesktop.value = viewPortWidth.value >= DESKTOP_BREAKPOINT;
        isTablet.value = viewPortWidth.value >= TABLET_BREAKPOINT && viewPortWidth.value < DESKTOP_BREAKPOINT;
        isMobile.value = viewPortWidth.value < TABLET_BREAKPOINT;
        isTabletOrMobile.value = isMobile.value || isTablet.value;
    };

    watch(viewPortWidth, evaluateSize);

    // expose managed state as return value
    return { isMobile, isTablet, isTabletOrMobile, isDesktop, viewPortWidth };
}

export default evaluateDevice;
