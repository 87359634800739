const ITEM_PREFIX = 'GA_ITEM';
export default function () {
    const { currency } = useSessionContext();
    /**
     * serializes shopware "line items" to an array of tracking compatible items
     */
    // TODO build a generic assignment for Shopware Properties to generate property key-value pairs to add into transformed item (TBD)
    const transformItem = (lineItem, index, item_list_name = '', item_list_id = '') => ({
        item_name: lineItem.translated?.name ?? lineItem.name ?? lineItem.label,
        item_id: lineItem.parentId ?? lineItem.id,
        item_variant: lineItem.id,
        price:
            lineItem.extensions?.tauschbox?.tauschboxPrice ??
            lineItem.children?.[0]?.extensions?.tauschbox?.tauschboxPrice ??
            lineItem.calculatedPrice?.totalPrice ??
            lineItem.price?.totalPrice ??
            lineItem.totalPrice,
        currency: currency.value?.isoCode,
        ...(lineItem.manufacturer?.translated?.name ? { item_brand: lineItem.manufacturer?.translated?.name } : {}),
        ...(lineItem.categories?.[0] ? { item_category: lineItem.categories?.[0]?.name } : {}),
        ...(lineItem.categories?.[1] ? { item_category2: lineItem.categories?.[1]?.name } : {}),
        ...(lineItem.categories?.[2] ? { item_category3: lineItem.categories?.[2]?.name } : {}),
        ...(lineItem.categories?.[3] ? { item_category4: lineItem.categories?.[3]?.name } : {}),
        ...(lineItem.categories?.[4] ? { item_category5: lineItem.categories?.[4]?.name } : {}),
        item_color:
            lineItem.options?.find(option => option.group.displayType.includes('color'))?.name ?? 'not available',
        item_size:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_size')
            )?.name ?? 'not available',
        item_amount:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_amount')
            )?.name ?? 'not available',
        item_filter:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_filter')
            )?.name ?? 'not available',
        item_armature:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_armature')
            )?.name ?? 'not available',
        item_accessories1:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_accessories1')
            )?.name ?? 'not available',
        item_subscription:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_subscription')
            )?.name ?? 'not available',
        item_flavour:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_flavour')
            )?.name ?? 'not available',
        item_material:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_material')
            )?.name ?? 'not available',
        item_cylinder:
            lineItem.options?.find(option =>
                option.group?.customFields?.['custom_tracking_data_type'].includes('item_cylinder')
            )?.name ?? 'not available',
        index,
        quantity: lineItem?.quantity ?? 1,
        item_list_name,
        item_list_id,
    });

    const transformOrderItem = (lineItem, index, item_list_name = '', item_list_id = '') => ({
        item_name: lineItem?.translated?.name ?? lineItem?.name ?? lineItem?.label,
        item_id: lineItem.item_id ?? lineItem.id,
        item_variant: lineItem.item_id ?? lineItem.id,
        price:
            lineItem.children?.[0]?.totalPrice ??
            lineItem.calculatedPrice?.totalPrice ??
            lineItem?.price?.totalPrice ??
            lineItem?.totalPrice,
        currency: currency.value?.isoCode,
        index,
        quantity: lineItem?.quantity ?? 1,
        item_list_name,
        item_list_id,
    });

    const getItemsArray = (lineItems = [], item_list_name = '', item_list_id = '') => {
        try {
            return (
                lineItems?.map((lineItem, index) => transformItem(lineItem, index, item_list_name, item_list_id)) ?? []
            );
        } catch (e) {
            console.error('eec.getItemsArray', e);
            return [];
        }
    };
    const saveItemToStorage = item => {
        // item variant is most specific and always sw id
        useSessionStorage(`${ITEM_PREFIX}_${item.item_variant}`, item);
    };

    const getItemFromStorage = item => {
        // if there is already an item in storage with no variants or is a variant, storageItem gets set
        let storageItem = useSessionStorage(`${ITEM_PREFIX}_${item.id}`).value;
        if (!storageItem) {
            // if there is no match, the parentId is checked across stored items to find a stored variant sibling
            try {
                // find and product sibling
                let relatedProduct = JSON.parse(
                    Object.values(sessionStorage).find(searchedItem => {
                        try {
                            // handle parsing errors as this iterates over the entire storage
                            const storageItem = JSON.parse(searchedItem);
                            // check if there is already a variant of the same product in storage (parent_id was assigned item_id above for variants )
                            return storageItem.item_id === item.parentId;
                        } catch (e) {
                            return false;
                        }
                    })
                );
                let transformedAltItem = transformItem(
                    item,
                    relatedProduct.index,
                    relatedProduct.item_list_name,
                    relatedProduct.item_list_id
                );
                saveItemToStorage(transformedAltItem);
                storageItem = useSessionStorage(`${ITEM_PREFIX}_${item.id}`).value;
            } catch (e) {
                console.info('items.array could not find related product');
            }
        }

        if (storageItem) {
            // return item with list reference
            return JSON.parse(storageItem);
        } else {
            // serialize new item
            const transformedItem = transformItem(item, -1);
            saveItemToStorage(transformedItem);
            return transformedItem;
        }
    };

    const getOrderItemFromStorage = item => {
        const storageItem = useSessionStorage(`${ITEM_PREFIX}_${item.productId}`).value;
        if (storageItem) {
            // return item with list reference
            return JSON.parse(storageItem);
        } else {
            // serialize new item
            return transformOrderItem(item, -1);
        }
    };

    return { getItemsArray, saveItemToStorage, getItemFromStorage, transformItem, getOrderItemFromStorage };
}
