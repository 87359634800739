<template>
    <div
        class="stage-story-component relative"
        :class="{
            '-mt-4': !isEditMode,
        }"
    >
        <div class="fixed-header-offset container relative w-full py-8 lg:py-16 grid md:grid-cols-2 responsive-gap">
            <div
                class="relative order-2 md:order-1 py-6 md:pt-0 md:pb-8 md:h-full border-b border-b-medium-grey/50 flex flex-col items-start justify-center responsive-gap"
            >
                <div v-if="subline" class="font-medium text-sm xl:text-lg text-black">
                    {{ subline }}
                </div>
                <HeadlineComponent
                    v-if="headline"
                    class="font-medium text-dark-blue text-3xl md:text-4xl xl:text-6xl"
                    :headline="headline"
                ></HeadlineComponent>

                <div v-if="readingTime.trim()" class="rounded-full p-2 bg-light-grey text-sm flex items-center gap-2">
                    <SvgIcon class="h-4 w-4" name="icon-clock"></SvgIcon>
                    <span> {{ readingTime }} </span>
                </div>
            </div>

            <div
                class="relative order-1 md:order-2 items-end align-start overflow-hidden rounded-xl aspect-3/2"
                :class="{ 'z-[1]': foregroundImage }"
            >
                <PictureElement
                    :img="image"
                    :breakpoints="BREAKPOINTS"
                    loading="eager"
                    img-class="absolute inset-0 block object-cover w-full h-full"
                ></PictureElement>
            </div>
        </div>
        <DotPattern
            v-if="showDotPattern ?? true"
            ref="dots"
            :blue-pattern="true"
            :overflow-visible="true"
            class="block absolute inset-0 w-full h-full pointer-events-none"
        ></DotPattern>
    </div>
</template>

<script setup>
import { DIMENSIONS } from '~/utils/helper/imaging';
import SvgIcon from '~/templates/elements/SvgIcon.vue';
import DotPattern from '~/templates/elements/DotPattern.vue';

const BREAKPOINTS = {
    start: DIMENSIONS['start'].ratio['3/2'],
    sm: DIMENSIONS['sm'].ratio['3/2'],
    md: DIMENSIONS['md'].width['1/2'].ratio['3/2'],
    xl: DIMENSIONS['xl'].width['1/2'].ratio['3/2'],
};
</script>
<script>
import HeadlineComponent from './HeadlineComponent';
import PictureElement from '../../elements/PictureElement';
import { editableComponent } from '~/utils/mixins/editableComponent';

export default {
    name: 'StageStoryComponent',
    components: { HeadlineComponent, PictureElement },
    mixins: [editableComponent],
    props: [
        'subline',
        'headline',
        'time',
        'commonLabel',
        'image',
        'link',
        'showDotPattern',
        'backgroundColor',
        'foregroundImage',
    ],
    data: () => ({
        dotsVisible: false,
    }),
    computed: {
        readingTime() {
            return `${this.time ?? ''} ${this.commonLabel?.value ?? ''}`;
        },
    },
};
</script>

<style scoped>
.fixed-header-offset {
    @apply transition-all duration-700;
    margin-top: var(--fixed-header-height);
}
</style>
