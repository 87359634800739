import type { Salutation } from '@shopware-pwa/types';

/** returns Salutation as options after filtering not-specified as an option and sorting with the custom sorting order */

const order: { [key: string]: number } = { not_specified: 0, mr: 1, mrs: 2, diverse: 3 };
const customSort = (a: Salutation, b: Salutation): number => {
    return order[a.salutationKey] - order[b.salutationKey];
};

export const salutationOptions = (options: never[], key = 'id', label = 'displayName') => {
    return options.sort(customSort).map(option => ({
        value: option[key],
        label: option[label],
    }));
};
