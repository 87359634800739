<template>
    <div class="font-medium text-3xl md:text-6xl whitespace-pre-wrap">
        {{ text }}
    </div>
</template>

<script>
export default {
    name: 'EditorialStoryHeadlineComponent',
    props: ['text'],
};
</script>
